import { httpClient } from "../utils/HttpClient";
import {
HTTP_CREATE_NEW_CUSTOMER_FETCHING,
HTTP_CREATE_NEW_CUSTOMER_SUCCESS,
HTTP_CREATE_NEW_CUSTOMER_FAILED,
  server,
//   HTTP_STOCK_CLEAR
} from "../constants";

export const setStateAddNewCustomerToSuccess = payload => ({
  type: HTTP_CREATE_NEW_CUSTOMER_SUCCESS,
  payload
});

const setStateAddNewCustomerToFetching = () => ({
  type: HTTP_CREATE_NEW_CUSTOMER_FETCHING
});

const setStateAddNewCustomerToFailed = payload => ({
  type: HTTP_CREATE_NEW_CUSTOMER_FAILED,
  payload
});



  export const createNewCustomerFunction= (value, history) => {
    return async dispatch => {
      try {
        dispatch(setStateAddNewCustomerToFetching()); // fetching
        let result = await httpClient.post(server.CREATE_NEW_CUSTOMER, value);
        if (result.data.result == 'ok') {
          dispatch(setStateAddNewCustomerToSuccess(result.data));
          history.push('/rental')
        } else {        
          // alert(JSON.stringify(result.data.message))
          dispatch(setStateAddNewCustomerToFailed(result.data));
        //   history.push('/rental')
        }
      } catch (error) {        
          dispatch(setStateAddNewCustomerToFailed({data: {message: error}}));
        //   history.push('/rental')
      }
    };
  };
  
  