import React, { Component } from 'react';
import * as actions from "../../actions/rentalEdit.action";
import { Formik } from "formik";
import { connect } from "react-redux";
import { imageUrl } from "../../constants";
// import moment from "react-moment";
import moment from 'moment'
class RentalEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ListEVCar:[],
      EVCarStoreValue:[],
      SelectCar:[],
      ArrEVList:[],
      NewArrEVList:[],
      RemoveEVCarList:[],
      Site_Name:'',
      Province:'',
      SiteId:'',
      CustomerId:''
    };
  }

  componentDidMount(){
    let id = this.props.match.params.id
    this.props.getRentalContractById(id).then((result)=>  this.ArrEVCar())
  }

  // SelectCustomer = ()=>{
  //   let {result} = this.props.rentalEditReducer

  //   return (
  //     result!=null ? result.Customer.map((item,index)=>{
  //     return  <option  key={item.Customer_Name} value={item.Id}>{item.Customer_Name}</option>
  //     }):null
  //   );
    
  // }
  SelectCustomerDropDown = (value)=>{
    let {result} = this.props.rentalEditReducer

    return (
      result!=null ? result.ArrCustomer.filter(val=>val.Customer_Name===value).map((item,index)=>{
      return  <option  key={item.Customer_Name} value={item.Id}>{item.Customer_Name}</option>
      }):null
    );
    
  }
  SelectCustomerDropDownS = (value)=>{
    let {result} = this.props.rentalEditReducer

    return (
      result!=null ? result.ArrCustomer.filter(val=>val.Customer_Name!==value).map((item,index)=>{
      return  <option  key={item.Customer_Name} value={item.Id}>{item.Customer_Name}</option>
      }):null
    );
    
  }
  SelectLocation= ()=>{
    let {result} = this.props.rentalEditReducer

    return (
      result!=null ? result.Location.map((item,index)=>{
      return  <option  key={item.Id} value={item.Id}>{item.Site_Name}</option>
      }):null
    );
    
  }
  SelectEVCar= ()=>{
    let {result} = this.props.rentalEditReducer

    return (
      this.state.ListEVCar!=null ? this.state.ListEVCar.map((item,index)=>{
      return  <option  key={item.Serial_Number} value={JSON.stringify(item)} >{item.Serial_Number}</option>
      }):null
    );
  }
  SelectSite= (value)=>{
    let {result} = this.props.rentalEditReducer

 
    return (
      result!=null ? result.ArrSite.filter(val=>val.Site_Name===value).map((item,index)=>{
      return  <option  key={item.Id} value={JSON.stringify(item)} >{item.Site_Name}</option>
      }):null
    );
  }
  SelectSiteExist= (value)=>{
    let {result} = this.props.rentalEditReducer

    return (
      result!=null ? result.ArrSite.filter(val=>val.Site_Name!==value).map((item,index)=>{
      return  <option  key={item.Id} value={JSON.stringify(item)} >{item.Site_Name}</option>
      }):null
    );
  }
   ArrEVCar = () =>{
    let {result} = this.props.rentalEditReducer
 
    if(result!=null){
      
      // localStorage.setItem("EVCar",JSON.stringify(result.EVCar))
      let Temp = result.EVCar
      this.setState({EVCarStoreValue:result.EVCar})
      this.setState({ArrEVList:result.ArrEVList})
     this.setState({ListEVCar:Temp})



       let arr = result.ArrSite.filter(val=>val.Site_Name===result.ArrRentalContract.Site.Site_Name)
      // alert(JSON.stringify(result.ArrRentalContract.Site.Site_Name))
           this.setState({Province:arr[0].Province})
     
    }
  }
  onHandleArrListEVCar = async (event) =>{

   
    // console.log("ok",event)
  // alert(event.target.value)
     let ListCar  = this.state.ListEVCar
  
  if(event.target.value !=null&& ListCar!=null){
   
       let PreLoad =  await JSON.parse(event.target.value)
   
        if(PreLoad){
          // this.setState({SelectCar:[...this.state.SelectCar,PreLoad]})
          // this.setState({ArrEVList:[...this.state.ArrEVList,PreLoad]})
          this.setState({NewArrEVList:[...this.state.NewArrEVList,PreLoad]})
     
  
          let arr = await ListCar.filter(function(item) {
            return item.Serial_Number !== PreLoad.Serial_Number
        })
        this.setState({ListEVCar:arr})
  
          // await ListCar.forEach(item=>{
          //   const index = item.Serial_Number.indexOf(PreLoad.Serial_Number);
          // if (index > -1) {
          //   ListCar.splice(index, 1);
          // }
          // })
        }else{
          alert("NOK")
        }
   
  
  }
    }
  onRecoveryArrListEVCar = async(value) =>{
    // alert(value.Serial_Number)
    let {result} =this.props.rentalEditReducer
  //  let ListCar  = localStorage.getItem("EVCar")
    let ListCar = this.state.EVCarStoreValue
      let selectCar  = this.state.NewArrEVList
  if(value !=null&& result!=null){
  
    let arr = await selectCar.filter(function(item) {
      return item.Serial_Number !== value.Serial_Number
  })

    this.setState({NewArrEVList:arr})
  
    let found =  ListCar.find(item=>{
      console.log(item.Serial_Number,value.Serial_Number)
       if(item.Serial_Number===value.Serial_Number)
       {
         return item
       }
      })

       let Dummy =  this.state.ListEVCar
       Dummy.unshift(found)
 
           this.setState({ListEVCar:Dummy})
    
  }
  
    }
  onRemoveEVCarListFromDB = (value) =>{
    let EVCarDB = this.state.ArrEVList
    this.setState({RemoveEVCarList:[...this.state.RemoveEVCarList,value]})
    let arr = EVCarDB.filter((item)=>{
      return item.Serial_Number !== value.Serial_Number
    })
    this.setState({ArrEVList:arr})
  }
  handleSite = (event,value) =>{
      // alert(JSON.stringify(event.target.value))
      // alert(JSON.parse(event.target.value).Id)
      this.setState({SiteId:JSON.parse(event.target.value).Id})
      this.setState({Province:JSON.parse(event.target.value).Province})
  }
  handleCustomer = (event) =>{

    this.setState({CustomerId:event.target.value})

  }


    ComponentSelectEVCar = () =>{
      return    <div className="form-group" style={{ marginBottom: 10 }}>
      <label className="col-sm-2 control-label" htmlFor="RentalCarListD">
      RentalCarList
      </label>
      <div className="col-sm-10">
        <div className="input-group">
          <select
            name="RentalCarListD"
            onChange={(this.onHandleArrListEVCar)}
            // value={values.RentalCarListD}
            className="form-control"
            type="select"
            id="RentalCarListD"
          > 
            <option value='' >Select EV Car</option>
  
            {this.SelectEVCar()}
     
          </select>
    
        </div>
         Total  {this.state.ListEVCar.length}
      </div>
  
    </div>
  
    }

  showForm =({values,handleChange,handleSubmit, setFieldValue})=>{
    const ArrRental= values.ArrRentalContract
    // const ArrData = this.state.ArrEVList
   
    if(ArrRental !=null && this.state.ArrEVList !=null ){

    return (
      <form
      className="form-horizontal"
      onSubmit={handleSubmit}>
      <div className="form-group">
 
        <label className="col-sm-2 control-label" htmlFor="ArrRentalContract.Contract_Number">
          Contract Number
        </label>
        <div className="col-sm-10">
          <div  className="input-group">
          <input
            name="ArrRentalContract.Contract_Number"
            onChange={handleChange}
            value={values.ArrRentalContract.Contract_Number}
            className="form-control"
            type="text"
            id="ArrRentalContract.Contract_Number"
          />
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginBottom: 10 }}>
 {/* { <p>{moment(values.ArrRentalContract.Start_Date).format('yyyy-MM-DD')}</p>} */}
 <label className="col-sm-2 control-label" htmlFor="ArrRentalContract.Start_Date">
   Start Date
 </label>
 <div className="col-sm-10">
   <div  className="input-group">
   <input
     name="ArrRentalContract.Start_Date"
     onChange={handleChange}
     value={moment(values.ArrRentalContract.Start_Date).format('yyyy-MM-DD')}
     className="form-control"
     type="date"
     id="ArrRentalContract.Start_Date"
   />
   </div>
 </div>
</div>

<div className="form-group" style={{ marginBottom: 10 }}>
 
 <label className="col-sm-2 control-label" htmlFor="ArrRentalContract.End_Date">
   End Date
 </label>
 <div className="col-sm-10">
   <div  className="input-group">
   <input
     name="ArrRentalContract.End_Date"
     onChange={handleChange}
     value={moment(values.ArrRentalContract.End_Date).format('yyyy-MM-DD')}
     placeholder="โปรดระบุ"
     className="form-control"
     type="date"
     id="ArrRentalContract.End_Date"
   />
   </div>
 </div>
</div>

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="ArrRentalContract.Customer.Customer_Name">
          Customer Name
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            {/* <input
              name="ArrRentalContract.Customer.Customer_Name"
              onChange={handleChange}
              value={values.ArrRentalContract.Customer.Customer_Name}
              className="form-control"
              type="text"
              id="ArrRentalContract.Customer.Customer_Name"
            /> */}

              <select
              name="CustomerName"
              onChange={e=>this.handleCustomer(e)}
              value={undefined}
              className="form-control"
              type="select"
              id="CustomerName"
            > 
              {/* <option value=''  >Select Site</option> */}
          
              {this.SelectCustomerDropDown(values.ArrRentalContract.Customer.Customer_Name)}
              {this.SelectCustomerDropDownS(values.ArrRentalContract.Customer.Customer_Name)}
       
            </select> 
         
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="ArrRentalContract.Customer.Customer_Code">
          Customer Code
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="ArrRentalContract.Customer.Customer_Code"
              onChange={handleChange}
              value={values.ArrRentalContract.Customer.Customer_Code}
              className="form-control"
              type="text"
              id="ArrRentalContract.Customer.Customer_Code"
              disabled
            />
            {/* <span className="input-group-addon input-group-addon_custom">
              PCS.
            </span> */}
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="ArrRentalContract.Customer.Customer_Email">
          Customer Email
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="ArrRentalContract.Customer.Customer_Email"
              onChange={handleChange}
              value={values.ArrRentalContract.Customer.Customer_Email!=null ?values.ArrRentalContract.Customer.Customer_Email:undefined}
              className="form-control"
              type="email"
              id="ArrRentalContract.Customer.Customer_Email"
              disabled
            />
            {/* <span className="input-group-addon input-group-addon_custom">
              PCS.
            </span> */}
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="ArrRentalContract.Customer.Customer_Address">
          Customer Address
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="ArrRentalContract.Customer.Customer_Address"
              onChange={handleChange}
              value={values.ArrRentalContract.Customer.Customer_Address}
              className="form-control"
              type="text"
              id="ArrRentalContract.Customer.Customer_Address"
              disabled
            />
            {/* <span className="input-group-addon input-group-addon_custom">
              PCS.
            </span> */}
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="ArrRentalContract.Customer.Customer_Telephone">
          Customer Telephone
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="ArrRentalContract.Customer.Customer_Telephone"
              onChange={handleChange}
              value={values.ArrRentalContract.Customer.Customer_Telephone || undefined}
              className="form-control"
              placeholder="input your customer info"
              type="text"
              id="ArrRentalContract.Customer.Customer_Telephone"
              disabled
            
            />
            {/* <span className="input-group-addon input-group-addon_custom">
              PCS.
            </span> */}
          </div>
        </div>
      </div>
      

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="ArrRentalContract.Site.Site_Name">
        Site
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            {/* <input
              name="ArrRentalContract.Site.Site_Name"
              onChange={handleChange}
              value={values.ArrRentalContract.Site.Site_Name}
              className="form-control"
              type="text"
              id="ArrRentalContract.Site.Site_Name"
            /> */}
            <select
              name="Site_Name"
              onChange={e=>this.handleSite(e,values.ArrRentalContract.Site.Id)}
              value={undefined}
              className="form-control"
              type="select"
              id="Site_Name"
            > 
              {/* <option value=''  >Select Site</option> */}
          
              {this.SelectSite(values.ArrRentalContract.Site.Site_Name)}
              {this.SelectSiteExist(values.ArrRentalContract.Site.Site_Name)}
       
            </select> 
            
      
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="ArrRentalContract.Site.Province">
        Province
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="ArrRentalContract.Site.Province"
              onChange={handleChange}
              value={ this.state.Province}
              className="form-control"
              type="text"
              id="ArrRentalContract.Site.Province"
              disabled
            />
  
          </div>
        </div>
      </div>
     
            
      {/* <div className="container">
            <div className="table-responsive" style={{width:'50%'}}>
        <table className="table">
        <caption>List of EV car by Serial Number</caption>
  <thead>
    <tr>
     
      <th scope="col">Number Id</th>
      <th scope="col">Serial No.</th>
      <th scope="col">#</th>

    </tr>
  </thead>
 
   
  {
    this.state.ArrEVList.map(item=>{
      return  <tbody  key={item.Serial_Number}>
         <tr >
   
    <td>{item.Id}</td>
    <td>{item.Serial_Number}</td>
    <td>
      <a onClick={()=>{
        alert(`Do you want to remove EV Car No. ${item.Serial_Number}`)
        this.onRecoveryArrListEVCar(item)
        }}>Remove</a>
    </td>
    </tr>
    </tbody>
    })
  }

        </table>
      </div>
            </div>
    */}
      <div className="box-footer" style={{ marginTop: 50 }}>
        <button
          type="submit"
          className="btn btn-primary pull-right"
        >
          Update
        </button>
        <a
          onClick={() => {
            // this.props.clearProduct()
            this.props.history.goBack();
          }}
          type="Button"
          className="btn btn-default pull-right"
          style={{ marginRight: 10 }}
        >
          Cancel
        </a>
      </div>
    </form>
  
    )
    }
  }
 onShowEVListFromDB =()=>{

return   <div className="container">
<div className="table-responsive" style={{width:'50%'}}>
<table className="table">
<caption>List of EV car by Serial Number</caption>
<thead>
<tr>

<th scope="col">Number Id</th>
<th scope="col">Serial No.</th>
<th scope="col">#</th>

</tr>
</thead>


{
this.state.ArrEVList.length>0 ? this.state.ArrEVList.map(item=>{
return  <tbody  key={item.Serial_Number}>
<tr >

<td>{item.Id}</td>
<td>{item.Serial_Number}</td>
<td>
<a onClick={()=>{
  let check = window.confirm(`Do you want to remove EV Car No. ${item.Serial_Number}`);
  if(check){
    this.onRemoveEVCarListFromDB(item)
  }
// alert(`Do you want to remove EV Car No. ${item.Serial_Number}`)
// this.onRemoveEVCarListFromDB(item)
}}>Remove</a>
</td>
</tr>
</tbody>
}): null
}

</table>
</div>
</div>

   
 }
 onShowEVListSelect =()=>{

  return   <div className="container">
  <div className="table-responsive" style={{width:'50%'}}>
  <table className="table">
  <caption>List of EV car by Serial Number for Add new </caption>
  <thead>
  <tr>
  
  <th scope="col">Number Id</th>
  <th scope="col">Serial No.</th>
  <th scope="col">#</th>
  
  </tr>
  </thead>
  {/* {JSON.stringify(this.state.NewArrEVList)} */}
  
  {
  this.state.NewArrEVList.length>0 ? this.state.NewArrEVList.map(item=>{
  return  <tbody  key={item.Serial_Number}>
  <tr >
  
  <td>{item.Id}</td>
  <td>{item.Serial_Number}</td>
  <td>
  <a className="btn btn-warning" onClick={()=>{
  alert(`Do you want to remove EV Car No. ${item.Serial_Number}`)
  this.onRecoveryArrListEVCar(item)
  }}>Remove</a>
  </td>
  </tr>
  </tbody>
  }): null
  }
  
  </table>
  </div>
  </div>
  
     
   }

   ValidateEndDate = (StartDate,EndDate) =>{

      // let Check = moment(StartDate).format('DD-MM-YYYY')
      let FromNow =  moment(EndDate, "YYYYMMDD").fromNow();
      let FromNowStart =  moment(StartDate, "YYYYMMDD").fromNow();
    
       if(
         (
         FromNow.split(" ")[0]=='in' &&
         FromNow.split(" ")[1]>5 && 
         FromNow.split(" ")[2]==='days'
         )
         || 
        (  FromNow.split(" ")[0]=='in' &&
        ( FromNow.split(" ")[1]>0 || FromNow.split(" ")[1]==='a' )&& 
            ( FromNow.split(" ")[2]==='month'|| FromNow.split(" ")[2]==='months')
        )
        ||
        (  FromNow.split(" ")[0]=='in' &&
        ( FromNow.split(" ")[1]>0 || FromNow.split(" ")[1]==='a' )&& 
        (FromNow.split(" ")[2]==='year'||FromNow.split(" ")[2]==='years')
        )

         )
         {
      
        return true
       }else{
        return false
       }
    
  }
  render() {
    const {result}= this.props.rentalEditReducer
    

    return (
      <div className="content-wrapper">
        {/* Main content */}
        <section className="content" style={{ maxWidth: "80%" }}>
          <div className="box box-primary" style={{ marginTop: 70 }}>
            <div className="box-header with-border">
              <p className="box-title" style={{ fontSize: 30 }}>
              Rental Contract Detail
              </p>
              {/* {this.showFormTest(result)} */}
            </div>
            <div className="box-body" style={{ marginTop: 30 }}>
              <Formik
                enableReinitialize
                initialValues={result ? result: {}}
                onSubmit={(values, { setSubmitting }) => {
                  let checkValid =   this.ValidateEndDate(values.ArrRentalContract.Start_Date,values.ArrRentalContract.End_Date)
                    if(checkValid){
                      values.RemoveEVCarList = this.state.RemoveEVCarList
                      values.ArrEVList = this.state.ArrEVList
                      values.NewArrEVList = this.state.NewArrEVList
                      if(this.state.SiteId!=''){
                        values.ArrRentalContract.Site.Id = this.state.SiteId 
                      }
                      if(this.state.CustomerId!=''){
                        values.ArrRentalContract.CustomerId= this.state.CustomerId
                      }
                    
                        console.log(values)
                      //  alert(values.ArrRentalContract.CustomerId)
                      //  alert(JSON.stringify(values.ArrRentalContract.Site.Id))
                      
                       this.props.updateRentalContractById(values,values.ArrRentalContract.Id,this.props.history)
                       setSubmitting(false);
                      }
                   else{
                    alert("Invalid End Date , Please select  End Date more than 7 days")
                   }
           
                }} >
               {props=> this.showForm(props)} 
          
              
              </Formik>
              {this.onShowEVListFromDB()}
              {this.onShowEVListSelect()}
                {this.ComponentSelectEVCar()}
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>
    );
  }
}


const mapStateToProps = ({ rentalEditReducer }) => ({
  rentalEditReducer
});
const mapDispatchToProps = {
  ...actions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RentalEdit);

