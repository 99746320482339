import { httpClient } from "../utils/HttpClient";
import {
    HTTP_CREATE_PRODUCT_FETCHING,
    HTTP_CREATE_PRODUCT_SUCCESS,
    HTTP_CREATE_PRODUCT_FAILED,
  server,
//   HTTP_STOCK_CLEAR
} from "../constants";
import { join } from "lodash";

export const setStateCreateProductToSuccess = payload => ({
  type: HTTP_CREATE_PRODUCT_SUCCESS,
  payload
});

const setStateCreateProductToFetching = () => ({
  type: HTTP_CREATE_PRODUCT_FETCHING
});

const setStateCreateProductToFailed = payload => ({
  type: HTTP_CREATE_PRODUCT_FAILED,
  payload
});



  export const createProduct= (value,history) => {
    return async dispatch => {
      try {
        dispatch(setStateCreateProductToFetching()); // fetching
        let result = await httpClient.post(server.CREATE_PRODUCT,value);
        // alert(JSON.stringify(result.data))
        if (result.data.result == 'ok') {
          dispatch(setStateCreateProductToSuccess(result.data));
          history.push('/product')
        // history.push('/rental')
        } else {        
          alert(JSON.stringify(result.data.message))
          history.push('/product')
          dispatch(setStateCreateProductToFailed(result.data));
        //   history.push('/rental')
        }
      } catch (error) {        
        history.push('/product')
          dispatch(setStateCreateProductToFailed({data: {message: error}}));
        //   history.push('/rental')
      }
    };
  };


  export const updateProduct= (Id,value,history) => {
    return async dispatch => {
      try {
        dispatch(setStateCreateProductToFetching()); // fetching
        let result = await httpClient.put(`${server.UPDATE_PRODUCT_BY_ID}${Id}`,value);
        // alert(JSON.stringify(result.data))
        if (result.data.result == 'ok') {
          dispatch(setStateCreateProductToSuccess(result.data));
        
        history.push('/product')
        } else {        
          alert(JSON.stringify(result.data.message))
          dispatch(setStateCreateProductToFailed(result.data));
          history.push('/product')
        }
      } catch (error) {        
          dispatch(setStateCreateProductToFailed({data: {message: error}}));
          history.push('/product')
      }
    };
  };


  
  
  