import React, { Component } from 'react';
import * as actions from "../../actions/editBMTask.action";
import { connect } from "react-redux";
import { Formik ,FieldArray,Field} from "formik";
import { imageUrl, server } from "../../constants";
import { Link } from "react-router-dom";
import _, { join, values } from "lodash";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import "./editBM.css";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from 'react-loader-spinner'

const MySwal = withReactContent(Swal)
var jwt = require("jsonwebtoken");
const moment = require('moment')

const orderid = require('order-id')('mysecret');
const id = orderid.generate();

class editBM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ListContractNumber:[],
      Id:'',
      SelectServicePrice:[],
      AmountProduct:[],
      SelectedProduct:[],
      CustomerName:'',
      ContractId:'',
      Contract_Number:'',
      BM_Location:'',
      BM_ID:'',
      CountEVListForBMByContract:'',
      ListEVCar:[],
      EVCarStoreValue:[],
      SelectCar:[],
      EVCarFromRentalDB:[],
      InputTemp:'',
      Total:'',
      ContractorId:'',
      EVCarRent:[],
      Appointment_Date:'',
      ContractorTempDB:'',
      TempContractor:[]
    };
  }
  componentDidMount(){
    let id = this.props.match.params.id
    // alert(id)
    this.props.getAllDataForSelect(id).then(result=>{
      this.ArrEVCar()
      this.setStateInputFromDB()
    })
 
  }
  SelectContractorForBM = (ContractorId)=>{
    let {isFetching,result} = this.props.editBmTaskReducer


    if(this.state.ContractorTempDB!=''){
      // alert(this.state.ContractorTempDB)
      return (
        result!=null ? result.Contractor.filter(val=> val.Id!=this.state.ContractorTempDB).map((item,index)=>{
        return  <option  key={item.Id} value={JSON.stringify(item)}>{item.Contractor_Name}</option>
        }):null
      );
    }
    else{

  return (
    result!=null ? result.Contractor.map((item,index)=>{
    return  <option  key={item.Id} value={JSON.stringify(item)}>{item.Contractor_Name}</option>
    }):null
  );
 
    }
  
  }
  SelectProductForBM = (filter)=>{
    let {isFetching,result} = this.props.editBmTaskReducer
    return (
      result!=null ? result.Product.map((item,index)=>{
      return  <option  key={item.Id} value={JSON.stringify(item)}>{item.Item_Name}</option>
      }):null
    );
    
  }

  SelectCustomerForBM = (Customer_Name)=>{
    let {isFetching,result} = this.props.editBmTaskReducer
    let unique = []
    function removeDuplicates(data, key) {

      return [
        ...new Map(data.map(item => [key(item), item])).values()
      ]
    };
    if(result){
      unique = removeDuplicates(result.Rental,item=>item.Customer.Customer_Name)
    }
    
    return (
      result!=null && unique.length>0? unique.filter(val=>val.Customer.Customer_Name!==Customer_Name).map((item,index)=>{
      return  <option  key={item.Id} value={JSON.stringify(item)}>{item.Customer.Customer_Name}</option>
      }):null
    );
  }

  SelectCustomer = ()=>{
    let {isFetching,result} = this.props.editBmTaskReducer
    return (
      result!=null ? result.Customer.map((item,index)=>{
      return  <option  key={item.Customer_Name} value={item.Id}>{item.Customer_Name}</option>
      }):null
    );
    
  }
  SelectLocation= ()=>{
    let {isFetching,result} = this.props.editBmTaskReducer
    return (
      result!=null ? result.Location.map((item,index)=>{
      return  <option  key={item.Id} value={item.Id}>{item.Site_Name}</option>
      }):null
    );
    
  }
  SelectEVCar= ()=>{
    let {isFetching,result} = this.props.editBmTaskReducer
    return (
      this.state.ListEVCar!=null ? this.state.ListEVCar.map((item,index)=>{
      return  <option  key={item.Serial_Number} value={JSON.stringify(item)} >{item.Serial_Number}</option>
      }):null
    );

  }
   ArrEVCar = () =>{
    let {isFetching,result} = this.props.editBmTaskReducer
    if(result!=null){
      this.setState({EVCarStoreValue:result.EVCar})
      let Temp = result.EVCar
     this.setState({ListEVCar:Temp})
    
    }
  }
 
  onHandleArrListEVCar = async (event) =>{
   let ListCar  = this.state.ListEVCar
  if(event.target.value !=null&& ListCar!=null){
 
     let PreLoad =  await JSON.parse(event.target.value)
 
      if(PreLoad){
        this.setState({SelectCar:[...this.state.SelectCar,PreLoad]})
        this.setState({CountEVListForBMByContract:this.state.SelectCar.length})

        let arr = await ListCar.filter(function(item) {
          return item.Serial_Number !== PreLoad.Serial_Number
      })
      this.setState({ListEVCar:arr})
      }else{
        // alert("NOK")
      }
}
  }




onRecoveryArrListEVCar = async(value) =>{
  // alert(value.Serial_Number)
  let {isFetching,result} =this.props.editBmTaskReducer
//  let ListCar  = localStorage.getItem("EVCar")
 let ListCar = this.state.EVCarStoreValue
 let selectCar = this.state.SelectCar
if(value !=null&& result!=null){

  let arr = await selectCar.filter(function(item) {
    return item.Serial_Number !== value.Serial_Number
})


  this.setState({SelectCar:arr})
  this.setState({CountEVListForBMByContract:this.state.SelectCar.length})
  //  let found=  await JSON.parse(ListCar).find(item=>{
  //   console.log(item.Serial_Number,value.Serial_Number)
  //    if(item.Serial_Number===value.Serial_Number)
  //    {
  //      return item
  //    }
  //   })
  let found=  await ListCar.find(item=>{
    console.log(item.Serial_Number,value.Serial_Number)
     if(item.Serial_Number===value.Serial_Number)
     {
       return item
     }
    })

     let Dummy =  this.state.ListEVCar
     Dummy.unshift(found)

         this.setState({ListEVCar:Dummy})

   
  // console.log("CHECKSTATE",this.state.ListEVCar)
}

  }

 
  ValidateEndDate = (StartDate,EndDate) =>{
    if(EndDate){
      // let Check = moment(StartDate).format('DD-MM-YYYY')
      let FromNow =  moment(EndDate, "YYYYMMDD").fromNow();
      let FromNowStart =  moment(StartDate, "YYYYMMDD").fromNow();
    
       if(
         (
         FromNow.split(" ")[0]=='in' &&
         FromNow.split(" ")[1]>5 && 
         FromNow.split(" ")[2]==='days'
         )
         || 
        (  FromNow.split(" ")[0]=='in' &&
        ( FromNow.split(" ")[1]>0 || FromNow.split(" ")[1]==='a' )&& 
            ( FromNow.split(" ")[2]==='month'|| FromNow.split(" ")[2]==='months')
        )
        ||
        (  FromNow.split(" ")[0]=='in' &&
        ( FromNow.split(" ")[1]>0 || FromNow.split(" ")[1]==='a' )&& 
        (FromNow.split(" ")[2]==='year'||FromNow.split(" ")[2]==='years')
        )

         )
         {
      
        return true
       }else{
        return false
       }
    }
  }

  onHandleSelectProduct = (event,item) =>{
    // alert(item.Serial_Number)
    if(event.target.value!=''){
      let arr  = JSON.parse(event.target.value)
   
    let Duplicated = this.state.SelectedProduct.filter((val)=>{
      return (val.Product.Item_Name == arr.Item_Name)&&(val.Serial_Number === item.Serial_Number)
    })
  
    if(Duplicated.length===0){
      let ProductList ={
        Product:arr,
        Serial_Number:item.Serial_Number
    }
   this.setState({SelectedProduct:[...this.state.SelectedProduct,ProductList]})
  //  console.log(this.state.SelectedProduct)
    }else{
      alert('Duplicated your selected product ,Please try again')
    }
    
   
    }
   }
   onGetStateOfActualPrice = (val,ItemName) =>{

    let newArr = this.state.AmountProduct.filter(itemF=>{
      return  itemF.Serial_Number === val && itemF.Item_Name===ItemName
    })
    // console.log("GETSTATE",newArr)
    return newArr.length>0  ? newArr[0].Actual_Price:0
   }
   onGetStateOfSelectProduct = (val,ItemName)  =>{

    let newArr = this.state.AmountProduct.filter(itemF=>{
      return  itemF.Serial_Number === val && itemF.Item_Name ===ItemName
    })
    // console.log("GETSTATE",newArr)
    return newArr.length>0  ? newArr[0].Amount:0
   }

   onGetStateOfServicePrice = (val) =>{
    let newArr = this.state.SelectServicePrice.filter(itemF=>{
      return  itemF.Serial_Number === val.Serial_Number
    })
    // console.log("GETSTATE",newArr)
    return newArr.length>0  ? newArr[0].Service_Price:0
   }

   onHandleServicePrice = (event,item) =>{
    // console.log(this.state.SelectServicePrice)
      if(event.target.value>0){
        let Duplicated = this.state.SelectServicePrice.filter(itemF=>{
            return itemF.Serial_Number === item.Serial_Number
          })
          if(Duplicated.length>0){
            let oldArr = this.state.SelectServicePrice.filter(itemF=>{
              return itemF.Serial_Number !== item.Serial_Number
            })
            let newArr = {
              Serial_Number:item.Serial_Number,
              Service_Price:parseFloat(event.target.value)
            }
            oldArr.push(newArr)
            this.setState({SelectServicePrice:oldArr})
            
          }else{
            let newArr = {
              Serial_Number:item.Serial_Number,
              Service_Price:parseFloat(event.target.value)
            }
            this.setState({SelectServicePrice:[...this.state.SelectServicePrice,newArr]})
          }
         
      }else{
          console.log("DO THIS")
        let Duplicated = this.state.SelectServicePrice.filter(itemF=>{
          return itemF.Serial_Number === item.Serial_Number
        })
        if(Duplicated.length>0){
          let oldArr = this.state.SelectServicePrice.filter(itemF=>{
            return itemF.Serial_Number !== item.Serial_Number
          })
          let newArr = {
            Serial_Number:item.Serial_Number,
            Service_Price:0
          }
          oldArr.push(newArr)
          this.setState({SelectServicePrice:oldArr})
        }


      }
 
   }

  handleUtilization = (event,itemName,Input_Serial_Number,Price,Id) =>{

    if(event.target.value!=''&&event.target.value>0){

      // console.log("INPUT",event.target.value,itemName,Input_Serial_Number)
      let Duplicated =this.state.AmountProduct.filter(val=>{
        // console.log("VAL",val)
        return (val.Item_Name===itemName) && (val.Serial_Number === Input_Serial_Number)
      })
      // console.log("DUPLICATED",Duplicated)
      if(Duplicated.length>0){
        let newArr = {
          "Id":Id,
          "Item_Name":itemName,
          "Serial_Number":Input_Serial_Number,
          "Price_List":Price,
          "Actual_Price":0,
          "Amount":event.target.value,
          "SubTotal":event.target.value*Price
        }
        // let oldArr = this.state.AmountProduct.filter(val=>{
        //   // console.log("VAL",val)
        //   return (val.Item_Name!==itemName) 
        // })
        // oldArr.push(newArr)
        // this.setState({AmountProduct:oldArr})
        let existArr = this.state.AmountProduct.filter(val=>{
          // console.log("VAL",val)
          return (val.Serial_Number !== Input_Serial_Number)
        })
        let oldArr = this.state.AmountProduct.filter(val=>{
          // console.log("VAL",val)
          return (val.Item_Name!==itemName) && (val.Serial_Number === Input_Serial_Number)
        })

        if(oldArr.length>0)
        {
          existArr.push(...oldArr)
        }
        existArr.push(newArr)
       
   
        this.setState({AmountProduct:existArr})
      }else{

        let newArr = {
          "Id":Id,
          "Item_Name":itemName,
          "Serial_Number":Input_Serial_Number,
          "Price_List":Price,
          "Actual_Price":0,
          "Amount":event.target.value,
          "SubTotal":event.target.value*Price
        }
           this.setState({AmountProduct:[...this.state.AmountProduct,newArr]})
       
      }
     
  
    //  console.log(this.state.AmountProduct)
    }else{
      let Duplicated =this.state.AmountProduct.filter(val=>{
        // console.log("VAL",val)
        return (val.Item_Name===itemName) && (val.Serial_Number === Input_Serial_Number)
      })
      // console.log("DUPLICATED",Duplicated)
      if(Duplicated.length>0){
        let newArr = {
          "Id":Id,
          "Item_Name":itemName,
          "Serial_Number":Input_Serial_Number,
          "Price_List":Price,
          "Actual_Price":0,
          "Amount":0,
          "SubTotal":0
        }
        // let oldArr = this.state.AmountProduct.filter(val=>{
        //   // console.log("VAL",val)
        //   return (val.Item_Name!==itemName) 
        // })
        // oldArr.push(newArr)
        // this.setState({AmountProduct:oldArr})
        let existArr = this.state.AmountProduct.filter(val=>{
          // console.log("VAL",val)
          return (val.Serial_Number !== Input_Serial_Number)
        })
        let oldArr = this.state.AmountProduct.filter(val=>{
          // console.log("VAL",val)
          return (val.Item_Name!==itemName) && (val.Serial_Number === Input_Serial_Number)
        })

        if(oldArr.length>0)
        {
          existArr.push(...oldArr)
        }
        existArr.push(newArr)
       
   
        this.setState({AmountProduct:existArr})
      }else{

        let newArr = {
          "Id":Id,
          "Item_Name":itemName,
          "Serial_Number":Input_Serial_Number,
          "Price_List":Price,
          "Actual_Price":0,
          "Amount":0,
          "SubTotal":0
        }
           this.setState({AmountProduct:[...this.state.AmountProduct,newArr]})
       
      }
    }

  }

  handleActualUtilization = (event,itemName,Input_Serial_Number,Price,Id) =>{
    // alert(Input_Serial_Number)
    if(event.target.value!=''&&event.target.value>0){

      let Duplicated =this.state.AmountProduct.filter(val=>{
        return (val.Item_Name===itemName) && (val.Serial_Number === Input_Serial_Number)
      })
      // alert("ok")
      if(Duplicated.length>0){
        // alert(JSON.stringify(Duplicated))
        let newArr = {
          "Id":Id,
          "Item_Name":itemName,
          "Serial_Number":Input_Serial_Number,
          "Price_List":Price,
          "Actual_Price":parseFloat(event.target.value),
          "Amount":parseFloat(Duplicated[0].Amount),
          "SubTotal":(event.target.value*Duplicated[0].Amount)
        }
        // alert(itemName)
        let existArr = this.state.AmountProduct.filter(val=>{
          // console.log("VAL",val)
          return (val.Serial_Number !== Input_Serial_Number)
        })
        let oldArr = this.state.AmountProduct.filter(val=>{
          // console.log("VAL",val)
          return (val.Item_Name!==itemName) && (val.Serial_Number === Input_Serial_Number)
        })

        if(oldArr.length>0)
        {
          existArr.push(...oldArr)
        }
        existArr.push(newArr)
       
   
        this.setState({AmountProduct:existArr})
      }else{
        
      }
  
    //  console.log(this.state.AmountProduct)
    }else{

      // alert("nok")
      let Duplicated =this.state.AmountProduct.filter(val=>{
   
        return (val.Item_Name===itemName) && (val.Serial_Number === Input_Serial_Number)
      })
     
      if(Duplicated.length>0){
        let newArr = {
          "Id":Id,
          "Item_Name":itemName,
          "Serial_Number":Input_Serial_Number,
          "Price_List":Price,
          "Actual_Price":0,
          "Amount":parseFloat(Duplicated[0].Amount),
          "SubTotal":(Price*Duplicated[0].Amount)
        }
        let existArr = this.state.AmountProduct.filter(val=>{
          // console.log("VAL",val)
          return (val.Serial_Number !== Input_Serial_Number)
        })
        let oldArr = this.state.AmountProduct.filter(val=>{
          // console.log("VAL",val)
          return (val.Item_Name!==itemName) && (val.Serial_Number === Input_Serial_Number)
        })

        if(oldArr.length>0)
        {
          existArr.push(...oldArr)
        }
        existArr.push(newArr)
       
        this.setState({AmountProduct:existArr})
      }
    }

  }

  onDeleteEVCarFromDBList = (Serial_Number) =>{
    // alert(Serial_Number)
      let EVCarDB = this.state.EVCarFromRentalDB
      let SelectedProducts = this.state.SelectedProduct
      let amountProducts = this.state.AmountProduct
      let servicePrices = this.state.SelectServicePrice

      let removeServicePrice =servicePrices.filter(item=>{
  
        return item.Serial_Number !== Serial_Number
     })
    this.setState({SelectServicePrice:removeServicePrice})


      let amountProdArr = amountProducts.filter(item=>{
  
        return item.Serial_Number !== Serial_Number
     })
    this.setState({AmountProduct:amountProdArr})


      let selectedProdArr = SelectedProducts.filter(item=>{
  
        return item.Serial_Number !== Serial_Number
     })
    this.setState({SelectedProducts:selectedProdArr})


      let newArr = EVCarDB.filter(item=>{
  
          return item.Serial_Number !== Serial_Number
      })
      console.log("newArr",newArr)
      
      this.setState({EVCarFromRentalDB:newArr})


      this.setState({CountEVListForBMByContract:newArr.length})
  }
  onHandleSelectCustomerForBMByRent = (event) =>{
    
    // alert(event.target.value)
    if(event.target.value!=''){
        //  alert(event.target.value)
        let {isFetching,result} = this.props.editBmTaskReducer
      let arrL =  JSON.parse(event.target.value)
      this.setState({CustomerName:arrL.Customer.Customer_Name})
      this.setState({BM_Location:arrL.Site.Site_Name})
      this.setState({EVCarFromRentalDB:arrL.RentEVCar})
      this.setState({Contract_Number:arrL.Contract_Number})
      this.setState({ContractId:arrL.Id})
      this.setState({CountEVListForBMByContract:arrL.RentEVCar.length})

      this.setState({SelectServicePrice:[]})
      this.setState({SelectedProduct:[]})
      this.setState({AmountProduct:[]})
      this.setState({Appointment_Date:''})
      
      let ListContract = result.Rental.filter(val=>val.Customer.Customer_Name===arrL.Customer.Customer_Name)
      
      // alert(JSON.stringify(arrL.Customer.Customer_Name))
      // alert(JSON.stringify(ListContract))
      this.setState({ListContractNumber:ListContract})
    }
    else{
      // this.setState({Appointment_Date:''})
      this.setState({BM_Location:''})
      this.setState({EVCarFromRentalDB:[]})
      this.setState({ContractId:''})
      this.setState({Contract_Number:''})
      this.setState({CountEVListForBMByContract:''})
      this.setState({CustomerName:''})
    }

   
  }
  CalculateTotalMaintenance = () =>{

      let ServiceCost = this.state.SelectServicePrice.reduce((accumulator, current) => accumulator + current.Service_Price,0)
      let ProductCost = this.state.AmountProduct.reduce((accumulator, current) => accumulator + current.SubTotal,0)
      let Sum = parseFloat(ServiceCost)+parseFloat(ProductCost)
      return Sum
    
  }
  onHandleSelectContractorBMByRent = (event) =>{
    if(event.target.value!=''){
      // alert(event.target.value)
      let arr = JSON.parse(event.target.value)

      let newArr = this.state.SelectServicePrice
      let saveArr = []
      let existValue= this.state.SelectServicePrice.filter(val=>val.ContractorId===arr.Id)
      if(existValue.length===0){  
        saveArr=  newArr.map(item=> {
            if(item.ContractorId != arr.Id){
              item.ContractorId =arr.Id
              return item
            }
          })
      this.setState({SelectServicePrice:saveArr})
      }
      this.setState({ContractorId:arr.Id})
    
    }
  }
  setStateServicePrice = (EVCar,ServicePrice) =>{
    let newArr = []
    EVCar.forEach(ItemA=>{
      ServicePrice.forEach(ItemB=>{
        if(ItemA.Serial_Number===ItemB.Serial_Number){
          newArr.push({
            Id:ItemA.Id,
            Contract_Number:ItemA.Contract_Number,
            Serial_Number:ItemA.Serial_Number,
            Start_Date:ItemA.Start_Date,
            End_Date:ItemA.End_Date,
            Service_Price:parseFloat(ItemB.Service_Price)
          })
        }
      })
    })
    if(newArr.length>0){
      this.setState({EVCarFromRentalDB:newArr})
    }
     
  }

  setStateProductPrice = (EVCar,ProductPrice) =>{
    let newArr = []
    EVCar.forEach(ItemA=>{
      let Prices= 0
      ProductPrice.forEach(ItemB=>{
        if(ItemA.Serial_Number===ItemB.Serial_Number){
          Prices = Prices + ItemB.SubTotal
        }
      })
     
      newArr.push({
        Id:ItemA.Id,
        Contract_Number:ItemA.Contract_Number,
        Serial_Number:ItemA.Serial_Number,
        Start_Date:ItemA.Start_Date,
        End_Date:ItemA.End_Date,
        Service_Price:ItemA.Service_Price,
        Product_Price:parseFloat(Prices)
      })

     
    })
    if(newArr.length>0){
      this.setState({EVCarFromRentalDB:newArr})
    }
     
  }

  setStateInputFromDB = () =>{
      let {result} = this.props.editBmTaskReducer
      if(result.BMTask!=null){
        let BMTask = result.BMTask
        let ProductDB = result.Product

        this.setState({Id:BMTask.Id})
        this.setState({BM_ID:BMTask.BM_ID})
        this.setState({ContractId:BMTask.Rental.Id})

        this.setState({CustomerName:BMTask.Customer_Name})
        this.setState({Contract_Number:BMTask.Rental.Contract_Number})
        this.setState({BM_Location:BMTask.Location})
        this.setState({Contractor_Name:BMTask.Contractor.Contractor_Name})
        this.setState({ContractorId:BMTask.Contractor.Id})
        this.setState({ContractorTempDB:BMTask.Contractor.Id})
        this.setState({Appointment_Date:moment(BMTask.Appointment_Date).format('yyyy-MM-DD')})
        this.setState({CountEVListForBMByContract:BMTask.Qty})
        let Temp = result.Rental.filter(val=>val.Contract_Number===BMTask.Rental.Contract_Number)
        this.setState({EVCarFromRentalDB:Temp[0].RentEVCar})
        
        let newArr2 = result.Contractor.filter(val=>val.Id===BMTask.Contractor.Id)
        this.setState({TempContractor:newArr2[0]})
        this.setState({SelectServicePrice:result.ArrService})
        let newArrProduct = []
        result.ArrProduct.forEach(ItemA=>{
          ProductDB.forEach(ItemB=>{
            let tempArr
            if(ItemB.Item_Name===ItemA.Item_Name){
              tempArr = {
                Product:ItemB,
                Serial_Number:ItemA.Serial_Number
              }
              console.log("TEMP",tempArr)
              newArrProduct.push(tempArr)
            }else{
             tempArr = null
            }
          })
        })
        this.setState({SelectedProduct:newArrProduct})
        this.setState({AmountProduct:result.ArrProduct})
     
        
        
        // this.setState({EVCarFromRentalDB:arrL.RentEVCar})
        // this.setState({Contract_Number:arrL.Contract_Number})
        // this.setState({ContractId:arrL.Id})
     
      }
   
  }

  onRemoveSelectedProduct = (Serial_Number,ItemName) =>{
 

    let existSelectedProduct = this.state.SelectedProduct.filter(ItemA=>{
      return  ItemA.Serial_Number !== Serial_Number
    })
    let duplicatedSelectedProduct = this.state.SelectedProduct.filter(ItemA=>{
      return  ItemA.Serial_Number === Serial_Number && ItemA.Product.Item_Name !==ItemName
    })
    console.log("DUPLICATED ITEM",duplicatedSelectedProduct)
    existSelectedProduct.push(...duplicatedSelectedProduct)
  
    this.setState({SelectedProduct:existSelectedProduct})


    let existAmountProduct = this.state.AmountProduct.filter(ItemA=>{
      return  ItemA.Serial_Number !== Serial_Number
    })
    let duplicatedAmountProduct = this.state.AmountProduct.filter(ItemA=>{
      return  ItemA.Serial_Number === Serial_Number && ItemA.Item_Name !==ItemName
    })
    existAmountProduct.push(...duplicatedAmountProduct)
    this.setState({AmountProduct:existAmountProduct})
   }

   SelectListContract = (Contract_Number,Customer_Name) =>{
    // this.state.ListContractNumber

    // return (
    //   this.state.ListContractNumber!=null ? this.state.ListContractNumber.filter(val=>val.Contract_Number ===Contract_Number).map((item,index)=>{
    //   return  <option  key={item.Id} value={JSON.stringify(item)}>{item.Contract_Number}</option>
    //   }):null
    // );/   return (
      let {result} = this.props.editBmTaskReducer
      return (  result!=null ? result.Rental.filter(val=>(val.Customer.Customer_Name===Customer_Name)).map((item,index)=>{
        return  <option  key={item.Id} value={JSON.stringify(item)}>{item.Contract_Number}</option>
        }):null)
    
   }
   SelectListContracts = (Contract_Number,Customer_Name) =>{
    // this.state.ListContractNumber
    return (
      this.state.ListContractNumber!=null ? this.state.ListContractNumber.filter(val=>val.Contract_Number !==Contract_Number).map((item,index)=>{
      return  <option  key={item.Id} value={JSON.stringify(item)}>{item.Contract_Number}</option>
      }):null
    );
  
   }
   SetStateContractId = (event)=>{
     if(event.target.value!=''){
      let {isFetching,result} = this.props.editBmTaskReducer
      let value = JSON.parse(event.target.value)
 
      let ListContract = result.Rental.filter(val=>val.Contract_Number===value.Contract_Number)
      console.log(ListContract[0].Id)
        
      this.setState({ContractId:ListContract[0].Id})
       this.setState({BM_Location:ListContract[0].Site.Site_Name})
       this.setState({EVCarFromRentalDB:ListContract[0].RentEVCar})
      this.setState({CountEVListForBMByContract:ListContract[0].RentEVCar.length})
       this.setState({Contract_Number:value.Contract_Number})
     }
   }
  showForm1 =({values,handleChange,handleSubmit, setFieldValue})=>{

    return (
      <form
      className="form-horizontal"
      onSubmit={handleSubmit}>
      <div className="form-group">
 
        <label className="col-sm-2 control-label" htmlFor="BM_ID">
          BM ID
        </label>
        <div className="col-sm-10">
          <div  className="input-group" style={{width:255}}>
          <input
            name="BM_ID"
            onChange={handleChange}
            value={values.BM_ID||this.state.BM_ID}
            placeholder="Please fill required field"
            className="form-control"
            type="text"
            id="BM_ID"
            disabled
          />
          </div>
        </div>
      </div>


            
    <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="CustomerName">
          Customer Name
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <select
              name="CustomerName"
              onChange={this.onHandleSelectCustomerForBMByRent}
              value={undefined}
              className="form-control"
              type="select"
              id="CustomerName"
              required
            > 
             { this.state.CustomerName!= ''? <option value={this.state.CustomerName}  >{this.state.CustomerName}</option>:<option value=''  >Select Customer</option>}

              {this.SelectCustomerForBM(this.state.CustomerName)}
           
       
            </select>

             
          </div>
        </div>
      </div>
      

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Contract_Number">
        Contract Number
        </label>
        <div className="col-sm-10">
          <div className="input-group">
          {/* <input
            name="Contract_Number"
            onChange={handleChange}
            value={values.ContractId||this.state.Contract_Number}
            className="form-control"
            type="text"
            id="Contract_Number"
            disabled
          /> */}
             <select
          name="Contractor_Name"
          onChange={e=>this.SetStateContractId(e)}
          value={undefined}
          className="form-control"
          type="select"
          id="Contractor_Name"
          required
        > 
      
         {/* { this.state.Contract_Number!= ''? 
          <option value={this.state.Contract_Number} >{this.state.Contract_Number}</option>

          :<option value=''  >Select Contract Number</option>}  */}


          {this.SelectListContract(this.state.Contract_Number,this.state.CustomerName)}
          {/* {this.SelectListContracts(this.state.Contract_Number,this.state.CustomerName)} */}
  
   
        </select>
         
      
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Location">
          Location 
        </label>
        <div className="col-sm-10">
          <div className="input-group">
          <input
            name="Location"
            onChange={handleChange}
            value={values.Location||this.state.BM_Location}
            className="form-control"
            type="text"
            id="Location"
            disabled
          />
      
          </div>
        </div>
      </div>
      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Contractor">
          Contractor Name
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <select
              name="Contractor"
              onChange={this.onHandleSelectContractorBMByRent}
              value={this.state.contractorId}
              className="form-control"
              type="select"
              id="Contractor"
              required
            > 
              {this.state.Contractor_Name!=''?<option value={JSON.stringify(this.state.TempContractor)}  >{this.state.Contractor_Name}</option> :<option value=''  >Select Contractor</option>}
          
      
              {this.SelectContractorForBM()}
       
            </select>
      
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Appointment_Date">
        Appointment Date
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="Appointment_Date"
              onChange={handleChange}
              value={values.Appointment_Date || this.state.Appointment_Date}
              className="form-control"
              type="date"
              required
            />
      
          </div>
        </div>
      </div>
      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Qty">
        Maintenance EV Car
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="Qty"
              onChange={handleChange}
              value={values.Qty || this.state.CountEVListForBMByContract}
              className="form-control"
              type="text"
              disabled
            />
          
          </div>
        </div>
      </div>
  
   
      

      <div className="box-footer" style={{ marginTop: 50 }}>
        <button
          type="submit"
          className="btn btn-primary pull-right"
        >
          Save
        </button>
        <a
          onClick={() => {
            // this.props.clearProduct()
            this.props.history.goBack();
          }}
          type="Button"
          className="btn btn-default pull-right"
          style={{ marginRight: 10 }}
        >
          Cancel
        </a>
      </div>
    </form>
  
    )
    
  }

  ComponentEVCarFromDB = () =>{
    return this.state.EVCarFromRentalDB.length>0? this.state.EVCarFromRentalDB.map((item,index)=>{
      return     <tr key={item.Serial_Number} style={{textAlign:'center'}}>
      {/* <td><Moment format="DD/MM/YYYY">{item.createdAt}</Moment></td> */}
      {/* <td>
           
            {item.Id}         
        </td> */}
        <td>{index+1}</td>
        <td>
        {item.Serial_Number}    
        </td>
        <td>

        <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-6 control-label" htmlFor="Service_Price">
        Service Price
        </label>
        <div className="col-sm-6">
          <div className="input-group">
          <input
              name="Service_Price"
              onChange={e=>this.onHandleServicePrice(e,item)}
              value={this.onGetStateOfServicePrice(item)}
              className="form-control"
              type="number"
              id="Service_Price"
      
            /> 
            
          
          </div>
        </div>
      </div>
        <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-6 control-label" htmlFor="Qty">
        Select maintenance part
        </label>
        <div className="col-sm-6">
          <div className="input-group">
          <select
              name="ProductId"
              onChange={e=>this.onHandleSelectProduct(e,item)}
              value={values.ProductId}
              className="form-control"
              type="select"
              id="ProductId"
            > 
              <option value=''  >Select Part</option>
          
              {this.SelectProductForBM()}
       
            </select> 
          
          </div>
        </div>
      </div>
 
      <div className="form-group" style={{ marginBottom: 10 }}>
        {/* <label className="col-sm-6 control-label" htmlFor="Qty">
     Qty
        </label> */}
        <div className="col-sm-12">
          <div className="input-group">
         
          
          </div>
        </div>
      </div>
         
            {this.state.SelectedProduct.length>0 ? this.state.SelectedProduct.filter(value=>value.Serial_Number===item.Serial_Number).map((itemS,index)=>{
              return item.Serial_Number==itemS.Serial_Number ?(
               
                   <div className="row" key={itemS.Product.Id}>
                      <div  className="col-2">   <h5 className="align-middle"> {index+1 }.{itemS.Product.Item_Name}</h5>  
                       <button className="btn btn-warning"
                            onClick={()=>{
              
                              MySwal.fire({
                                title: 'Are you sure to delete?',
                                text: "You won't be able to revert this!",
                                type: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, delete it!',
                                cancelButtonText: 'No, cancel!',                                
                              }).then(async(result) => {
                                if (result.value){
                         
                                  // await this.onRecoveryArrListEVCar(item)
                                  await this.onRemoveSelectedProduct(itemS.Serial_Number,itemS.Product.Item_Name)
                                  // this.props.deleteProduct(item.product_id)
                                }                 
                              })   
                
                            }}      
                       >remove Item</button> </div>
              <div  className="col-2">
                            <img src={itemS.Product.Image_No1}  className="img-fluid img-thumbnail"/>
           
              <input
                name="InputTemp"
                onChange={e=>this.handleUtilization(e,itemS.Product.Item_Name,itemS.Serial_Number,itemS.Product.Price_List,itemS.Product.Id)}
                value={this.onGetStateOfSelectProduct(itemS.Serial_Number,itemS.Product.Item_Name)}
                placeholder={itemS.Product.Unit}
                type="number"
                min="0.1"

              />  
            
        
         
              </div>
              <div  className="col-3">
                <h5>   estimated price</h5>
              </div>
              <div className="col-1">  
           
              <input
                name="InputTemp2"
                // onBlur={e=>this.handleUtilization(e,itemS.Product.Item_Name,itemS.Serial_Number,itemS.Product.Price_List)}
                value={values.estimate}
                placeholder={itemS.Product.Price_List}
                type="number"
                min="0.1"
                disabled

              />  
              </div>

              <div  className="col-3">
                <h5>   actual price</h5>
              </div>
              <div className="col-1">  
           
             { this.state.AmountProduct.length>0? <input
                name="InputTemp1"
                onChange={e=>this.handleActualUtilization(e,itemS.Product.Item_Name,itemS.Serial_Number,itemS.Product.Price_List,itemS.Product.Id)}
                value={this.onGetStateOfActualPrice(itemS.Serial_Number,itemS.Product.Item_Name)}
                // placeholder={itemS.Product.Price_List}
                type="number"
                min="0.1"
              

              />:null  }
              </div>
              <hr/>
              </div>
                
              ):null
            }):null}
          {/* <p>test{JSON.stringify(this.state.EVCarFromRentalDB.ProductList)}</p> */}
        </td>
        <td>
         <p>Service Price : { this.state.SelectServicePrice.filter(value=>value.Serial_Number===item.Serial_Number).reduce((accumulator,{Service_Price})=>accumulator+Service_Price, [])}</p>
          <p>Consumable Price : {    this.state.AmountProduct.filter(value=>value.Serial_Number===item.Serial_Number).reduce((accumulator,current) => accumulator + current.SubTotal, 0)}</p> 
          {/* <p>Service Price : {
            this.setStateAndShowServicePrice(this.state.SelectServicePrice.filter(value=>value.Serial_Number===item.Serial_Number).reduce((accumulator,{Service_Price})=>accumulator+Service_Price, []),item.Serial_Number)
          }</p> */}
          {/* <p>Consumable Price : {    this.state.AmountProduct.filter(value=>value.Serial_Number===item.Serial_Number).reduce((accumulator,current) => accumulator + current.SubTotal, 0)}</p> */}
          </td>
        {/* <td><Moment format="DD/MM/YYYY">{item.Manufacturing_Date}</Moment></td> */}
       

 
   
        <td style={{ textAlign: "center", }}>
          <button   
            onClick={()=>{
              
              MySwal.fire({
                title: 'Are you sure to delete?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',                                
              }).then(async(result) => {
                if (result.value){
         
                  // await this.onRecoveryArrListEVCar(item)
                  await this.onDeleteEVCarFromDBList(item.Serial_Number)
                  // this.props.deleteProduct(item.product_id)
                }                 
              })   

            }}           
            type="button"
            className="btn btn-danger"
          >
            Delete
          </button>
        
        </td>
    </tr>
  }):null
  }

















  ComponentSelectEVCar = () =>{
    return    <div className="form-group" style={{ marginBottom: 10 }}>
    <label className="col-sm-2 control-label" htmlFor="RentalCarListD">
   SELECTED LIST EV CAR
    </label>
    <div className="col-sm-10">
      <div className="input-group">
        <select
          name="RentalCarListD"
          onChange={(this.onHandleArrListEVCar)}
          value={values.RentalCarListD}
          className="form-control"
          type="select"
          id="RentalCarListD"
        > 
          <option value='' >Select EV Car</option>

          {this.SelectEVCar()}
   
        </select>
  
      </div>
       Total  {this.state.ListEVCar.length}
    </div>

  </div>

  }
  showSelectEVCarTable = () =>{
    
    return this.state.SelectCar.length>0? this.state.SelectCar.map((item)=>{
        return     <tr key={item.Serial_Number}>
        <td><Moment format="DD/MM/YYYY">{item.createdAt}</Moment></td>
        <td>
             
              {item.Id}         
          </td>
          <td>
          {item.Serial_Number}    
          </td>
          <td>
          {item.Description}    
          </td>
    
          <td><Moment format="DD/MM/YYYY">{item.Manufacturing_Date}</Moment></td>
         

   
     
          <td style={{ textAlign: "center", }}>
            <button   
              onClick={()=>{
                
                MySwal.fire({
                  title: 'Are you sure to delete?',
                  text: "You won't be able to revert this!",
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete it!',
                  cancelButtonText: 'No, cancel!',                                
                }).then(async(result) => {
                  if (result.value){
                    await this.onRecoveryArrListEVCar(item)
                    // this.props.deleteProduct(item.product_id)
                  }                 
                })   

              }}           
              type="button"
              className="btn btn-danger"
            >
              Delete
            </button>
          </td>
      </tr>
    }):null
  }

  RenderComponent = () =>{
    return (
      <div>
      <ul className="nav nav-pills">
        <li className="active">
          <a href="#1a" data-toggle="tab">Create BM by Rental Contract</a>
        </li>
 
      </ul>
      <div className="tab-content clearfix">
        <div className="tab-pane active" id="1a">
          {this.ForCreateBMByRentalContract()}
        </div>
        {/* <div className="tab-pane" id="2a">
  
       
        </div> */}
    
      </div>
    </div>
    )
  }


  
  
  ForCreateBMByRentalContract = () =>{
    return (
      <div className="mt">
        <Formik
      enableReinitialize
      initialValues={{
      
        BM_ID:'',
        Location:'',
        Appointment_Date:'',
        Contract_Number:'',
        ContractId:'',
        Month:'',
        Year:'',
        Qty:'',
        Service_Total:'',
        Product_Total:'',
        Total:'',
        CustomerName:'',
        CreatedBy:localStorage.getItem('token'),         
      }}
      onSubmit={(values, { setSubmitting }) => {
        this.setStateServicePrice(this.state.EVCarFromRentalDB,this.state.SelectServicePrice)
        this.setStateProductPrice(this.state.EVCarFromRentalDB,this.state.AmountProduct)
        values.Id = this.state.Id
        values.BM_ID = this.state.BM_ID
        values.Location =this.state.BM_Location
        values.ContractId = this.state.ContractId
        values.Contract_Number = this.state.Contract_Number
   

        if(!values.Appointment_Date){
            values.Appointment_Date = this.state.Appointment_Date
        }
  
        values.AmountProduct = this.state.AmountProduct
        values.ServicePrice = this.state.SelectServicePrice
        values.Month = moment(values.Appointment_Date).format("MM")
        values.Year = moment(values.Appointment_Date).format("YYYY")
        values.Service_Total = parseFloat(this.state.SelectServicePrice.reduce((accumulator, current) => accumulator + current.Service_Price,0))
        values.Product_Total = parseFloat(this.state.AmountProduct.reduce((accumulator, current) => accumulator + current.SubTotal, 0))
        values.Total = parseFloat(this.state.AmountProduct.reduce((accumulator, current) => accumulator + current.SubTotal, 0))+parseFloat(this.state.SelectServicePrice.reduce((accumulator, current) => accumulator + current.Service_Price,0))
        values.ContractorId = this.state.ContractorId
        values.CustomerName = this.state.CustomerName
       
        setTimeout(() => {
          values.EVCarFromRentalDB = this.state.EVCarFromRentalDB
          values.Qty = this.state.EVCarFromRentalDB.length
          // alert(JSON.stringify(values.ContractorId))
          if(this.ValidateEndDate(Date.now(),values.Appointment_Date)){
            // if(values.AmountProduct.length>0){
       
              this.props.updateBM(values.Id,values,this.props.history)
              // alert(JSON.stringify(values.AmountProduct))
              // alert(JSON.stringify(values.Qty))
                  // setSubmitting(false);
            // }else{
            //   alert('Please select only one the maintenance part')
            // }
          }else{
            alert('Please select appointment date at least 7 days after today')
          }
        }, 3000);
        // alert(JSON.stringify(values.AmountProduct))
        // alert(JSON.stringify(values.Qty))
  

        // setSubmitting(false);

      }} >
     {props=> this.showForm1(props)} 

    </Formik>
      {/* {this.ComponentEVCarFromDB()} */}
      </div>
    
    )
  }
  ForCreateBMByCustomer = () =>{
    return (
      <div className="mt">
        <Formik
      enableReinitialize
      initialValues={{
      
        BM_ID:'',
        Location:'',
        CustomerId:'',
        Appointment_Date:'',
        CustomerId:'',
        CreatedBy:localStorage.getItem('token'),         
      }}
      onSubmit={(values, { setSubmitting }) => {
      // let checkValid =   this.ValidateEndDate(values.Start_Date,values.End_Date)

      values.BMCarCarList = this.state.SelectCar
        alert(JSON.stringify(values))
      // this.props.addNewRentalContract(values, this.props.history)

        // setSubmitting(false);

      }} >
     {props=> this.showForm(props)} 

    </Formik>
      {this.ComponentSelectEVCar()}
      </div>
    
    )
  }
  render() {
    return (
      <div className="content-wrapper">
        {/* Main content */}
        <section className="content" style={{ maxWidth: "80%" }}>
          <div className="box box-primary" style={{ marginTop: 70 }}>
            <div className="box-header with-border">
              <p className="box-title" style={{ fontSize: 30 }}>
             Edit Preventive Maintenance
              </p>
             
            </div>
            <div className="box-body" style={{ marginTop: 30 }}>
            {this.props.editBmTaskReducer!=null &&this.state.Id!='' ? this.RenderComponent():null}
        
                <br></br>
                {/* <p>{JSON.stringify(this.state.AmountProduct)}</p>
              <p>Service Price:{JSON.stringify(this.state.SelectServicePrice)}</p> */}

              <table
                    id="stock_table"
                    className="table table-bordered table-striped table-hover mt-5"
                    style={{ height: 300, maxHeight: 300 }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "7%", textAlign: "center" }}>
                          No.
                        </th>
                        {/* <th style={{ width: "10%" }}>Id</th> */}
                        <th style={{ width: "12%" ,textAlign: "center"}}>Serial No.</th>
                        <th style={{ width: "58%" ,textAlign: "center" }}>Maintenance Description</th>
                        <th style={{ width: "9%" }}>Sub Total</th>
                        <th style={{ width: "14%", textAlign: "center" }}>
                          ACTION
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.EVCarFromRentalDB!=null ? this.ComponentEVCarFromDB():null}
                    {this.state.EVCarFromRentalDB!=null && (this.state.AmountProduct.length>0 || this.state.SelectServicePrice.length>0)? 
                    <tr>
                      <td></td> 
                      <td></td>
                       <td></td> 
                       <td>Total</td> 
                       <td>
                          : { this.CalculateTotalMaintenance()}
                        {/* :{ this.state.AmountProduct.length>0 ? `${this.state.AmountProduct.reduce((accumulator, current) => accumulator + current.SubTotal, 0)}+${this.state.SelectServicePrice.reduce((accumulator, current) => accumulator + current.Service_Price, [])}`:null} */}
                      </td>
                      <td></td>
                    </tr>
                    :null}
                    
                   {this.state.SelectCar.length>0 && this.state.SelectCar!=null ? this.showSelectEVCarTable():null}
           
                    </tbody>
                  </table>
         
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>
    );
  }
}


const mapStateToProps = ({ editBmTaskReducer }) => ({
  editBmTaskReducer
});
const mapDispatchToProps = {
  ...actions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(editBM);

