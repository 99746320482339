import React, { Component } from "react";
import * as actions from "../../actions/dashboard.action";
import { connect } from "react-redux";
import { imageUrl } from "../../constants";
import { Link } from "react-router-dom";
import _, { result } from "lodash";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import "./urlbycustomer.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { httpClient } from "../../utils/HttpClient";




import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
    GET_FOLLOW_TO_MANAGER,
    GET_FOLLOW_BY_BYCOMPANY,
    server,
} from "../../constants";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Asia/Bangkok")

const MySwal = withReactContent(Swal);

class Urlbycustomer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataResults: [],
            startDateParm: new Date(new Date().getFullYear() + "-01-01T00:00:00"),//samano added
            endDateParm: new Date(new Date().getFullYear() + "-12-31T23:59:59"),//samano added
            isLoading: false,
            customers: [],
            custid: "",
        };
    }
    componentDidMount() {
        this.fetchCustomers();
        //this.props.getAllSurveyResults("All", "All", "", "");

        // this.fetchData(this.state.startDateParm, this.state.endDateParm)

    }
    handleStartDate = (_date) => {
        this.setState({
            startDateParm: _date
        })
        // this.fetchData(_date, this.state.endDateParm)
        //this.props.getAllSurveyResults(_productparm,_companyparm,_date,_endDateParm);
    }
    handleEndDate = (_date) => {
        this.setState({
            endDateParm: _date
        })

        this.fetchData(this.state.startDateParm, this.state.endDateParm)
    }

    async fetchCustomers() {
        this.setState({
            customers: []

        })

        this.setState({
            isLoading: true
        })

        //let queryString = "?producth1=" + _productparm + "&companyid=" + _companyparm + "&startdate=" + _startDateParm + "&enddate=" + _endDateParm + "&score=" + score + "&question=" + question
        // let queryString = "?startdate=" + _startDateParm + "&enddate=" + _endDateParm + "&custid=" + _custid
        // let result1 = await httpClient.get(server.GET_FOLLOW_TO_MANAGER + queryString);
        let result1 = await httpClient.get(server.GET_ALL_CUSTOMERS);


        this.setState({
            customers: result1.data.Customers,

        })

        this.setState({
            isLoading: false
        })


        console.log(result1.data)



    }

    async fetchData(_startDateParm = "", _endDateParm = "", _custid = "") {
        this.setState({
            dataResults: []

        })

        this.setState({
            isLoading: true
        })

        //let queryString = "?producth1=" + _productparm + "&companyid=" + _companyparm + "&startdate=" + _startDateParm + "&enddate=" + _endDateParm + "&score=" + score + "&question=" + question
        let queryString = "?startdate=" + _startDateParm + "&enddate=" + _endDateParm + "&custid=" + _custid
        // let result1 = await httpClient.get(server.GET_FOLLOW_TO_MANAGER + queryString);
        let result1 = await httpClient.get(server.GET_URL_BY_CUSTOMER + queryString);
        console.log("result1")
        console.log(result1.data)
        this.setState({
            dataResults: result1.data.Urls,

        })

        this.setState({
            isLoading: false
        })


        console.log(result1.data)



    }
    handleSelectCustomers(e) {
        //alert(e.target.value)
        this.setState({custid: e.target.value})
    }

    handleSearch(e) {

        this.fetchData(this.state.startDateParm, this.state.endDateParm,this.state.custid)

    }
    handleExport(e) {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        let fileName = "summaryreport"

        // const ws = XLSX.utils.json_to_sheet(this.state.dataResults);
        var ws = XLSX.utils.table_to_sheet(document.getElementById('emp'));
        var wscols = [
            { wch: 5 },
            { wch: 12 },
            { wch: 40 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
        ];

        ws['!cols'] = wscols;



        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };



        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        // this.fetchData(this.state.startDateParm,this.state.endDateParm)

    }
    render() {
        return (
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <h1>
                        Cust sat Url by Customer
                        <small>Report</small>
                    </h1>
                    {/* <ol className="breadcrumb">
            <li>
              <a href="#/">
                <i className="fa fa-dashboard" /> Home
              </a>
            </li>
            <li>
              <a href="#/">Dashboard</a>
            </li>
          </ol> */}
                </section>

                <section className="content">

                    <div className="row">
                        <div className="col-xs-12">
                            <div className="form-group row">
                                <div className="col-md-1">
                                    <label htmlFor="inputFirstname">From :</label>
                                    <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.startDateParm} onChange={(date) => this.handleStartDate(date)} />
                                    {/* <input type="text" className="form-control" id="inputFirstname" placeholder="First name" /> */}
                                </div>
                                <div className="col-md-1">
                                    <label htmlFor="inputLastname">To:</label>
                                    <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.endDateParm} onChange={(date) => this.handleEndDate(date)} />
                                    {/* <input type="text" className="form-control" id="inputLastname" placeholder="Last name" /> */}
                                </div>








                                {/* <div className="col-md-1">
                                    <label htmlFor="inputLastname">&nbsp;</label>
                                    <div>
                                   
                                        <button onClick={(e) => this.handleExport(e)}>Download</button>
                                    </div>
                                </div> */}
                            </div>

                            {/* From: 
        TO: <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.endDateParm} onChange={(date) => this.handleEndDate(date)} /> */}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">

                            <label htmlFor="inputCustomername">Customer :</label><br />

                            <select className="form-select form-select-lg mb-3" name="customers" id="customers" onChange={(e) => this.handleSelectCustomers(e)}>
                                <option value="All">All</option>
                                {this.state.customers.length > 0 && this.state.customers.map((Item, index) => {

                                    return (
                                        <>
                                            <option key={index} value={Item._id}>{Item.Customer_Company_Name} ({Item.Customer_Code})</option>
                                        </>
                                    )

                                })}
                            </select>
                        </div>
                        <div className="col-md-1">
                            <label htmlFor="inputLastname">&nbsp;</label>
                            <div>
                                <button onClick={(e) => this.handleSearch(e)}>Search</button>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            {/* {this.state.dataResults.length > 0 && ( */}
                            <div className="table-responsive">

                                <table id="emp" className="table table-bordered table-sm">
                                    <thead>
                                        <tr className="bg-secondary">
                                            <th scope="col">No.</th>
                                            <th scope="col">Period</th>
                                            <th scope="col">Customer Code</th>
                                            <th scope="col">Customer Name</th>
                                          
                                            <th scope="col">Sent&nbsp;Date&nbsp;&nbsp;&nbsp;</th>
                                            <th scope="col">Url</th>
                                           
                                            <th scope="col">Survey Result</th>
                                            <th scope="col">Billing_Document_Accumulated</th>
                                          
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {this.state.isLoading === true && (
                                            <tr align="center"><td colSpan="6">
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <div className="loader"></div>
                                                    </div>
                                                </div>
                                            </td></tr>
                                        )}


                                        {this.state.dataResults.length > 0 && this.state.dataResults.map((Item, index) => {
                                            return (
                                                <>
                                                    <tr key={index} >
                                                        <td>#{index+1}</td>
                                                        <td>{Item.Period}</td>
                                                        <td>{Item.Customer_Code}</td>
                                                        <td>{Item.Customer_Company_Name}</td>
                                                       
                                                        <td>{Item.CreatedAt}</td>
                                                        <td><a href={Item.Url}>{Item.Url}</a></td>
                                                   
                                                       
                                                        <td>{Item.SurveyResult !== ""? "Responsed" : "Not response"}</td>
                                                        <td>{Item.Billing_Document_Accumulated}</td>
                                                     


                                                    </tr>
                                                                 
                                                        </>

                                                    
                                            )
                                        })}
                                               


                                            

                                        



                                    </tbody>

                                </table>


                            </div>
                            {/* )} */}

                        </div>
                    </div>
                </section>


            </div>
        )

    }

}

const mapStateToProps = ({ dashboardReducer }) => ({
    dashboardReducer,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Urlbycustomer);
