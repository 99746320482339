import React, { Component } from 'react';
// import * as actions from "./../../actions/stock.action";
import * as actions from "../../actions/product.action";
import { connect } from "react-redux";
import { imageUrl } from "../../constants";
import { Link } from "react-router-dom";
import _ from "lodash";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import "./product.css";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class product extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = { 
      TotalEVCar: '',
      RentEVCar:'',
      SoldEVCar:'',
      DemoEVCar:'',
      ReadyToSellEVCar:'',
      DoNotSellEVCar:''
   };

  }
  componentDidMount(){    

    this.props.getAllProduct();
   
    // this.props.getProducts()
    // this.debounceSearch =  _.debounce(this.props.getProductByKeyword, 500)
  }
  FetchingTotalEVCar=()=>{
    const {isFetching, result } = this.props.productReducer
    return (
           !isFetching &&
      result != null && <div>
       <h2> {result.TotalEVCar}</h2>
      </div>
    );
  }
  FetchingRentEVCar=()=>{
    const {isFetching, result } = this.props.productReducer
    return (
           !isFetching &&
      result != null && <div>
       <h2> {result.RentEVCar}</h2>
      </div>
    );
  }
  FetchingSoldEVCar=()=>{
    const {isFetching, result } = this.props.productReducer
    return (
           !isFetching &&
      result != null && <div>
       <h2> {result.SoldEVCar}</h2>
      </div>
    );
  }
  FetchingDemoEVCar=()=>{
    const {isFetching, result } = this.props.productReducer
    return (
           !isFetching &&
      result != null && <div>
       <h2> {result.DemoEVCar}</h2>
      </div>
    );
  }
  FetchingReadyToSellEVCar=()=>{
    const {isFetching, result } = this.props.productReducer
    return (
           !isFetching &&
      result != null && <div>
       <h2> {result.ReadyToSellEVCar}</h2>
      </div>
    );
  }
  FetchingDoNotSellEVCar=()=>{
    const {isFetching, result } = this.props.productReducer
    return (
           !isFetching &&
      result != null && <div>
       <h2> {result.DoNotSellEVCar}</h2>
      </div>
    );
  }
  FetchingByGroup = (keyword) =>{
    this.props.getEVCarByCategoryByKeyWord(keyword)
  }



  onChange = function(e){
    // e.persist()
    // this.debounceSearch(e)
  }

    createRows = ()=>{
      const {isFetching, result } = this.props.productReducer
    return (
      !isFetching &&
      result != null &&
      result.ArrData.map((item,i)=>(
        <tr key={item.Id}>
        {/* <td><Moment format="DD/MM/YYYY">{item.Appointment_Date}</Moment></td> */}
        <td>
            <span style={{ marginRight: 10, minHeight: 100 }}>
              {/* <img
                src={`${imageUrl}/images/${item.image}?dummy=${Math.random()}`}
                style={{ maxWidth: 50 }}
              /> */}
            </span>         
              {i+1}         
          </td>
          <td>
          {item.Item_Name}    
            {/* <NumberFormat
              value={item.price}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={"฿"}
            /> */}
          </td>
          <td>
            {item.Price_List }
          </td>
          <td>
          {item.Part_Number}    
      
          </td>
          <td>
            <img src={item.Image_No1} className="img-thumbnail"/>
          </td>
          <td>
          <img src={item.Image_No2} className="img-thumbnail"/>
          </td>
          <td>
          <img src={item.Image_No3} className="img-thumbnail"/>
          </td>
       
          <td style={{ textAlign: "center", }}>
            <Link 
              className="btn btn-info" 
              to={{pathname: `/product-edit/${item.Id}`,query: {...item}}}
              
              >
            Edit
            </Link>
            {/* <button
              onClick={() =>
                this.props.history.push(`/evcar-edit/${item.Id}` )
              }
              type="button"
              className="btn btn-info"
            >
              Edit
            </button> */}
            <span style={{ color: "grey" }}> | </span>
            <button   
              onClick={()=>{
                
                MySwal.fire({
                  title: 'Are you sure to delete?',
                  text: "You won't be able to revert this!",
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete it!',
                  cancelButtonText: 'No, cancel!',                                
                }).then((result) => {
                  if (result.value){
                    this.props.deleteProduct(item.Id,this.props.history)
                    window.location.reload(); 
                    // this.props.deleteEVCarById(item.Id,this.props.history)
                    // this.props.deleteProduct(item.product_id)
                  }                 
                })   

              }}           
              type="button"
              className="btn btn-danger"
            >
              Delete
            </button>
          </td>
      </tr>
      ))


     
    )
  }     
  






  render() {


    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <h1>
            Stock
            <small>Report</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <a href="#/">
                <i className="fa fa-dashboard" /> Home
              </a>
            </li>
            <li>
              <a href="#/">Stock</a>
            </li>
          </ol>
        </section>
        {/* Main content */}
        <section className="content">
          <div className="row">
            <div className="col-xs-12">
              {/* <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_product.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
               
                      <a onClick={()=>{this.FetchingByGroup('Total')}}>
                      <p className="p_custom">Total</p>
                     {this.FetchingTotalEVCar()}
                     
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_new.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
         
                    <a onClick={()=>{this.FetchingByGroup('Rent')}} >
                    <p className="p_custom">Rent</p>
                      {this.FetchingRentEVCar()}
                    </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/images/ic_out_of_stock.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                
                                 <a onClick={()=>{this.FetchingByGroup('Sold')}}>
                                 <p className="p_custom">Sold</p>
                      {this.FetchingSoldEVCar()}
                                 </a>
                    
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_product.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                     <a onClick={()=>{this.FetchingByGroup('Demo')}}>
                     <p className="p_custom">DEMO</p>
                      {this.FetchingDemoEVCar()}
                     </a>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_new.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                      <a onClick={()=>{this.FetchingByGroup('Ready_to_sell')}}>
                      <p className="p_custom">Ready To Sell</p>
                      {this.FetchingReadyToSellEVCar()}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/images/ic_out_of_stock.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                     <a onClick={()=>{this.FetchingByGroup('Dont_sell')}}>
                     <p className="p_custom">Maintenance</p>
                      {this.FetchingDoNotSellEVCar()}
                     </a>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="box">
                <div className="box-body">
                  <div className="row" style={{ marginBottom: 40 }}>
                    <div className="col-xs-6">
                      <input
                        onChange={this.onChange.bind(this)}
                        type="search"
                        className="form-control input-lg"
                        placeholder="Enter search keyword"
                        style={{ borderRadius: 10 }}
                      />
                    </div>
                    <div className="col-xs-3 text-right">
                      {/* <Link
                        to="/pm-create"
                        style={{ float: "right", margin: 0, width: 200 }}
                        className="btn btn-success btn-lg"
                      >
                        Create PM
                      </Link> */}
                    </div>
                    <div className="col-xs-3 text-right">
                      <Link
                        to="/product-create"
                        style={{ float: "right", margin: 0, width: 200 }}
                        className="btn btn-warning btn-lg"
                      >
                        Create Product
                      </Link>
                    </div>
                    <div className="col-xs-3 text-right">
                      {/* <Link
                        to="/pm-create"
                        style={{ float: "right", margin: 0, width: 200 }}
                        className="btn btn-warning btn-lg"
                      >
                        Create BM
                      </Link> */}
                    </div>
                  </div>

                  <table
                    id="stock_table"
                    className="table table-bordered table-striped table-hover"
                    style={{ height: 300, maxHeight: 300 }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "7%", textAlign: "center" }}>
                          Id
                        </th>
                        <th style={{ width: "15%" }}>Product Name</th>
                        <th style={{ width: "15%" }}>Price List(THB/Unit)</th>
                        <th style={{ width: "9%" }}>Part Number</th>
                        <th style={{ width: "12%" }}>Product Image 1</th>
                        <th style={{ width: "12%" }}>Product Image 2</th>
                        <th style={{ width: "12%" }}>Product Image 3</th>
                        <th style={{ width: "14%", textAlign: "center" }}>
                          ACTION
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.createRows()}</tbody>
                  </table>
                </div>
                {/* /.box-body */}
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </section>
      </div>
    );
  }
}


const mapStateToProps = ({productReducer}) => ({
  productReducer
})

const mapDispatchToProps = {
  ...actions
}

export default connect(mapStateToProps, mapDispatchToProps)(product);
