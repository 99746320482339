import React, { Component } from 'react';
import * as actions from "../../actions/createNewCustomer.action";
import { connect } from "react-redux";
import { Formik ,FieldArray,Field} from "formik";
import { imageUrl } from "../../constants";
import { Link } from "react-router-dom";
import _, { join, values } from "lodash";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import "./CreateCustomer.css";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from 'react-loader-spinner'

const MySwal = withReactContent(Swal)
var jwt = require("jsonwebtoken");
const moment = require('moment')

const orderid = require('order-id')('mysecret');
const id = orderid.generate();

class createCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount(){
    // let id = this.props.match.params.id
    // this.getAllDataForSelect()
    // this.props.getAllDataForSelect().then(result=>{
  
    //   // this.onHandleArrListEVCar()
    // })
   
  }




  showForm =({values,handleChange,handleSubmit, setFieldValue})=>{
    // this.ValidateEndDate(values.Start_Date,values.End_Date)

    return (
      <form
      className="form-horizontal"
      onSubmit={handleSubmit}>
      <div className="form-group">
 
        <label className="col-sm-2 control-label" htmlFor="Customer_Code">
          Customer Code
        </label>
        <div className="col-sm-10">
          <div  className="input-group">
          <input
            name="Customer_Code"
            onChange={handleChange}
            value={values.Customer_Code}
            placeholder="0820"
            className="form-control"
            type="text"
            id="Customer_Code"
          />
          </div>
        </div>
      </div>


            
    <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Customer_Name">
          Customer Name
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="Customer_Name"
              onChange={handleChange}
              value={values.Customer_Name}
              className="form-control"
              placeholder="SCG Cement"
              type="text"
              id="Customer_Name"
              required
            /> 
           
      
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Customer_Email">
        Customer Email
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="Customer_Email"
              onChange={handleChange}
              value={values.Customer_Email}
              className="form-control"
              type="email"
              id="Customer_Email"
              required
            /> 
           
      
          </div>
        </div>
      </div>


      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Customer_Address">
        Customer Address
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="Customer_Address"
              onChange={handleChange}
              value={values.Customer_Address}
              className="form-control"
              type="text"
            />
      
          </div>
        </div>
      </div>
      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Customer_Telephone">
        Customer Telephone
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="Customer_Telephone"
              onChange={handleChange}
              value={values.Customer_Telephone}
              className="form-control"
              type="text"
            />
          
          </div>
        </div>
      </div>
  
   
       
      
    



      <div className="box-footer" style={{ marginTop: 50 }}>
        <button
          type="submit"
          className="btn btn-primary pull-right"
        >
          Save
        </button>
        <a
          onClick={() => {
            // this.props.clearProduct()
            this.props.history.goBack();
          }}
          type="Button"
          className="btn btn-default pull-right"
          style={{ marginRight: 10 }}
        >
          Cancel
        </a>
      </div>
    </form>
  
    )
    
  }
 
  render() {

 

    return (
      <div className="content-wrapper">
        {/* Main content */}
        <section className="content" style={{ maxWidth: "80%" }}>
          <div className="box box-primary" style={{ marginTop: 70 }}>
            <div className="box-header with-border">
              <p className="box-title" style={{ fontSize: 30 }}>
              Create New Customer
              </p>
           <p>
          
           </p>
            </div>
            <div className="box-body" style={{ marginTop: 30 }}>
              <Formik
                enableReinitialize
                initialValues={{
                
                  Customer_Code:'',
                  Customer_Name:'',
                  Customer_Email:'',
                  Customer_Address:'',
                  Customer_Telephone:'',
                  CreatedBy:localStorage.getItem('token'),         
                }}
                onSubmit={(values, { setSubmitting }) => {
                  // alert(JSON.stringify(values))
                  this.props.createNewCustomerFunction(values,this.props.history)
                // this.props.addNewRentalContract(values, this.props.history)
               
                  // setSubmitting(false);
             
                  setTimeout(() => {
                    let { result } = this.props.createNewCustomerReducer

                    if(result.result==='nok'){
                      if(result.message!=null){
                        alert(result.message)
                      }else{
                  
                           setSubmitting(false);
                            this.props.history.push('/rental')
                      }
                    }
                    if(result.result==='ok'){
                      setSubmitting(false);
                      this.props.history.push('/rental')
                    }
                 
            
                  }, 3000);
                }} >
               {props=> this.showForm(props)} 
          
              
              </Formik>
       
                <br></br>
          
         
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>
    );
  }
}


const mapStateToProps = ({ createNewCustomerReducer }) => ({
  createNewCustomerReducer
});
const mapDispatchToProps = {
  ...actions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createCustomer);

