import { httpClient } from "../utils/HttpClient";
import {
    HTTP_CREATE_NEW_SITE_SUCCESS,
    HTTP_CREATE_NEW_SITE_FETCHING,
    HTTP_CREATE_NEW_SITE_FAILED,
  server,
//   HTTP_STOCK_CLEAR
} from "../constants";

export const setStateAddNewSiteToSuccess = payload => ({
  type: HTTP_CREATE_NEW_SITE_SUCCESS,
  payload
});

const setStateAddNewSiteToFetching = () => ({
  type: HTTP_CREATE_NEW_SITE_FETCHING
});

const setStateAddNewSiteToFailed = payload => ({
  type: HTTP_CREATE_NEW_SITE_FAILED,
  payload
});



  export const createNewSiteFunction= (value, history) => {
    return async dispatch => {
      try {
        dispatch(setStateAddNewSiteToFetching()); // fetching
        let result = await httpClient.post(server.CREATE_NEW_SITE, value);
        if (result.data.result == 'ok') {
          dispatch(setStateAddNewSiteToSuccess(result.data));
          history.push('/rental')
        } else {        
          // alert(JSON.stringify(result.data.message))
          dispatch(setStateAddNewSiteToFailed(result.data));
        //   history.push('/rental')
        }
      } catch (error) {        
          dispatch(setStateAddNewSiteToFailed({data: {message: error}}));
        //   history.push('/rental')
      }
    };
  };
  
  