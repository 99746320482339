import { HTTP_CREATE_NEW_SITE_FAILED, HTTP_CREATE_NEW_SITE_FETCHING, HTTP_CREATE_NEW_SITE_SUCCESS} from "../constants";

const initialState = {
    result: null,
    isFetching: false,
    isError: false
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case HTTP_CREATE_NEW_SITE_FETCHING:
        return { ...state, result:null, isFetching:true, isError: false }
    case HTTP_CREATE_NEW_SITE_SUCCESS:
        return { ...state, result:payload, isFetching:false, isError: false }
    case HTTP_CREATE_NEW_SITE_FAILED:
        return { ...state, result:payload, isFetching:false, isError: true }
    default:
        return state
    }
}
