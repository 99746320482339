// GET SURVEY RESULT FOR DASHBOARD
export const HTTP_GET_ALL_SURVEY_RESULT_FETCHING = "HTTP_GET_ALL_SURVEY_RESULT_FETCHING";
export const HTTP_GET_ALL_SURVEY_RESULT_SUCCESS= "HTTP_GET_ALL_SURVEY_RESULT_SUCCESS";
export const HTTP_GET_ALL_SURVEY_RESULT_FAILED = "HTTP_GET_ALL_SURVEY_RESULT_FAILED";





// GET ALL PRODUCT
export const HTTP_GET_ALL_PRODUCT_FETCHING = "HTTP_GET_ALL_PRODUCT_FETCHING";
export const HTTP_GET_ALL_PRODUCT_SUCCESS= "HTTP_GET_ALL_PRODUCT_SUCCESS";
export const HTTP_GET_ALL_PRODUCT_FAILED = "HTTP_GET_ALL_PRODUCT_FAILED";
// CREATE PRODUCT
export const HTTP_CREATE_PRODUCT_FETCHING = "HTTP_CREATE_PRODUCT_FETCHING";
export const HTTP_CREATE_PRODUCT_SUCCESS = "HTTP_CREATE_PRODUCT_SUCCESS";
export const HTTP_CREATE_PRODUCT_FAILED = "HTTP_CREATE_PRODUCT_FAILED";
// GET ALL PM TASK

// GET BM TASK BY ID
export const HTTP_GET_BM_TASK_BY_ID_FETCHING = "HTTP_GET_BM_TASK_BY_ID_FETCHING";
export const HTTP_GET_BM_TASK_BY_ID_SUCCESS = "HTTP_GET_BM_TASK_BY_ID_SUCCESS";
export const HTTP_GET_BM_TASK_BY_ID_FAILED  = "HTTP_GET_BM_TASK_BY_ID_FAILED";
// GET PM TASK BY ID
export const HTTP_GET_PM_TASK_BY_ID_FETCHING = "HTTP_GET_PM_TASK_BY_ID_FETCHING";
export const HTTP_GET_PM_TASK_BY_ID_SUCCESS = "HTTP_GET_PM_TASK_BY_ID_SUCCESS";
export const HTTP_GET_PM_TASK_BY_ID_FAILED  = "HTTP_GET_PM_TASK_BY_ID_FAILED";
// GET ALL PM TASK
export const HTTP_GET_ALL_PM_TASK_FETCHING = "HTTP_GET_ALL_PM_TASK_FETCHING";
export const HTTP_GET_ALL_PM_TASK_SUCCESS = "HTTP_GET_ALL_PM_TASK_SUCCESS";
export const HTTP_GET_ALL_PM_TASK_FAILED  = "HTTP_GET_ALL_PM_TASK_FAILED";
// GET RENTAL CONTRACT FOR CREATE BM
export const HTTP_GET_ALL_RENTAL_CONTRACT_BM_FETCHING = "HTTP_GET_ALL_RENTAL_CONTRACT_BM_FETCHING";
export const HTTP_GET_ALL_RENTAL_CONTRACT_BM_SUCCESS = "HTTP_GET_ALL_RENTAL_CONTRACT_BM_SUCCESS";
export const HTTP_GET_ALL_RENTAL_CONTRACT_BM_FAILED = "HTTP_GET_ALL_RENTAL_CONTRACT_BM_FAILED";

// GET RENTAL CONTRACT FOR CREATE PM
export const HTTP_GET_ALL_RENTAL_CONTRACT_FETCHING = "HTTP_GET_ALL_RENTAL_CONTRACT_FETCHING";
export const HTTP_GET_ALL_RENTAL_CONTRACT_SUCCESS = "HTTP_GET_ALL_RENTAL_CONTRACT_SUCCESS";
export const HTTP_GET_ALL_RENTAL_CONTRACT_FAILED = "HTTP_GET_ALL_RENTAL_CONTRACT_FAILED";

// GET EV MODEL BY ID
export const HTTP_GET_EV_MODEL_FETCHING = "HTTP_GET_EV_MODEL_FETCHING";
export const HTTP_GET_EV_MODEL_SUCCESS = "HTTP_GET_EV_MODEL_SUCCESS";
export const HTTP_GET_EV_MODEL_FAILED = "HTTP_GET_EV_MODEL_FAILED";


// GET CUSTOMER BY ID
export const HTTP_GET_SITE_BY_ID_FETCHING = "HTTP_GET_SITE_BY_ID_FETCHING";
export const HTTP_GET_SITE_BY_ID_SUCCESS = "HTTP_GET_SITE_BY_ID_SUCCESS";
export const HTTP_GET_SITE_BY_ID_FAILED = "HTTP_GET_SITE_BY_ID_FAILED";
// GET ALL Site
export const HTTP_GET_ALL_SITE_FETCHING = "HTTP_GET_ALL_SITE_FETCHING";
export const HTTP_GET_ALL_SITE_SUCCESS = "HTTP_GET_ALL_SITE_SUCCESS";
export const HTTP_GET_ALL_SITE_FAILED = "HTTP_GET_ALL_SITE_FAILED";
// Create New Site
export const HTTP_CREATE_NEW_SITE_FETCHING = "HTTP_CREATE_NEW_SITE_FETCHING";
export const HTTP_CREATE_NEW_SITE_SUCCESS = "HTTP_CREATE_NEW_SITE_SUCCESS";
export const HTTP_CREATE_NEW_SITE_FAILED = "HTTP_CREATE_NEW_SITE_FAILED";

// GET CUSTOMER BY ID
export const HTTP_GET_CUSTOMER_BY_ID_FETCHING = "HTTP_GET_CUSTOMER_BY_ID_FETCHING";
export const HTTP_GET_CUSTOMER_BY_ID_SUCCESS = "HTTP_GET_CUSTOMER_BY_ID_SUCCESS";
export const HTTP_GET_CUSTOMER_BY_ID_FAILED = "HTTP_GET_CUSTOMER_BY_ID_FAILED";
// Create New Customer
export const HTTP_CREATE_NEW_CUSTOMER_FETCHING = "HTTP_CREATE_NEW_CUSTOMER_FETCHING";
export const HTTP_CREATE_NEW_CUSTOMER_SUCCESS = "HTTP_CREATE_NEW_CUSTOMER_SUCCESS";
export const HTTP_CREATE_NEW_CUSTOMER_FAILED = "HTTP_CREATE_NEW_CUSTOMER_FAILED";
// Fetch Rental Create
export const HTTP_RENTAL_GET_DATA_FETCHING = "HTTP_RENTAL_GET_DATA_FETCHING";
export const HTTP_RENTAL_GET_DATA_SUCCESS = "HTTP_RENTAL_GET_DATA_SUCCESS";
export const HTTP_RENTAL_GET_DATA_FAILED = "HTTP_RENTAL_GET_DATA_FAILED";
// Fetch Rental
export const HTTP_RENTAL_FETCHING = "HTTP_RENTAL_FETCHING";
export const HTTP_RENTAL_SUCCESS = "HTTP_RENTAL_SUCCESS";
export const HTTP_RENTAL_FAILED = "HTTP_RENTAL_FAILED";

// Fetch Rental BY ID
export const HTTP_RENTAL_FETCHING_BY_ID = "HTTP_RENTAL_FETCHING_BY_ID";
export const HTTP_RENTAL_SUCCESS_BY_ID = "HTTP_RENTAL_SUCCESS_BY_ID";
export const HTTP_RENTAL_FAILED_BY_ID = "HTTP_RENTAL_FAILED_BY_ID";

export const LOGIN_FETCHING = 'LOGIN_FETCHING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';

// Fetch Data
export const HTTP_EV_CAR_BY_CATEGORY_FETCHING = "HTTP_EV_CAR_BY_CATEGORY_FETCHING";
export const HTTP_EV_CAR_BY_CATEGORY_SUCCESS = "HTTP_EV_CAR_BY_CATEGORY_SUCCESS";
export const HTTP_EV_CAR_BY_CATEGORY_FAILED = "HTTP_EV_CAR_BY_CATEGORY_FAILED";
// Fetch Data
export const HTTP_CUSTOMER_FETCHING = "HTTP_CUSTOMER_FETCHING";
export const HTTP_CUSTOMER_SUCCESS = "HTTP_CUSTOMER_SUCCESS";
export const HTTP_CUSTOMER_FAILED = "HTTP_CUSTOMER_FAILED";

export const HTTP_CUSTOMER_DETAIL_FETCHING = "HTTP_CUSTOMER_DETAIL_FETCHING";
export const HTTP_CUSTOMER_DETAIL_SUCCESS = "HTTP_CUSTOMER_DETAIL_SUCCESS";
export const HTTP_CUSTOMER_DETAIL_FAILED = "HTTP_CUSTOMER_DETAIL_FAILED";


// Register Page
export const HTTP_REGISTER_FETCHING = "HTTP_REGISTER_FETCHING";
export const HTTP_REGISTER_SUCCESS = "HTTP_REGISTER_SUCCESS";
export const HTTP_REGISTER_FAILED = "HTTP_REGISTER_FAILED";

// Stock Page
export const HTTP_STOCK_FETCHING = "HTTP_STOCK_FETCHING";
export const HTTP_STOCK_SUCCESS = "HTTP_STOCK_SUCCESS";
export const HTTP_STOCK_FAILED = "HTTP_STOCK_FAILED";
export const HTTP_STOCK_CLEAR = "HTTP_STOCK_CLEAR";

// Stock Edit Page
export const HTTP_STOCK_EDIT_FETCHING = "HTTP_STOCK_EDIT_FETCHING";
export const HTTP_STOCK_EDIT_SUCCESS = "HTTP_STOCK_EDIT_SUCCESS";
export const HTTP_STOCK_EDIT_FAILED = "HTTP_STOCK_EDIT_FAILED";
export const HTTP_STOCK_EDIT_INITIALED = "HTTP_STOCK_EDIT_INITIALED";

// Transaction Edit Page
export const HTTP_TRANSACTION_FETCHING = "HTTP_TRANSACTION_FETCHING";
export const HTTP_TRANSACTION_SUCCESS = "HTTP_TRANSACTION_SUCCESS";
export const HTTP_TRANSACTION_FAILED = "HTTP_TRANSACTION_FAILED";

// Shop Page
export const HTTP_SHOP_FETCHING = "HTTP_SHOP_FETCHING";
export const HTTP_SHOP_SUCCESS = "HTTP_SHOP_SUCCESS";
export const HTTP_SHOP_FAILED = "HTTP_SHOP_FAILED";

export const SHOP_UPDATE_ORDER = "SHOP_UPDATE_ORDER";
export const SHOP_UPDATE_PAYMENT = "SHOP_UPDATE_PAYMENT";

// Error Code
export const E_PICKER_CANCELLED = 'E_PICKER_CANCELLED'
export const E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR = 'E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR'
export const E_PERMISSION_MISSING = 'E_PERMISSION_MISSING'
export const E_PICKER_NO_CAMERA_PERMISSION = 'E_PICKER_NO_CAMERA_PERMISSION'
export const E_USER_CANCELLED = 'E_USER_CANCELLED'
export const E_UNKNOWN = 'E_UNKNOWN'
export const E_DEVELOPER_ERROR = 'E_DEVELOPER_ERROR'
export const TIMEOUT_NETWORK = 'ECONNABORTED' // request service timeout
export const NOT_CONNECT_NETWORK = 'NOT_CONNECT_NETWORK' 

//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE = 'Cannot connect to server, Please try again.' 
export const NETWORK_TIMEOUT_MESSAGE = 'A network timeout has occurred, Please try again.'  
export const UPLOAD_PHOTO_FAIL_MESSAGE = 'An error has occurred. The photo was unable to upload.' 


export const apiUrl =  "http://localhost:3002/api/v1";
export const imageUrl = "http://localhost:8081";


export const server = {    
    LOGIN_URL : `login`,
    SSO_URL   : `sso`,    
    REGISTER_URL : `register`,  
    PRODUCT_URL : `product`,    
    TRANSACTION_URL : `transaction`,    
    REPORT_URL: `report`,    
    TOKEN_KEY : `token`,
    GET_EV_CAR_BY_CATEGORY : `getevcarbycategory`,
    GET_CUSTOMER: `getallcustomer`,
    GET_CUSTOMER_DETAIL: `getevcarbycustomer/`,
    GET_RENTAL: `getrentalcontract`,

    GET_EV_CAR_FOR_RENT: `getallevcarforrent`,
    GET_CUSTOMER_FOR_RENT: `getallcustomerforrent`,
    GET_LOCATION_FOR_RENT: `getalllocationforrent`,


    ADD_NEW_RENTAL_CONTRACT:`createrentalcontract`,
    GET_RENTAL_CONTRACT_BY_ID:`getrentalcontractbyid/`,
    UPDATE_RENTAL_CONTRACT_BY_ID:`updaterentalcontract/`,
    CREATE_NEW_CUSTOMER:`createnewcustomer`,
    GET_CUSTOMER_BY_ID:`getcustomerbyid/`,
    UPDATE_CUSTOMER_BY_ID:`updatecustomerbyid/`,
    CREATE_NEW_SITE:`createnewsite`,
    GET_ALL_SITE:`getallsite`,
    GET_SITE_BY_ID:`getsitebyid/`,
    UPDATE_SITE_BY_ID:`updatesitebyid/`,
    GET_EV_MODEL:`getevmodel`,
    CREATE_EV_CAR:`createevcar`,
    UPDATE_EV_CAR:`updateevcar/`,
    DELETE_EV_CAR:`deleteevcar/`,

    GET_RENTAL_AND_EV_LIST_FOR_PM:`getrentalcontractforpm`,
    GET_RENTAL_AND_EV_LIST_FOR_BM:`getrentalcontractforbm`,
    GET_PRODUCT_FOR_PM:`getproductforpm`,
    GET_ALL_CONTRACTOR:`getallcontractor`,

    CREATE_NEW_PM_ORDER:`createpm`,
    GET_ALL_PM_TASK:`getallpmtask`,
    GET_PM_TASK_BY_ID:`getpmtaskbyid/`,
    UPDATE_PM_TASK_BY_ID:`updatepmbyid/`,
    DELETE_PM_TASK_BY_ID:`deletepmbyid/`,

    CREATE_NEW_BM_ORDER:`createbm`,
    GET_ALL_BM_TASK:`getallbmtask`,
    GET_BM_TASK_BY_ID:`getbmtaskbyid/`,
    UPDATE_BM_TASK_BY_ID:`updatebmbyid/`,
    DELETE_BM_TASK_BY_ID:`deletebmbyid/`,

    CREATE_PRODUCT:`createproduct`,
    GET_ALL_PRODUCT:`getallproduct`,
    UPDATE_PRODUCT_BY_ID:`updateproductbyid/`,
    DELETE_PRODUCT_BY_ID:`deleteproductbyid/`,


    GET_ALL_SURVEY_RESULT:'getallsurveyresult',
    GET_SURVEY_RESULT_BY_LEVEL:'getsurveyresultbylevel',
    GET_ALL_NPS:'getnpsscore',
    GET_PRODUCTS_AND_SERVICES:'getproductsandservices',
    GET_SALESPERSON:'getsalesperson',
    GET_DELIVERY:'getdelivery',
    GET_CLAIM_AND_SUPPORT:'getclaimandsupport',
    GET_ALL_COMMENT:'getallcomment',
    GET_ALL_PRODUCTS:'getallproducts', //samano added
    GET_ALL_COUNTRIES:'getallcountries', //samano added
    GET_ALL_COMPANYS:'getallcompanys', //samano added
    GET_DETAILS_SCORE_BY_CLICK: 'getdetailscorebyclick', //samano added
    GET_FOLLOW_TO_MANAGER: 'getfollowtomanager', //samano added
    GET_FOLLOW_BY_BYCOMPANY: 'getfollowbycompany', //samano added
    GET_URL_BY_CUSTOMER: 'geturlbycustomer',// samano added
    GET_ALL_CUSTOMERS:'getallcustomers', //samano added
}
