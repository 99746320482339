import { httpClient } from "../utils/HttpClient";
import {
    HTTP_GET_BM_TASK_BY_ID_FETCHING, HTTP_GET_BM_TASK_BY_ID_SUCCESS, HTTP_GET_BM_TASK_BY_ID_FAILED,
  server,
//   HTTP_STOCK_CLEAR
} from "../constants";
import { result } from "lodash";

export const setStateGetBMTaskByIdtToSuccess = payload => ({
  type: HTTP_GET_BM_TASK_BY_ID_SUCCESS,
  payload
});

const setStateGetBMTaskByIdToFetching = () => ({
  type: HTTP_GET_BM_TASK_BY_ID_FETCHING
});

const setStateGetBMTaskByIdToFailed = () => ({
  type: HTTP_GET_BM_TASK_BY_ID_FAILED
});







export const getAllDataForSelect = (Id) => {
    return async dispatch => {
      try {
          dispatch(setStateGetBMTaskByIdToFetching()); 
       
          let result1 = await httpClient.get(server.GET_EV_CAR_FOR_RENT);
          let result2 = await httpClient.get(server.GET_CUSTOMER_FOR_RENT);
          let result3 = await httpClient.get(server.GET_LOCATION_FOR_RENT);
          let result4 = await httpClient.get(server.GET_RENTAL_AND_EV_LIST_FOR_BM)
          let result5 = await httpClient.get(server.GET_PRODUCT_FOR_PM)
          let result6 = await httpClient.get(server.GET_ALL_CONTRACTOR)
          let result7 = await httpClient.get(`${server.GET_BM_TASK_BY_ID}${Id}`)
        // alert(JSON.stringify(result1.data.ArrData))
          if (result1.data.result === 'ok' && result2.data.result === 'ok'&& result3.data.result === 'ok'&& result4.data.result==='ok'&& result5.data.result==='ok'&& result6.data.result==='ok'&&result7.data.result==='ok') {
              let Consolidate = {
                  EVCar:result1.data.ArrData,
                  Customer:result2.data.ArrData,
                  Location:result3.data.ArrData,
                  Rental:result4.data.ArrData,
                  Product:result5.data.ArrData,
                  Contractor:result6.data.ArrData,
                  BMTask:result7.data.ArrBM,
                  SubBMTask:result7.data.ArrSubBM,
                  ArrProduct:result7.data.ArrProduct,
                  ArrService:result7.data.ArrService

              }
            //   alert(JSON.stringify(Consolidate.EVCar))
            dispatch(setStateGetBMTaskByIdtToSuccess(Consolidate));
    
          } else {        
            dispatch(setStateGetBMTaskByIdToFailed(result2.data));
          }
        } catch (error) {        
            dispatch(setStateGetBMTaskByIdToFailed({data: {message: error}}));
        }
    };
  };

  export const updateBM = (Id,value,history) => {
    return async dispatch => {
      try {
        dispatch(setStateGetBMTaskByIdToFetching()); // fetching
        let result = await httpClient.put(`${server.UPDATE_BM_TASK_BY_ID}${Id}`, value);
        // alert(result.data.result)
        if (result.data.result == 'ok') {
          // dispatch(setStateGetDATARentalToSuccess(result));
          history.push('/maintenance')
        } else {        
            alert(result.data.message)
          dispatch(setStateGetBMTaskByIdToFailed(result));
          history.push('/maintenance')
        }
      } catch (error) {        
          dispatch(setStateGetBMTaskByIdToFailed({data: {message: error}}));
          history.push('/maintenance')
      }
    };
  };
  
  