import { httpClient } from "../utils/HttpClient";
import {
HTTP_GET_CUSTOMER_BY_ID_FAILED,
HTTP_GET_CUSTOMER_BY_ID_SUCCESS,
HTTP_GET_CUSTOMER_BY_ID_FETCHING,
  server,
//   HTTP_STOCK_CLEAR
} from "../constants";

export const setStateGetCustomerByIdToSuccess = payload => ({
  type: HTTP_GET_CUSTOMER_BY_ID_SUCCESS,
  payload
});

const setStateGetCustomerByIdToFetching = () => ({
  type: HTTP_GET_CUSTOMER_BY_ID_FETCHING
});

const setStateGetCustomerByIdToFailed = payload => ({
  type: HTTP_GET_CUSTOMER_BY_ID_FAILED,
  payload
});



  export const getCustomerByIdFunction= (Id) => {
    return async dispatch => {
      try {
        dispatch(setStateGetCustomerByIdToFetching()); // fetching
        let result = await httpClient.get(`${server.GET_CUSTOMER_BY_ID}${Id}`);
        if (result.data.result == 'ok') {
          dispatch(setStateGetCustomerByIdToSuccess(result.data));
         
        } else {        
    
          dispatch(setStateGetCustomerByIdToFailed(result.data));

        }
      } catch (error) {        
          dispatch(setStateGetCustomerByIdToFailed({data: {message: error}}));
      }
    };
  };
  export const updateCustomerByIdFunction= (value,Id,history) => {
    return async dispatch => {
      try {
        dispatch(setStateGetCustomerByIdToFetching()); // fetching
        let result = await httpClient.put(`${server.UPDATE_CUSTOMER_BY_ID}${Id}`,value);
        if (result.data.result == 'ok') {
          dispatch(setStateGetCustomerByIdToSuccess(result.data));
          history.push('/customer')
         
        } else {        
    
          dispatch(setStateGetCustomerByIdToFailed(result.data));
          history.push('/customer')

        }
      } catch (error) {        
          dispatch(setStateGetCustomerByIdToFailed({data: {message: error}}));
      }
    };
  };
  
  
  