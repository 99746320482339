// import React, { Component } from "react";
// import { Pie, Doughnut, HorizontalBar } from "react-chartjs-2";
// import GaugeChart from "react-gauge-chart";
// import "./NPS.css";


// export default class NPS extends Component {
//   constructor(props) {
//     super(props);
//     // Don't call this.setState() here!
//     this.state = {
//       NPS: 0,
//       Promoters: 0,
//       Passives: 0,
//       Detractors: 0,
//       P_Promoters: 0,
//       P_Passives: 0,
//       P_Detractors: 0,
//       isLoading: 1,
//     };
//   }
//   componentDidMount() {
//     // alert(JSON.stringify(this.props.value.result.AllSurvey))
//     if (this.props.value.result) {
//       let result = this.props.value.result.AllNPS;
//       this.setState({ NPS: result.NPS });
//       this.setState({ Promoters: result.Promoters });
//       this.setState({ Passives: result.Passives });
//       this.setState({ Detractors: result.Detractors });
//       this.setState({ P_Promoters: result.P_Promoters });
//       this.setState({ P_Passives: result.P_Passives });
//       this.setState({ P_Detractors: result.P_Detractors });
//       // alert(JSON.stringify(result))
//       this.setState({ isLoading: 0 });
//     }
//   }
//   NPSData = () => {
//     return {
//       labels: ["Promoters", "Passives", "Detractors"],
//       datasets: [
//         {
//           label: "NPS data",
//           backgroundColor: ["#5BE12C", "#808080","#EA4228"],
//           borderColor: "rgba(255,99,132,1)",
//           borderWidth: 1,
//           hoverBackgroundColor: "rgba(255,99,132,0.4)",
//           hoverBorderColor: "rgba(255,99,132,1)",
//           data: [
//             this.state.Promoters,
//             this.state.Passives,
//             this.state.Detractors,
//           ],
//         },
//       ],
//     };
//   };
//   render() {
//     return (
//       <div>
//         <div className="">
//           {this.state.isLoading === 0 ? (
//             <GaugeChart
//               id="gauge-chart5"
//               nrOfLevels={500}
//               arcsLength={[
//                 this.state.P_Detractors,
//                 this.state.P_Passives,
//                 this.state.P_Promoters,
//               ]}
//               cornerRadius={5}
//               animDelay={500}
//               textColor={"#464A4F"}
//               colors={["#EA4228", "#808080", "#5BE12C"]}
//               percent={this.state.NPS}
//               arcPadding={0.03}
//             />
//           ) : null}
//         </div>
//         <div className="">
//         </div>
//       </div>
//     );
//   }
// }

import React, { Component } from "react";
import {Doughnut} from 'react-chartjs-2';


export default class NPS extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      NPS: 0,
      Promoters: 0,
      Passives: 0,
      Detractors: 0,
      P_Promoters: 0,
      P_Passives: 0,
      P_Detractors: 0,
      isLoading: 1,
    };
  }
  componentDidMount() {
    // alert(JSON.stringify(this.props.value.result.AllSurvey))
    if (this.props.value.result) {
      let result = this.props.value.result.AllNPS;
      this.setState({ NPS: result.NPS });
      this.setState({ Promoters: result.Promoters });
      this.setState({ Passives: result.Passives });
      this.setState({ Detractors: result.Detractors });
      this.setState({ P_Promoters: result.P_Promoters });
      this.setState({ P_Passives: result.P_Passives });
      this.setState({ P_Detractors: result.P_Detractors });
      // alert(JSON.stringify(result))
      this.setState({ isLoading: 0 });
    }
  }
  NPSData = () => {
    return {
      labels: ["Promoters", "Passives", "Detractors"],
      datasets: [
        {
          label: "NPS data",
          backgroundColor: ["#5BE12C", "#808080","#EA4228"],
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          // hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: [
            this.state.Promoters,
            this.state.Passives,
            this.state.Detractors,
          ],
        },
      ],
  
    };
  };
  render() {
    return (
      <div>
        <div className="">
          {this.state.isLoading === 0 ? (
                   <Doughnut 
                   data={this.NPSData()} 
                   options={{
                    legend: { display: true, position: "right" },
                    dataLabels: {
                      display: true,
                      color: "white",
                    },
                   }}
                   />
          ) : null}
        </div>
        <div className="">
        </div>
      </div>
    );
  }
}