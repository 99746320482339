import React, { Component } from 'react';
import * as actions from "../../actions/createNewProduct.action";
import { connect } from "react-redux";
import { Formik ,FieldArray,Field} from "formik";
import { imageUrl } from "../../constants";
import { Link } from "react-router-dom";
import _, { join, values } from "lodash";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import "./createProduct.css";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from 'react-loader-spinner'

const MySwal = withReactContent(Swal)
var jwt = require("jsonwebtoken");
const moment = require('moment')

const orderid = require('order-id')('mysecret');
const id = orderid.generate();

class createProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUpload:[],
      imagePreview:[]
    };
  }
  componentDidMount(){
    // let id = this.props.match.params.id
    // this.getAllDataForSelect()
    // this.props.getAllDataForSelect().then(result=>{
  
    //   // this.onHandleArrListEVCar()
    // })
   
  }

  showPreviewImage1 = values => {
    if (values.file_obj1) {
      return <img src={values.file_obj1} style={{ height: 100 }} />;
    }else if (values.image){
      return <img src={`${imageUrl}/images/${values.image}`} style={{ height: 100 }} />
    }
  };
  showPreviewImage2 = values => {
    if (values.file_obj2) {
      return <img src={values.file_obj2} style={{ height: 100 }} />;
    }else if (values.image){
      return <img src={`${imageUrl}/images/${values.image}`} style={{ height: 100 }} />
    }
  };
  showPreviewImage3 = values => {
    if (values.file_obj3) {
      return <img src={values.file_obj3} style={{ height: 100 }} />;
    }else if (values.image){
      return <img src={`${imageUrl}/images/${values.image}`} style={{ height: 100 }} />
    }
  };

  showForm =({values,handleChange,handleSubmit, setFieldValue})=>{
    // this.ValidateEndDate(values.Start_Date,values.End_Date)

    return (
      <form
      className="form-horizontal"
      onSubmit={handleSubmit}>
  
      <div className="form-group">
 
        <label className="col-sm-2 control-label" htmlFor="item_name">
          Product Name
        </label>
        <div className="col-sm-10">
          <div  className="input-group">
          <input
            name="item_name"
            onChange={handleChange}
            value={values.item_name}
            placeholder="Wheel"
            className="form-control"
            type="text"
            id="item_name"
            required
          />
          </div>
        </div>
      </div>

      <div className="form-group">
 
 <label className="col-sm-2 control-label" htmlFor="item_name">
   Part Number
 </label>
 <div className="col-sm-10">
   <div  className="input-group">
   <input
     name="part_number"
     onChange={handleChange}
     value={values.part_number}
     placeholder="xxx-xxxx-xxx"
     className="form-control"
     type="text"
     id="part_number"
  
   />
   </div>
 </div>
</div>
            
    <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="price_list">
          Price List (Baht / Unit)
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="price_list"
              onChange={handleChange}
              value={values.price_list}
              className="form-control"
              placeholder="50"
              type="number"
              id="price_list"
              required
            /> 
           
      
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginTop: 15 }}>
        <div className="col-sm-12 col-sm-offset-2">
        {this.showPreviewImage1(values)}
          <div className="wrap-upload-buttons control-label">
            <ul className="btn-nav row" id="rcorners">
              <li>
                <span style={{ marginLeft: 2 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ic_photo.png`}
                    style={{ width: 25, height: 20 }}
                  />
                  <span
                    style={{ color: "#00B0CD", marginLeft: 10 }}
                  >
                    Add Picture
                  </span>
                  <input
                    type="file"
                    onChange={e=>{
                      e.preventDefault();
                      setFieldValue("file1", e.target.files[0]); // for upload
                      setFieldValue("file_obj1",URL.createObjectURL(e.target.files[0])); // for preview image
                    }}
                    name="image"
                    click-type="type1"
                    className="picupload"
                    multiple
                    accept="image/*"
                    id="files"
                    style={{ padding: "20px 0" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginTop: 15 }}>
        <div className="col-sm-12 col-sm-offset-2">
        {this.showPreviewImage2(values)}
          <div className="wrap-upload-buttons control-label">
            <ul className="btn-nav row" id="rcorners">
              <li>
                <span style={{ marginLeft: 2 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ic_photo.png`}
                    style={{ width: 25, height: 20 }}
                  />
                  <span
                    style={{ color: "#00B0CD", marginLeft: 10 }}
                  >
                    Add Picture
                  </span>
                  <input
                    type="file"
                    onChange={e=>{
                      e.preventDefault();
                      setFieldValue("file2", e.target.files[0]); // for upload
                      setFieldValue("file_obj2",URL.createObjectURL(e.target.files[0])); // for preview image
                    }}
                    name="image"
                    click-type="type1"
                    className="picupload"
                    multiple
                    accept="image/*"
                    id="files"
                    style={{ padding: "20px 0" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginTop: 15 }}>
        <div className="col-sm-12 col-sm-offset-2">
        {this.showPreviewImage3(values)}

          <div className="wrap-upload-buttons control-label">
            <ul className="btn-nav row" id="rcorners">
              <li>
                <span style={{ marginLeft: 2 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ic_photo.png`}
                    style={{ width: 25, height: 20 }}
                  />
                  <span
                    style={{ color: "#00B0CD", marginLeft: 10 }}
                  >
                    Add Picture
                  </span>
                  <input
                    type="file"
                    onChange={e=>{
                      e.preventDefault();
                      setFieldValue("file3", e.target.files[0]); // for upload
                      setFieldValue("file_obj3",URL.createObjectURL(e.target.files[0])); // for preview
                 
                    }}
                    name="image"
                    click-type="type1"
                    className="picupload"
                    multiple
                    accept="image/*"
                    id="files"
                    style={{ padding: "20px 0" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

    
    
   
       
      
    



      <div className="box-footer" style={{ marginTop: 50 }}>
        <button
          type="submit"
          className="btn btn-primary pull-right"
        >
          Save
        </button>
        <a
          onClick={() => {
            // this.props.clearProduct()
            this.props.history.goBack();
          }}
          type="Button"
          className="btn btn-default pull-right"
          style={{ marginRight: 10 }}
        >
          Cancel
        </a>
      </div>
    </form>
  
    )
    
  }
 
  render() {

 

    return (
      <div className="content-wrapper">
        {/* Main content */}
        <section className="content" style={{ maxWidth: "80%" }}>
          <div className="box box-primary" style={{ marginTop: 70 }}>
            <div className="box-header with-border">
              <p className="box-title" style={{ fontSize: 30 }}>
              Create New Product
              </p>
           <p>
          
           </p>
            </div>
            <div className="box-body" style={{ marginTop: 30 }}>
            {/* {this.showPreviewImage()}  */}

              <Formik
                enableReinitialize
                initialValues={{
                  part_number:'',
                  item_name:'',
                  price_list:'',
                }}
                onSubmit={(values, { setSubmitting }) => {
                  let formData = new FormData()
                  formData.append("part_number", values.part_number)
                  formData.append("item_name", values.item_name)
                  formData.append("price_list", values.price_list);

                  if (values.file1){ 
                    formData.append("image1",  values.file1) 
                  }
                  if (values.file2){ 
                    formData.append("image2",  values.file2) 
                  }
                  if (values.file3){ 
                    formData.append("image3",  values.file3) 
                  }
                  setTimeout(() => {
                      this.props.createProduct(formData,this.props.history)
                  }, 2000);
                  
                  // this.props.updateProduct(formData, this.props.history)
                }} >
               {props=> this.showForm(props)} 
          
              
              </Formik>
       
                <br></br>
          
         
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>
    );
  }
}


const mapStateToProps = ({ createProductReducer }) => ({
  createProductReducer
});
const mapDispatchToProps = {
  ...actions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createProduct);

