import React, { Component } from 'react';
// import * as actions from "./../../actions/stock.action";
import * as actions from "../../actions/customer.action";
import { connect } from "react-redux";
import { imageUrl } from "../../constants";
import { Link } from "react-router-dom";
import _ from "lodash";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import "./customer.css";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class Customer extends Component {
 
  componentDidMount(){    

    this.props.getAllCustomer()
    // this.props.getProducts()
    // this.debounceSearch =  _.debounce(this.props.getProductByKeyword, 500)
  }
  // FetchingTotalEVCar=()=>{
  //   const {isFetching, result } = this.props.getEVCarByCategoryReducer
  //   return (
  //          !isFetching &&
  //     result != null && <div>
  //      <h2> {result.TotalEVCar}</h2>
  //     </div>
  //   );
  // }
  // FetchingRentEVCar=()=>{
  //   const {isFetching, result } = this.props.getEVCarByCategoryReducer
  //   return (
  //          !isFetching &&
  //     result != null && <div>
  //      <h2> {result.RentEVCar}</h2>
  //     </div>
  //   );
  // }
  // FetchingSoldEVCar=()=>{
  //   const {isFetching, result } = this.props.getEVCarByCategoryReducer
  //   return (
  //          !isFetching &&
  //     result != null && <div>
  //      <h2> {result.SoldEVCar}</h2>
  //     </div>
  //   );
  // }
  // FetchingDemoEVCar=()=>{
  //   const {isFetching, result } = this.props.getEVCarByCategoryReducer
  //   return (
  //          !isFetching &&
  //     result != null && <div>
  //      <h2> {result.DemoEVCar}</h2>
  //     </div>
  //   );
  // }
  // FetchingReadyToSellEVCar=()=>{
  //   const {isFetching, result } = this.props.getEVCarByCategoryReducer
  //   return (
  //          !isFetching &&
  //     result != null && <div>
  //      <h2> {result.ReadyToSellEVCar}</h2>
  //     </div>
  //   );
  // }
  // FetchingDoNotSellEVCar=()=>{
  //   const {isFetching, result } = this.props.getEVCarByCategoryReducer
  //   return (
  //          !isFetching &&
  //     result != null && <div>
  //      <h2> {result.DoNotSellEVCar}</h2>
  //     </div>
  //   );
  // }
  // FetchingByGroup = (keyword) =>{
  //   this.props.getEVCarByCategoryByKeyWord(keyword)
  // }



  onChange = function(e){
    // e.persist()
    // this.debounceSearch(e)
  }

    createRows = ()=>{
      const {isFetching, result } = this.props.customerReducer
     
    return (
      !isFetching &&
      result != null &&
      result.ArrData.map(item=>(
        <tr key={item.Id}>
        <td><Moment format="DD/MM/YYYY">{item.createdAt}</Moment></td>
        <td>
            <span style={{ marginRight: 10, minHeight: 100 }}>
              {/* <img
                src={`${imageUrl}/images/${item.image}?dummy=${Math.random()}`}
                style={{ maxWidth: 50 }}
              /> */}
            </span>         
              {item.Customer_Code}         
          </td>
          <td>
          {item.Customer_Name}    
            {/* <NumberFormat
              value={item.price}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={"฿"}
            /> */}
          </td>
          {/* <td>
            {item.saleType}
          </td> */}
          <td>
          {item.Customer_Address}    
            {/* <NumberFormat
              value={item.stock}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={0}
              fixedDecimalScale={true}
              suffix={" pcs"}
            /> */}
          </td>
          {/* <td>
          {item.TotalEVCar}    
          </td> */}
          <td style={{ textAlign: "center", }}>
            <Link to={`/customer-edit/${item.Id}`}>Edit</Link>
            {/* <button
              onClick={() =>
                this.props.history.push(`/customer-edit/${item.Id}`)
              }
              type="button"
              className="btn btn-info"
            >
              Edit
            </button> */}

            {/* <span style={{ color: "grey" }}> | </span> */}
            {/* <button   
              onClick={()=>{
                
                MySwal.fire({
                  title: 'Are you sure to delete?',
                  text: "You won't be able to revert this!",
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete it!',
                  cancelButtonText: 'No, cancel!',                                
                }).then((result) => {
                  if (result.value){
                 
                  }                 
                })   

              }}           
              type="button"
              className="btn btn-danger"
            >
              Delete
            </button> */}
          </td>
      </tr>
      ))


     
    )
  }     
  




  // createRows = ()=>{
  //   const { isFetching, result } = this.props.stockReducer;
  //   return (
  //     !isFetching &&
  //     result != null &&
  //     result.map(item=>(
  //       <tr key={item.product_id}>
  //       <td><Moment format="DD/MM/YYYY">{item.created}</Moment></td>
  //       <td>
  //           <span style={{ marginRight: 10, minHeight: 100 }}>
  //             <img
  //               src={`${imageUrl}/images/${item.image}?dummy=${Math.random()}`}
  //               style={{ maxWidth: 50 }}
  //             />
  //           </span>         
  //             {item.name}         
  //         </td>
  //         <td>
  //           <NumberFormat
  //             value={item.price}
  //             displayType={"text"}
  //             thousandSeparator={true}
  //             decimalScale={2}
  //             fixedDecimalScale={true}
  //             prefix={"฿"}
  //           />
  //         </td>
  //         <td>
  //           <NumberFormat
  //             value={item.stock}
  //             displayType={"text"}
  //             thousandSeparator={true}
  //             decimalScale={0}
  //             fixedDecimalScale={true}
  //             suffix={" pcs"}
  //           />
  //         </td>
  //         <td>{item.product_id}</td>
  //         <td style={{ textAlign: "center", }}>
  //           <button
  //             onClick={() =>
  //               this.props.history.push(`/stock-edit/${item.product_id}`)
  //             }
  //             type="button"
  //             className="btn btn-info"
  //           >
  //             แก้ไข
  //           </button>
  //           <span style={{ color: "grey" }}> | </span>
  //           <button   
  //             onClick={()=>{
                
  //               MySwal.fire({
  //                 title: 'Are you sure to delete?',
  //                 text: "You won't be able to revert this!",
  //                 type: 'warning',
  //                 showCancelButton: true,
  //                 confirmButtonText: 'Yes, delete it!',
  //                 cancelButtonText: 'No, cancel!',                                
  //               }).then((result) => {
  //                 if (result.value){
  //                   this.props.deleteProduct(item.product_id)
  //                 }                 
  //               })   

  //             }}           
  //             type="button"
  //             className="btn btn-danger"
  //           >
  //             ลบ
  //           </button>
  //         </td>
  //     </tr>
  //     ))


     
  //   )
  // }

  render() {


    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <h1>
            Customers
            <small>Report</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              {/* <a href="#/">
                <i className="fa fa-dashboard" /> Home
              </a> */}
              <Link to={'/stock'}>   <i className="fa fa-dashboard" /> Home</Link>
            </li>
            <li>
            <Link to={'/stock'}>   <i className="fa fa-dashboard" /> stock</Link>
              {/* <a href="#/">Stock</a> */}
            </li>
          </ol>
        </section>
  
        <section className="content">
          <div className="row">
            <div className="col-xs-12">

              {/* <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_product.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                 
            
                      <p className="p_custom">Total</p>
               
                     
             
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_new.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
         
              
                    <p className="p_custom">Rent</p>
                      
                   
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/images/ic_out_of_stock.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                
                               
                                 <p className="p_custom">Sold</p>
                
                    
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_product.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                 
                     <p className="p_custom">DEMO</p>
                 
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_new.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                    
                      <p className="p_custom">Ready To Sell</p>
                 
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/images/ic_out_of_stock.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                    
                     <p className="p_custom">Maintenance</p>
                  
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="box">
                <div className="box-body">
                  <div className="row" style={{ marginBottom: 40 }}>
                    <div className="col-xs-6">
                      <input
                        onChange={this.onChange.bind(this)}
                        type="search"
                        className="form-control input-lg"
                        placeholder="Enter search keyword"
                        style={{ borderRadius: 10 }}
                      />
                    </div>
                    <div className="col-xs-6 text-right">
                      {/* <Link
                        to="/stock-create"
                        style={{ float: "right", margin: 0, width: 100 }}
                        className="btn btn-success btn-lg"
                      >
                        Add
                      </Link> */}
                    </div>
                  </div>

                  <table
                    id="stock_table"
                    className="table table-bordered table-striped table-hover"
                    style={{ height: 300, maxHeight: 300 }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "7%", textAlign: "center" }}>
                          CreatedAt
                        </th>
                        <th style={{ width: "10%" }}>Customer Code</th>
                        <th style={{ width: "34%" }}>Customer Name</th>
                        {/* <th style={{ width: "10%" }}>Customer Type</th> */}
                        <th style={{ width: "9%" }}>Province</th>
                        {/* <th style={{ width: "9%" }}>EV Car Total</th> */}
                        <th style={{ width: "14%", textAlign: "center" }}>
                          ACTION
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.createRows()}</tbody>
                  </table>
                </div>
                {/* /.box-body */}
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </section>
      </div>
    );
  }
}


const mapStateToProps = ({customerReducer}) => ({
  customerReducer
})

const mapDispatchToProps = {
  ...actions
}

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
