import React, { Component } from "react";
import { Pie, Doughnut, HorizontalBar } from "react-chartjs-2";
import GaugeChart from "react-gauge-chart";

export default class NPSScoreRange extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      NPS: 0,
      Promoters: 0,
      Passives: 0,
      Detractors: 0,
      P_Promoters: 0,
      P_Passives: 0,
      P_Detractors: 0,
      isLoading: 1,
    };
  }

  render() {
    return (
      <div>
        <div className="">
            <img src={`${process.env.PUBLIC_URL}/images/nps_range_score.png`} style={{maxWidth:'100%'}}/>
        </div>
       
      </div>
    );
  }
}
