import React, { Component } from 'react';
// import * as actions from "./../../actions/stock.action";
import * as actions from "../../actions/rental.action";
import { connect } from "react-redux";
import { imageUrl } from "../../constants";
import { Link } from "react-router-dom";
import _ from "lodash";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import "./Rental.css";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class Rental extends Component {
 
  componentDidMount(){    

    this.props.getAllRentalContract()
    // this.props.getProducts()
    // this.debounceSearch =  _.debounce(this.props.getProductByKeyword, 500)
  }
  // FetchingTotalEVCar=()=>{
  //   const {isFetching, result } = this.props.getEVCarByCategoryReducer
  //   return (
  //          !isFetching &&
  //     result != null && <div>
  //      <h2> {result.TotalEVCar}</h2>
  //     </div>
  //   );
  // }
  // FetchingRentEVCar=()=>{
  //   const {isFetching, result } = this.props.getEVCarByCategoryReducer
  //   return (
  //          !isFetching &&
  //     result != null && <div>
  //      <h2> {result.RentEVCar}</h2>
  //     </div>
  //   );
  // }
  // FetchingSoldEVCar=()=>{
  //   const {isFetching, result } = this.props.getEVCarByCategoryReducer
  //   return (
  //          !isFetching &&
  //     result != null && <div>
  //      <h2> {result.SoldEVCar}</h2>
  //     </div>
  //   );
  // }
  // FetchingDemoEVCar=()=>{
  //   const {isFetching, result } = this.props.getEVCarByCategoryReducer
  //   return (
  //          !isFetching &&
  //     result != null && <div>
  //      <h2> {result.DemoEVCar}</h2>
  //     </div>
  //   );
  // }
  // FetchingReadyToSellEVCar=()=>{
  //   const {isFetching, result } = this.props.getEVCarByCategoryReducer
  //   return (
  //          !isFetching &&
  //     result != null && <div>
  //      <h2> {result.ReadyToSellEVCar}</h2>
  //     </div>
  //   );
  // }
  // FetchingDoNotSellEVCar=()=>{
  //   const {isFetching, result } = this.props.getEVCarByCategoryReducer
  //   return (
  //          !isFetching &&
  //     result != null && <div>
  //      <h2> {result.DoNotSellEVCar}</h2>
  //     </div>
  //   );
  // }
  // FetchingByGroup = (keyword) =>{
  //   this.props.getEVCarByCategoryByKeyWord(keyword)
  // }



  onChange = function(e){
    // e.persist()
    // this.debounceSearch(e)
  }

    createRows = ()=>{
      const {isFetching, result } = this.props.rentalReducer
     
    return (
      !isFetching &&
      result != null &&
      result.ArrData.map(item=>(
        <tr key={item.Id}>
        <td><Moment format="DD/MM/YYYY">{item.createdAt}</Moment></td>
        {/* <td>
            <span style={{ marginRight: 10, minHeight: 100 }}>
           
            </span>         
              {item.Id}         
          </td> */}
          <td>
          {item.Contract_Number}    
            {/* <NumberFormat
              value={item.price}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={"฿"}
            /> */}
          </td>
         
          <td><Moment format="DD/MM/YYYY">{item.Start_Date}</Moment></td>
        
       
          <td><Moment format="DD/MM/YYYY">{item.End_Date}</Moment></td>
       
          <td>
          {item.Customer.Customer_Name}    
          </td>
          <td>
          {item.TotalEVCar}    
          </td>
          <td style={{ textAlign: "center", }}>
            <button
              onClick={() =>
                this.props.history.push(`/rental-edit/${item.Id}`)
              }
              type="button"
              className="btn btn-info"
            >
              Edit
            </button>
            {/* <span style={{ color: "grey" }}> | </span> */}
            {/* <button   
              onClick={()=>{
                
                MySwal.fire({
                  title: 'Are you sure to delete?',
                  text: "You won't be able to revert this!",
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete it!',
                  cancelButtonText: 'No, cancel!',                                
                }).then((result) => {
                  if (result.value){
                    this.props.deleteProduct(item.product_id)
                  }                 
                })   

              }}           
              type="button"
              className="btn btn-danger"
            >
              Delete
            </button> */}
          </td>
      </tr>
      ))


     
    )
  }     
  




  render() {


    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <h1>
            Rental
            <small>Report</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              {/* <a href="#/">
                <i className="fa fa-dashboard" /> Home
              </a> */}
              <Link to={'/stock'}>   <i className="fa fa-dashboard" /> Home</Link>
            </li>
            <li>
            <Link to={'/stock'}>   <i className="fa fa-dashboard" /> stock</Link>
              {/* <a href="#/">Stock</a> */}
            </li>
          </ol>
        </section>
  
        <section className="content">
          <div className="row">
            <div className="col-xs-12">

              {/* <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_product.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                 
            
                      <p className="p_custom">Total</p>
               
                     
             
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_new.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
         
              
                    <p className="p_custom">Rent</p>
                      
                   
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/images/ic_out_of_stock.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                
                               
                                 <p className="p_custom">Sold</p>
                
                    
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_product.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                 
                     <p className="p_custom">DEMO</p>
                 
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_new.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                    
                      <p className="p_custom">Ready To Sell</p>
                 
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/images/ic_out_of_stock.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                    
                     <p className="p_custom">Maintenance</p>
                  
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="box">
                <div className="box-body">
                  <div className="row" style={{ marginBottom: 40 }}>
                    <div className="col-xs-6">
                      <input
                        onChange={this.onChange.bind(this)}
                        type="search"
                        className="form-control input-lg"
                        placeholder="Enter search keyword"
                        style={{ borderRadius: 10 }}
                      />
                    </div>
                    <div className="col-xs-6 text-right">
                      {/* <Link
                        to="/rental-create"
                        style={{ float: "right", margin: 0, width: 300 }}
                        className="btn btn-success btn-lg"
                      >
                        Create Rental Contract
                      </Link> */}
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: 40 }}>
                    <div className="col-xs-3">
                    <Link
                        to="/customer-create"
                        style={{ float: "right", margin: 0, width: 300 }}
                        className="btn btn-primary btn-lg"
                      >
                        Create New Customer
                      </Link>
                    </div>
                    <div className="col-xs-3 text-right">
                      <Link
                        to="/site-create"
                        style={{ float: "right", margin: 0, width: 300 }}
                        className="btn btn-info btn-lg"
                      >
                        Create New Site / Location
                      </Link>
                    </div>
                    <div className="col-xs-3 text-right">
                      <Link
                        to="/evcar-create"
                        style={{ float: "right", margin: 0, width: 300 }}
                        className="btn btn-warning btn-lg"
                      >
                       Create New EV Car
                      </Link>
                    </div>
                    <div className="col-xs-3 text-right">
                    <Link
                        to="/rental-create"
                        style={{ float: "right", margin: 0, width: 300 }}
                        className="btn btn-success btn-lg"
                      >
                        Create Rental Contract
                      </Link>
                    </div>
                  </div>

                  <table
                    id="stock_table"
                    className="table table-bordered table-striped table-hover"
                    style={{ height: 300, maxHeight: 300 }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "7%", textAlign: "center" }}>
                          CreatedAt
                        </th>
                        {/* <th style={{ width: "10%" }}>Id</th> */}
                        <th style={{ width: "12%" }}>Contract No.</th>
                        <th style={{ width: "10%" }}>Started Date</th>
                        <th style={{ width: "9%" }}>Ended Date</th>
                        <th style={{ width: "20%" }}>Customer Name</th>
                        <th style={{ width: "9%" }}>Total EV Car</th>
                        <th style={{ width: "14%", textAlign: "center" }}>
                          ACTION
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.createRows()}</tbody>
                  </table>
                </div>
                {/* /.box-body */}
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </section>
      </div>
    );
  }
}


const mapStateToProps = ({rentalReducer}) => ({
  rentalReducer
})

const mapDispatchToProps = {
  ...actions
}

export default connect(mapStateToProps, mapDispatchToProps)(Rental);
