import React, { Component } from 'react';
import * as actions from "../../actions/createNewEVCar.action";
import { connect } from "react-redux";
import { Formik ,FieldArray,Field} from "formik";
import { imageUrl } from "../../constants";
import { Link } from "react-router-dom";
import _, { join, values } from "lodash";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import "./EditEVCar.css";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from 'react-loader-spinner'

const MySwal = withReactContent(Swal)
var jwt = require("jsonwebtoken");
const moment = require('moment')

const orderid = require('order-id')('mysecret');
const id = orderid.generate();

class EditEVCar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result:[],
      EVModel:[],

      Id:'',
      Serial_Number:'',
      Sap_Number:'',
      Manufacturing_Date:'',
      Import_Date:'',
      Description:'',
      Sale_Type:'',
      EVModelId:'',

      EVModelDescription:'',
      Image_No1:'',
      Image_No2:'',
      Image_No3:'',
      Image_No4:'',
      Image_No5:'',
      Image_No6:'',
      Image_No7:'',

    };
    
  }
  componentDidMount(){
    let id = this.props.match.params.id
    // alert(JSON.stringify(this.props.location.query.value))
 
    if(this.props.location.query.value!=undefined){
      let arr  =this.props.location.query.value
      this.setState({Image_No1:arr.Image_No1})
      this.setState({Image_No2:arr.Image_No2})
      this.setState({Image_No3:arr.Image_No3})
      this.setState({Image_No4:arr.Image_No4})
      this.setState({Image_No5:arr.Image_No5})
      this.setState({Image_No6:arr.Image_No6})
      this.setState({Image_No7:arr.Image_No7})

      this.setState({Id:arr.Id})
      this.setState({Serial_Number:arr.Serial_Number})
      this.setState({Sap_Number:arr.Sap_Number})
      this.setState({Manufacturing_Date:arr.Manufacturing_Date})
      this.setState({Import_Date:arr.Import_Date})
      this.setState({Description:arr.Description})
      this.setState({Sale_Type:arr.Sale_Type})
      this.setState({EVModelId:arr.EVModelId})
 




      this.setState({result:this.props.location.query.value})
    
    }
    this.props.getEVModel().then(ctx=>{
      let {isFetching,result} = this.props.createNewEVCarReducer
      if(result!=null){
        if(result.result==='ok'){
          this.setState({EVModel:result.ArrData})
        
    
        }
      }
    })
   
  }
  showPreviewImage1 = values => {
    if (values.file_obj1) {
      return <img src={values.file_obj1} style={{ height: 100 }} />;
    }else{
      return this.state.Image_No1!='' ?  <img src={this.state.Image_No1} style={{ height: 100 }} />:null;
    }
  };
  showPreviewImage2 = values => {
    if (values.file_obj2) {
      return <img src={values.file_obj2} style={{ height: 100 }} />;
    }else{
      return this.state.Image_No2!='' ?  <img src={this.state.Image_No2} style={{ height: 100 }} />:null;
    }
  };
  showPreviewImage3 = values => {
    if (values.file_obj3) {
      return <img src={values.file_obj3} style={{ height: 100 }} />;
    }else{
      return this.state.Image_No3!='' ?  <img src={this.state.Image_No3} style={{ height: 100 }} />:null;
    }
  };
  showPreviewImage4 = values => {
    if (values.file_obj4) {
      return <img src={values.file_obj4} style={{ height: 100 }} />;
    }else{
      return this.state.Image_No4!='' ?  <img src={this.state.Image_No4} style={{ height: 100 }} />:null;
    }
  };
  showPreviewImage5 = values => {
    if (values.file_obj5) {
      return <img src={values.file_obj5} style={{ height: 100 }} />;
    }else{
      return this.state.Image_No5!='' ?  <img src={this.state.Image_No5} style={{ height: 100 }} />:null;
    }
  };
  showPreviewImage6 = values => {
    if (values.file_obj6) {
      return <img src={values.file_obj6} style={{ height: 100 }} />;
    }else{
      return this.state.Image_No6!='' ?  <img src={this.state.Image_No6} style={{ height: 100 }} />:null;
    }
  };
  showPreviewImage7 = values => {
    if (values.file_obj7) {
      return <img src={values.file_obj7} style={{ height: 100 }} />;
    }else{
      return this.state.Image_No7!='' ?  <img src={this.state.Image_No7} style={{ height: 100 }} />:null;
    }
  };
  showEVModelDes =  (value) =>{
    // let {isFetching,result} = this.props.createNewEVCarReducer
 
    if(value!=null&& this.state.EVModel!=null&&value!=''){
        value = parseInt(value)
      let GetArr =  this.state.EVModel
     let arr =  GetArr.filter((item)=>{
        return item.Id === value
      })
      // alert(JSON.stringify( this.state.EVModel))
      return arr[0].Description
    }
    else{
      return ''
    }

  }

  SelectEVModel= ()=>{
    let {isFetching,result} = this.props.createNewEVCarReducer
    
 
    return (
      this.state.EVModel!=null ? this.state.EVModel.map((item,index)=>{
      return  <option  key={item.Model} value={item.Id} >{item.Model}</option>
      }):null
    );
  }


  showForm =({values,handleChange,handleSubmit, setFieldValue})=>{
    // this.ValidateEndDate(values.Start_Date,values.End_Date)

    return (
      <form
      className="form-horizontal"
      onSubmit={handleSubmit}>
      <div className="form-group">
 
        <label className="col-sm-2 control-label" htmlFor="Serial_Number">
          Serial Number
        </label>
        <div className="col-sm-10">
          <div  className="input-group">
          <input
            name="Serial_Number"
            onChange={handleChange}
            value={values.Serial_Number || ''}
            placeholder="0820"
            className="form-control"
            type="text"
            id="Serial_Number"
          />
          </div>
        </div>
      </div>


            
    <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Sap_Number">
        Sap Number
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="Sap_Number"
              onChange={handleChange}
              value={values.Sap_Number || ''}
              className="form-control"
              type="text"
              id="Sap_Number"
              required
            /> 
           
      
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Manufacturing_Date">
        Manufacturing Date
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="Manufacturing_Date"
              onChange={handleChange}
              value={moment(values.Manufacturing_Date).format('yyyy-MM-DD')|| ''}
              className="form-control"
              type="date"
              id="Manufacturing_Date"
              required
            /> 
           
      
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Import_Date">
        Import Date
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="Import_Date"
              onChange={handleChange}
              value={moment(values.Import_Date).format('yyyy-MM-DD')|| ''}
              className="form-control"
              type="date"
              id="Import_Date"
              required
            /> 
           
      
          </div>
        </div>
      </div>


      {/* <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Status">
        Status
        </label>
        <div className="col-sm-10">
          <div className="input-group">
          <select
              name="Status"
              onChange={handleChange}
              value={values.Status}
              className="form-control"
              type="select"
              id="Status"
            > 
           
              <option value='Operate'  >Operate</option>
              <option value='Maintenance'  >Maintenance</option>
        
            </select>
      
          </div>
        </div>
      </div> */}

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Sale_Type">
        Sale Types
        </label>
        <div className="col-sm-10">
          <div className="input-group">
          <select
              name="Sale_Type"
              onChange={handleChange}
              value={values.Sale_Type}
              className="form-control"
              type="select"
              id="Sale_Type"
            > 
        
              <option value='Rent'  >Rent</option>
              <option value='Sold'  >Sold</option>
              <option value='Demo'  >Demo</option>
              <option value='Ready_to_sell'  >Ready to sell</option>
              <option value='Dont_sell'  >Don't sell</option>
            </select>
      
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="EVModelId">
          EV Model
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <select
              name="EVModelId"
              onChange={handleChange}
              value={values.EVModelId}
              className="form-control"
              type="select"
              id="EVModelId"
            > 
              {/* <option value=''  >Select EV Model</option> */}
          
              {this.SelectEVModel()}
       
            </select>
      
          </div>
        </div>
      </div>
      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="EVModelId">
          EV Model Description
        </label>
        <div className="col-sm-10">
          <div className="input-group">
    <p>{ this.showEVModelDes(values.EVModelId) }</p>
        {/* {JSON.stringify(values.EVModelId)} */}
          </div>
        </div>
      </div>

      
      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Description">
        Description
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <textarea
             name="Description"
             onChange={handleChange}
             value={values.Description}
             className="form-control"
             id="Description"
             rows="4" cols="50"
            />
          </div>
        </div>
      </div>
   
      <div className="form-group" style={{ marginTop: 15 }}>
        <div className="col-sm-12 col-sm-offset-2">
          <label>1.Forklift Name Plate</label>
        <div className="row">
        {this.showPreviewImage1(values)}
        </div>

          <div className="wrap-upload-buttons control-label">
            <ul className="btn-nav row" id="rcorners">
              <li>
                <span style={{ marginLeft: 2 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ic_photo.png`}
                    style={{ width: 25, height: 20 }}
                  />
                  <span
                    style={{ color: "#00B0CD", marginLeft: 10 }}
                  >
                    Add Picture
                  </span>
                  <input
                    type="file"
                    onChange={e=>{
                      e.preventDefault();
                      setFieldValue("file1", e.target.files[0]); // for upload
                      setFieldValue("file_obj1",URL.createObjectURL(e.target.files[0])); // for preview
                 
                    }}
                    name="image"
                    click-type="type1"
                    className="picupload"
                    multiple
                    accept="image/*"
                    id="files"
                    style={{ padding: "20px 0" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginTop: 15 }}>
        <div className="col-sm-12 col-sm-offset-2">
          <label>2.Display</label>
        <div className="row">
        {this.showPreviewImage2(values)}
        </div>

          <div className="wrap-upload-buttons control-label">
            <ul className="btn-nav row" id="rcorners">
              <li>
                <span style={{ marginLeft: 2 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ic_photo.png`}
                    style={{ width: 25, height: 20 }}
                  />
                  <span
                    style={{ color: "#00B0CD", marginLeft: 10 }}
                  >
                    Add Picture
                  </span>
                  <input
                    type="file"
                    onChange={e=>{
                      e.preventDefault();
                      setFieldValue("file2", e.target.files[0]); // for upload
                      setFieldValue("file_obj2",URL.createObjectURL(e.target.files[0])); // for preview
                 
                    }}
                    name="image"
                    click-type="type1"
                    className="picupload"
                    multiple
                    accept="image/*"
                    id="files"
                    style={{ padding: "20px 0" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginTop: 15 }}>
        <div className="col-sm-12 col-sm-offset-2">
          <label>3.Front</label>
        <div className="row">
        {this.showPreviewImage3(values)}
        </div>

          <div className="wrap-upload-buttons control-label">
            <ul className="btn-nav row" id="rcorners">
              <li>
                <span style={{ marginLeft: 2 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ic_photo.png`}
                    style={{ width: 25, height: 20 }}
                  />
                  <span
                    style={{ color: "#00B0CD", marginLeft: 10 }}
                  >
                    Add Picture
                  </span>
                  <input
                    type="file"
                    onChange={e=>{
                      e.preventDefault();
                      setFieldValue("file3", e.target.files[0]); // for upload
                      setFieldValue("file_obj3",URL.createObjectURL(e.target.files[0])); // for preview
                 
                    }}
                    name="image"
                    click-type="type1"
                    className="picupload"
                    multiple
                    accept="image/*"
                    id="files"
                    style={{ padding: "20px 0" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginTop: 15 }}>
        <div className="col-sm-12 col-sm-offset-2">
          <label>4.Left Side</label>
        <div className="row">
        {this.showPreviewImage4(values)}
        </div>

          <div className="wrap-upload-buttons control-label">
            <ul className="btn-nav row" id="rcorners">
              <li>
                <span style={{ marginLeft: 2 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ic_photo.png`}
                    style={{ width: 25, height: 20 }}
                  />
                  <span
                    style={{ color: "#00B0CD", marginLeft: 10 }}
                  >
                    Add Picture
                  </span>
                  <input
                    type="file"
                    onChange={e=>{
                      e.preventDefault();
                      setFieldValue("file4", e.target.files[0]); // for upload
                      setFieldValue("file_obj4",URL.createObjectURL(e.target.files[0])); // for preview
                 
                    }}
                    name="image"
                    click-type="type1"
                    className="picupload"
                    multiple
                    accept="image/*"
                    id="files"
                    style={{ padding: "20px 0" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      
      <div className="form-group" style={{ marginTop: 15 }}>
        <div className="col-sm-12 col-sm-offset-2">
          <label>5.Right Side</label>
        <div className="row">
        {this.showPreviewImage5(values)}
        </div>

          <div className="wrap-upload-buttons control-label">
            <ul className="btn-nav row" id="rcorners">
              <li>
                <span style={{ marginLeft: 2 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ic_photo.png`}
                    style={{ width: 25, height: 20 }}
                  />
                  <span
                    style={{ color: "#00B0CD", marginLeft: 10 }}
                  >
                    Add Picture
                  </span>
                  <input
                    type="file"
                    onChange={e=>{
                      e.preventDefault();
                      setFieldValue("file5", e.target.files[0]); // for upload
                      setFieldValue("file_obj5",URL.createObjectURL(e.target.files[0])); // for preview
                 
                    }}
                    name="image"
                    click-type="type1"
                    className="picupload"
                    multiple
                    accept="image/*"
                    id="files"
                    style={{ padding: "20px 0" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="form-group" style={{ marginTop: 15 }}>
        <div className="col-sm-12 col-sm-offset-2">
          <label>6.Rear</label>
        <div className="row">
        {this.showPreviewImage6(values)}
        </div>

          <div className="wrap-upload-buttons control-label">
            <ul className="btn-nav row" id="rcorners">
              <li>
                <span style={{ marginLeft: 2 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ic_photo.png`}
                    style={{ width: 25, height: 20 }}
                  />
                  <span
                    style={{ color: "#00B0CD", marginLeft: 10 }}
                  >
                    Add Picture
                  </span>
                  <input
                    type="file"
                    onChange={e=>{
                      e.preventDefault();
                      setFieldValue("file6", e.target.files[0]); // for upload
                      setFieldValue("file_obj6",URL.createObjectURL(e.target.files[0])); // for preview
                 
                    }}
                    name="image"
                    click-type="type1"
                    className="picupload"
                    multiple
                    accept="image/*"
                    id="files"
                    style={{ padding: "20px 0" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginTop: 15 }}>
        <div className="col-sm-12 col-sm-offset-2">
          <label>7.Battery Name Plate</label>
        <div className="row">
        {this.showPreviewImage4(values)}
        </div>

          <div className="wrap-upload-buttons control-label">
            <ul className="btn-nav row" id="rcorners">
              <li>
                <span style={{ marginLeft: 2 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ic_photo.png`}
                    style={{ width: 25, height: 20 }}
                  />
                  <span
                    style={{ color: "#00B0CD", marginLeft: 10 }}
                  >
                    Add Picture
                  </span>
                  <input
                    type="file"
                    onChange={e=>{
                      e.preventDefault();
                      setFieldValue("file7", e.target.files[0]); // for upload
                      setFieldValue("file_obj7",URL.createObjectURL(e.target.files[0])); // for preview
                 
                    }}
                    name="image"
                    click-type="type1"
                    className="picupload"
                    multiple
                    accept="image/*"
                    id="files"
                    style={{ padding: "20px 0" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
  
   
       
      
    
      
    



      <div className="box-footer" style={{ marginTop: 50 }}>
        <button
          type="submit"
          className="btn btn-primary pull-right"
        >
          Save
        </button>
        <a
          onClick={() => {
            // this.props.clearProduct()
            this.props.history.goBack();
          }}
          type="Button"
          className="btn btn-default pull-right"
          style={{ marginRight: 10 }}
        >
          Cancel
        </a>
      </div>
    </form>
  
    )
    
  }
 
  render() {


    return (
      <div className="content-wrapper">
        {/* Main content */}
        <section className="content" style={{ maxWidth: "80%" }}>
          <div className="box box-primary" style={{ marginTop: 70 }}>
            <div className="box-header with-border">
              <p className="box-title" style={{ fontSize: 30 }}>
             Edit EV Car
              </p>
           <p>
          
           </p>
            </div>
            <div className="box-body" style={{ marginTop: 30 }}>
              <Formik
                enableReinitialize
                initialValues={{
                  Id:this.state.Id,
                  Serial_Number:this.state.Serial_Number,
                  Sap_Number:this.state.Sap_Number,
                  Manufacturing_Date:this.state.Manufacturing_Date,
                  Import_Date:this.state.Import_Date,
                  Sale_Type:this.state.Sale_Type,
                  EVModelId:this.state.EVModelId,
                  Description:this.state.Description
                }}
                onSubmit={(values, { setSubmitting }) => {
                  let formData = new FormData()
                  formData.append("Id", values.Id)
                  formData.append("Serial_Number", values.Serial_Number)
                  formData.append("Sap_Number", values.Sap_Number)
                  formData.append("Manufacturing_Date", values.Manufacturing_Date);
                  formData.append("Import_Date", values.Import_Date);
                  formData.append("Sale_Type", values.Sale_Type);
                  formData.append("EVModelId", values.EVModelId);
                  formData.append("Description", values.Description);
                  
                  formData.append("Image_No1", this.state.Image_No1);
                  formData.append("Image_No2", this.state.Image_No2);
                  formData.append("Image_No3", this.state.Image_No3);
                  formData.append("Image_No4", this.state.Image_No4);
                  formData.append("Image_No5", this.state.Image_No5);
                  formData.append("Image_No6", this.state.Image_No6);
                  formData.append("Image_No7", this.state.Image_No7);
                  if (values.file1){
                    formData.append("image1",  values.file1) 
                  }
                  if (values.file2){ 
                    formData.append("image2",  values.file2) 
                  }
                  if (values.file3){ 
                    formData.append("image3",  values.file3) 
                  }
                  if (values.file4){ 
                    formData.append("image4",  values.file4) 
                  }
                  if (values.file5){ 
                    formData.append("image5",  values.file5) 
                  }
                  if (values.file6){ 
                    formData.append("image6",  values.file6) 
                  }
                  if (values.file7){ 
                    formData.append("image7",  values.file7) 
                  }
                  setTimeout(() => {
             
                    this.props.updateEVCar(values.Id,formData,this.props.history)
                   
                  }, 2000);
                
                }} >
               {this.state.EVModel.length>0? props=> this.showForm(props):null} 
          
              </Formik>
       
                <br></br>
          
         
               {/* <h2>11  {this.state.EVModel!=null ? this.state.E:null}</h2> */}
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>
    );
  }
}


const mapStateToProps = ({ createNewEVCarReducer }) => ({
  createNewEVCarReducer
});
const mapDispatchToProps = {
  ...actions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEVCar);

