import { httpClient } from "../utils/HttpClient";
import {
HTTP_GET_ALL_SITE_FETCHING,
HTTP_GET_ALL_SITE_SUCCESS,
HTTP_GET_ALL_SITE_FAILED,


  server,
//   HTTP_STOCK_CLEAR
} from "../constants";

export const setStateGetSiteToSuccess = payload => ({
  type: HTTP_GET_ALL_SITE_SUCCESS,
  payload
});

const setStateGetSiteToFetching = () => ({
  type: HTTP_GET_ALL_SITE_FETCHING
});

const setStateGetSiteToFailed = () => ({
  type: HTTP_GET_ALL_SITE_FAILED
});

export const getAllSite = () => {
  return async dispatch => {
    try {
        dispatch(setStateGetSiteToFetching()); 
     
        let result = await httpClient.get(server.GET_ALL_SITE);

        if (result.data.result == 'ok') {
     
          dispatch(setStateGetSiteToSuccess(result.data));
  
        } else {        
          dispatch(setStateGetSiteToFailed(result.data));
        }
      } catch (error) {        
          dispatch(setStateGetSiteToFailed({data: {message: error}}));
      }
  };
};
