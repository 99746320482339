import React, { Component } from 'react';
// import * as actions from "./../../actions/stock.action";
import * as actions from "../../actions/maintenance.action";
import { connect } from "react-redux";
import { imageUrl } from "../../constants";
import { Link } from "react-router-dom";
import _ from "lodash";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import "./maintenance.css";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class maintenance extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = { 
      TotalEVCar: '',
      RentEVCar:'',
      SoldEVCar:'',
      DemoEVCar:'',
      ReadyToSellEVCar:'',
      DoNotSellEVCar:'',
      TotalPM:'',
      TotalBM:'',
      TotalBM_PM:''
   };

  }
  componentDidMount(){    
    // window.location.reload(); 
    // window.location.reload(false);
    this.props.getAllPMTask().then(()=>{
      // window.location.reload(); 
      // alert(JSON.stringify(this.props.maintenanceReducer))
    });
   
    // this.props.getProducts()
    // this.debounceSearch =  _.debounce(this.props.getProductByKeyword, 500)
  }
  FetchingTotalBM_PM=()=>{
    const {isFetching, result } = this.props.maintenanceReducer
    return (
           !isFetching &&
      result != null && <div>
       <h2> {result.PM.ArrData.length+result.BM.ArrData.length}</h2>
      </div>
    );
  }
  FetchingPM=()=>{
    const {isFetching, result } = this.props.maintenanceReducer
    return (
           !isFetching &&
      result != null && <div>
       <h2> {result.PM.ArrData.length}</h2>

      </div>
    );
  }
  FetchingBM=()=>{
    const {isFetching, result } = this.props.maintenanceReducer
    return (
           !isFetching &&
      result != null && <div>
           <h2> {result.BM.ArrData.length}</h2>
      </div>
    );
  }
  FetchingDemoEVCar=()=>{
    const {isFetching, result } = this.props.maintenanceReducer
    return (
           !isFetching &&
      result != null && <div>
       <h2> {result.DemoEVCar}</h2>
      </div>
    );
  }
  FetchingReadyToSellEVCar=()=>{
    const {isFetching, result } = this.props.maintenanceReducer
    return (
           !isFetching &&
      result != null && <div>
       <h2> {result.ReadyToSellEVCar}</h2>
      </div>
    );
  }
  FetchingDoNotSellEVCar=()=>{
    const {isFetching, result } = this.props.maintenanceReducer
    return (
           !isFetching &&
      result != null && <div>
       <h2> {result.DoNotSellEVCar}</h2>
      </div>
    );
  }
  FetchingByGroup = (keyword) =>{
    this.props.getEVCarByCategoryByKeyWord(keyword)
  }



  onChange = function(e){
    // e.persist()
    // this.debounceSearch(e)
  }

    createRowsPM = ()=>{
      const {isFetching, result } = this.props.maintenanceReducer
    return (
      !isFetching &&
      result != null &&
      result.PM.ArrData.map(item=>(
      
        <tr key={item.Id}>
        <td><Moment format="DD/MM/YYYY">{item.Appointment_Date}</Moment></td>
        <td>
            <span style={{ marginRight: 10, minHeight: 100 }}>
            
            </span>         
              {item.PM_ID}         
          </td>
          <td>
          {item.Rental.Contract_Number}    
         
          </td>
          <td>
            {item.Customer_Name }
          </td>
          <td>
          {item.Location}    
      
          </td>
          <td>
          {item.Qty}    
          </td>
          <td>
          {item.Total}    
          </td>
          <td>
          {item.Service_Total}    
          </td>
          <td>
          {item.Product_Total}    
          </td>
          <td style={{ textAlign: "center", }}>
            <Link 
              className="btn btn-info" 
              to={{pathname: `/pm-edit/${item.Id}`}}
              
              >
            Edit
            </Link>
            <span style={{ color: "grey" }}> | </span>
            <button   
              onClick={()=>{
                
                MySwal.fire({
                  title: 'Are you sure to delete?',
                  text: "You won't be able to revert this!",
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete it!',
                  cancelButtonText: 'No, cancel!',                                
                }).then((result) => {
                  if (result.value){
                    this.props.deletePM(item.PM_ID,this.props.history)
                    setTimeout(function(){
                      window.location.reload(); 
                    },5000)
                  }     
                            
                })   

              }}           
              type="button"
              className="btn btn-danger"
            >
              Delete
            </button>
          </td>
      </tr>
      ))


     
    )
  }   
  createRowsBM = ()=>{
    const {isFetching, result } = this.props.maintenanceReducer
  return (
    !isFetching &&
    result != null &&
    result.BM.ArrData.map(item=>(
    
      <tr key={item.Id}>
      <td><Moment format="DD/MM/YYYY">{item.Appointment_Date}</Moment></td>
      <td>
          <span style={{ marginRight: 10, minHeight: 100 }}>
          
          </span>         
            {item.BM_ID}         
        </td>
        <td>
        {item.Rental.Contract_Number}    
       
        </td>
        <td>
          {item.Customer_Name }
        </td>
        <td>
        {item.Location}    
    
        </td>
        <td>
        {item.Qty}    
        </td>
        <td>
        {item.Total}    
        </td>
        <td>
        {item.Service_Total}    
        </td>
        <td>
        {item.Product_Total}    
        </td>
        <td style={{ textAlign: "center", }}>
          <Link 
            className="btn btn-info" 
            to={{pathname: `/bm-edit/${item.Id}`}}
            
            >
          Edit
          </Link>
          <span style={{ color: "grey" }}> | </span>
          <button   
            onClick={()=>{
              
              MySwal.fire({
                title: 'Are you sure to delete?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',                                
              }).then((result) => {
                if (result.value){
                  this.props.deleteBM(item.BM_ID,this.props.history)
                  setTimeout(function(){
                    window.location.reload(); 
                  },5000)
                }                 
              })   

            }}           
            type="button"
            className="btn btn-danger"
          >
            Delete
          </button>
        </td>
    </tr>
    ))


   
  )
}       
  






  render() {


    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <h1>
            Stock
            <small>Report</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <a href="#/">
                <i className="fa fa-dashboard" /> Home
              </a>
            </li>
            <li>
              <a href="#/">PM</a>
            </li>
          </ol>
        </section>
        {/* Main content */}
        <section className="content">
          <div className="row">
            <div className="col-xs-12">
              <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_product.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                      {/* <p className="p_custom">Total</p>
                     {this.FetchingTotalEVCar()}
                      */}
                      <a >
                      <p className="p_custom">Total</p>
                     {this.FetchingTotalBM_PM()}
                     
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_new.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
         
                    <a  >
                    <p className="p_custom">Preventive</p>
                      {this.FetchingPM()}
                    </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/images/ic_out_of_stock.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                
                                 <a >
                                 <p className="p_custom">Breakdown</p>
                      {this.FetchingBM()}
                                 </a>
                    
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_product.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                     <a onClick={()=>{this.FetchingByGroup('Demo')}}>
                     <p className="p_custom">DEMO</p>
                      {this.FetchingDemoEVCar()}
                     </a>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ic_new.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                      <a onClick={()=>{this.FetchingByGroup('Ready_to_sell')}}>
                      <p className="p_custom">Ready To Sell</p>
                      {this.FetchingReadyToSellEVCar()}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="info-box">
                    <span className="info-box-icon">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/images/ic_out_of_stock.png`}
                        className="logo"
                      />
                    </span>
                    <div className="info-box-content">
                     <a onClick={()=>{this.FetchingByGroup('Dont_sell')}}>
                     <p className="p_custom">Maintenance</p>
                      {this.FetchingDoNotSellEVCar()}
                     </a>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="box">
                <div className="box-body">
                  <div className="row" style={{ marginBottom: 40 }}>
                    <div className="col-xs-6">
                      <input
                        onChange={this.onChange.bind(this)}
                        type="search"
                        className="form-control input-lg"
                        placeholder="Enter search keyword"
                        style={{ borderRadius: 10 }}
                      />
                    </div>
                    <div className="col-xs-3 text-right">
                      <Link
                        to="/pm-create"
                        style={{ float: "right", margin: 0, width: 200 }}
                        className="btn btn-success btn-lg"
                      >
                        Create PM
                      </Link>
                    </div>
                    <div className="col-xs-3 text-right">
                      <Link
                        to="/bm-create"
                        style={{ float: "right", margin: 0, width: 200 }}
                        className="btn btn-warning btn-lg"
                      >
                        Create BM
                      </Link>
                    </div>
                    <div className="col-xs-3 text-right">
                      {/* <Link
                        to="/product-create"
                        style={{ float: "right", margin: 0, width: 200 }}
                        className="btn btn-warning btn-lg"
                      >
                        Create Product
                      </Link> */}
                    </div>
                    <div className="col-xs-3 text-right">
                      {/* <Link
                        to="/pm-create"
                        style={{ float: "right", margin: 0, width: 200 }}
                        className="btn btn-warning btn-lg"
                      >
                        Create BM
                      </Link> */}
                    </div>
                  </div>

                  <table
                    id="stock_table"
                    className="table table-bordered table-striped table-hover"
                    style={{ height: 300, maxHeight: 300 }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "7%", textAlign: "center" }}>
                          Appointment Date
                        </th>
                        <th style={{ width: "15%" }}>PM ID / BM ID</th>
                        <th style={{ width: "5%" }}>Contract Number</th>
                        <th style={{ width: "9%" }}>Customer</th>
                        <th style={{ width: "9%" }}>Location</th>
                        <th style={{ width: "5%" }}>Qty</th>
                        <th style={{ width: "12%" }}>Total Price</th>
                        <th style={{ width: "12%" }}>Service Price</th>
                        <th style={{ width: "12%" }}>Consumable Price</th>
                        <th style={{ width: "14%", textAlign: "center" }}>
                          ACTION
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.createRowsPM()}</tbody>
                    <tbody>{this.createRowsBM()}</tbody>
                  </table>
                </div>
                {/* /.box-body */}
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </section>
      </div>
    );
  }
}


const mapStateToProps = ({maintenanceReducer}) => ({
  maintenanceReducer
})

const mapDispatchToProps = {
  ...actions
}

export default connect(mapStateToProps, mapDispatchToProps)(maintenance);
