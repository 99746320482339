import { httpClient } from "../utils/HttpClient";
import {
HTTP_CUSTOMER_FETCHING,
HTTP_CUSTOMER_SUCCESS,
HTTP_CUSTOMER_FAILED,

HTTP_CUSTOMER_DETAIL_FAILED,
HTTP_CUSTOMER_DETAIL_SUCCESS,
HTTP_CUSTOMER_DETAIL_FETCHING,
  server,
//   HTTP_STOCK_CLEAR
} from "../constants";

export const setStateGetCustomerToSuccess = payload => ({
  type: HTTP_CUSTOMER_SUCCESS,
  payload
});

const setStateGetCustomerToFetching = () => ({
  type: HTTP_CUSTOMER_FETCHING
});

const setStateGetCustomerToFailed = () => ({
  type: HTTP_CUSTOMER_FAILED
});

// export const setStateGetCustomerDetailToSuccess = payload => ({
//   type: HTTP_CUSTOMER_DETAIL_SUCCESS,
//   payload
// });

// const setStateGetCustomerDetailFetching = () => ({
//   type: HTTP_CUSTOMER_DETAIL_FETCHING
// });

// const setStateGetCustomerDetailFailed = () => ({
//   type: HTTP_CUSTOMER_DETAIL_FAILED
// });





export const getAllCustomer = () => {
  return async dispatch => {
    try {
        dispatch(setStateGetCustomerToFetching()); 
     
        let result = await httpClient.get(server.GET_CUSTOMER);

        if (result.data.result == 'ok') {
     
          dispatch(setStateGetCustomerToSuccess(result.data));
  
        } else {        
          dispatch(setStateGetCustomerToFailed(result.data));
        }
      } catch (error) {        
          dispatch(setStateGetCustomerToFailed({data: {message: error}}));
      }
  };
};
export const getCustomerDetail = (Id) => {
    return async dispatch => {
      try {
          dispatch(setStateGetCustomerToFetching()); 
       
          let result = await httpClient.get(`${server.GET_CUSTOMER_DETAIL}${Id}`);
          // alert(JSON.stringify(result.data))
          if (result.data.result == 'ok') {
       
            dispatch(setStateGetCustomerToSuccess(result.data));
    
          } else {        
            dispatch(setStateGetCustomerToFailed(result.data));
          }
        } catch (error) {        
            dispatch(setStateGetCustomerToFailed({data: {message: error}}));
        }
    };
  };


