import React, { Component } from "react";
import * as actions from "../../actions/dashboard.action";
import { connect } from "react-redux";
import { imageUrl } from "../../constants";
import { Link } from "react-router-dom";
import _ from "lodash";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import "./dashboard.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import NPS from "../../shared/NPS";
import NPSBars from "../../shared/NPSBars";
import NPSScoreRange from "../../shared/NPSScoreRange";

import ProductsAndServicesPage from "../../shared/ProductsAndServices_Page";
import SalesPersonPage from "../../shared/Salesperson_Page";
import DeliveryPage from "../../shared/Delivery_Page";
import ClaimAndSupportPage from '../../shared/ClaimAndSupport_Page';


import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const MySwal = withReactContent(Swal);

class Dashboard extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      TotalEVCar: "",
      RentEVCar: "",
      SoldEVCar: "",
      DemoEVCar: "",
      ReadyToSellEVCar: "",
      DoNotSellEVCar: "",
      startDateParm: new Date(new Date().getFullYear() + "-01-01T00:00:00"),//samano added
      endDateParm: new Date(new Date().getFullYear() + "-12-31T23:59:59"),//samano added
    };
  }
  componentDidMount() {
    this.props.getAllSurveyResults("All", "All", "", "");

    
  }

  handleStartDate = (_date, _productparm, _companyparm, _endDateParm) => {

    this.setState({
      startDateParm: _date
    })

    //alert(_date);
    //this.props.getAllSurveyResults(_productparm, _companyparm, _date, _endDateParm);


  }
  handleEndDate = (_date, _productparm, _companyparm, _startDateParm) => {

    this.setState({
      endDateParm: _date
    })
    //this.props.getAllSurveyResults(_productparm, _companyparm, this.state.startDateParm, _date);




  }
  handleSearch(e){
    const { isFetching, result } = this.props.dashboardReducer;
    this.props.getAllSurveyResults(result.productparm, result.companyparm, this.state.startDateParm, this.state.endDateParm);
  }

  handleSelectProducts = (e, _companyparm, _startDateParm, _endDateParm) => {

    e.preventDefault()
    if (e.target.value == "All") {
      this.props.getAllSurveyResults("All", _companyparm, this.state.startDateParm, this.state.endDateParm);
    } else {
      this.props.getAllSurveyResults(e.target.value, _companyparm, this.state.startDateParm, this.state.endDateParm);
    }

  }
  // handleSelectCountries = (e,_productparm) => {

  //   e.preventDefault()
  //   if(e.target.value == "All"){
  //     this.props.getAllSurveyResults(_productparm,"All");
  //   }else{
  //     this.props.getAllSurveyResults(_productparm,e.target.value);
  //   }

  // }
  handleSelectCompanys = (e, _productparm, _startDateParm, _endDateParm) => {

    e.preventDefault()
    if (e.target.value == "All") {
      this.props.getAllSurveyResults(_productparm, "All", this.state.startDateParm, this.state.endDateParm);
    } else {
      this.props.getAllSurveyResults(_productparm, e.target.value, this.state.startDateParm, this.state.endDateParm);
    }

  }
  FetchingCompanys = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return (
      <div>
        <label for="companys">BKK or IO Company:</label>

        <select className="form-select form-select-lg mb-3" name="companys" id="companys" onChange={(e) => this.handleSelectCompanys(e, result.productparm, result.startDateParm, result.endDateParm)}>
          <option selected>All</option>
          {result.companys.Companys.map((Item, index) => {

            return (
              <>
                <option key={index} selected={result.companyparm === Item.Company_Code} value={Item.Company_Code}>{Item.Company_Code} - {Item.Company_Name}</option>
              </>
            )

          })}
        </select>


      </div>

    );
  };

  // FetchingCountries = () => {
  //   const { isFetching, result } = this.props.dashboardReducer;
  //   return (
  //     <div>

  //       <label for="countries">Country:</label>

  //       <select className="form-select form-select-lg mb-3" name="countries" id="countries" onChange={(e) => this.handleSelectCountries(e,result.productparm,"","")}>
  //       <option selected>All</option>
  //         {result.countries.Countries.map((Item, index) => {

  //           return (
  //             <>
  //               <option key={index} selected={result.countryparm === Item.Customer_Country} value={Item.Customer_Country}>{Item.Customer_Country}</option>
  //             </>
  //           )

  //         })}
  //       </select>


  //     </div>

  //   );
  // };

  FetchingProducts = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return (
      <div>
        <label for="products">Product H1: </label>

        <select className="form-select form-select-lg mb-3" name="products" id="products" onChange={(e) => this.handleSelectProducts(e, result.companyparm, result.startDateParm, result.endDateParm)}>
          <option value="All">All</option>
          {result.products.Products.map((Item, index) => {

            return (
              <>
                <option key={index} selected={result.productparm === Item.Mat_ProdH1} value={Item.Mat_ProdH1}>{Item.Product_Cat}({Item.Mat_ProdH1})</option>
              </>
            )

          })}
        </select>


      </div>

    );
  };
  FetchingFromTo = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return (
      <div>
        <div className="form-group row">
          <div className="col-md-1">
            <label htmlFor="inputFirstname">From :</label>
            <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.startDateParm} onChange={(date) => this.handleStartDate(date, result.productparm, result.companyparm, result.endDateParm)} />
            {/* <input type="text" className="form-control" id="inputFirstname" placeholder="First name" /> */}
          </div>
          <div className="col-md-1">
            <label htmlFor="inputLastname">To:</label>
            <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.endDateParm} onChange={(date) => this.handleEndDate(date, result.productparm, result.companyparm, result.startDateParm)} />
            {/* <input type="text" className="form-control" id="inputLastname" placeholder="Last name" /> */}
          </div>
          <div className="col-md-1">
            <label htmlFor="inputLastname">&nbsp;</label>
            <div>
              <button onClick={(e) => this.handleSearch(e)}>Search</button>
            </div>
          </div>
        </div>

        {/* From: 
        TO: <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.endDateParm} onChange={(date) => this.handleEndDate(date)} /> */}
      </div>

    );
  };

  FetchingTotalSendingSurvey = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return (
      !isFetching &&
      result != null && (
        <>
          <> {result.AllSurvey.Total_Sending}</>
        </>
      )
    );
  };
  FetchingTotalSurveyResult = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return (
      !isFetching &&
      result != null && (
        <>
          <> {result.AllSurvey.Total_Response}</>
        </>
      )
    );
  };
  FetchingTotalSurveyResultPercentage = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return (
      !isFetching &&
      result != null &&
      result.AllSurvey.Total_Response_Percentage
    );
  };

  FetchingFromDate = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return !isFetching && result != null && result.AllSurvey.FromDate;
  };
  FetchingLatestDate = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return !isFetching && result != null && result.AllSurvey.LatestDate;
  };
  FetchingWeeklySending = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return !isFetching && result != null && result.AllSurvey.Total_Weekly;
  };
  FetchingMonthlySending = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return !isFetching && result != null && result.AllSurvey.Total_Monthly;
  };
  FetchingQuarterlySending = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return !isFetching && result != null && result.AllSurvey.Total_Quarterly;
  };
  FetchingYearlySending = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return !isFetching && result != null && result.AllSurvey.Total_Yearly;
  };

  FetchingProductAndServiceScore = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return (
      !isFetching &&
      result != null &&
      result.ResultByLevel.ProductsAndServicesScore
    );
  };
  FetchingSalespersonScore = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return (
      !isFetching && result != null && result.ResultByLevel.SalespersonScore
    );
  };
  FetchingDeliveryScore = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return !isFetching && result != null && result.ResultByLevel.DeliveryScore;
  };
  FetchingClaimAndSupportScore = () => {
    const { isFetching, result } = this.props.dashboardReducer;
    return (
      !isFetching && result != null && result.ResultByLevel.ClaimAndSupportScore
    );
  };
  onChange = function (e) {
    // e.persist()
    // this.debounceSearch(e)
  };

  ScoringComponent = () => {
    return (
      <div className="row">
        <div className="col-xs-12">

          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <div className="text-center">
                  <h1 className="card-title btn btn-danger btn-lg">
                    1. Products / Services
                  </h1>
                </div>
                <div style={{ marginLeft: "20px", marginTop: "20px" }}>
                  <p className="card-text">a. Quality consistency</p>
                  <p className="card-text">b. Good value for money</p>
                  <p className="card-text">c. Variety</p>
                </div>

                {parseFloat(this.FetchingProductAndServiceScore()).toFixed(2) >
                  3.5 ? (
                  <div
                    className="text-center circle"
                    style={{ backgroundColor: "green" }}
                  >
                    <h1 style={{ marginTop: "75px" }}>
                      {parseFloat(
                        this.FetchingProductAndServiceScore()
                      ).toFixed(2)}
                    </h1>
                  </div>
                ) : null}
                {parseFloat(this.FetchingProductAndServiceScore()).toFixed(2) <=
                  3.5 &&
                  parseFloat(this.FetchingProductAndServiceScore()).toFixed(2) >=
                  2.5 ? (
                  <div
                    className="text-center circle"
                    style={{ backgroundColor: "#DAA520" }}
                  >
                    <h1 style={{ marginTop: "75px" }}>
                      {parseFloat(
                        this.FetchingProductAndServiceScore()
                      ).toFixed(2)}
                    </h1>
                  </div>
                ) : null}
                {parseFloat(this.FetchingProductAndServiceScore()).toFixed(2) <
                  2.5 ? (
                  <div
                    className="text-center circle"
                    style={{ backgroundColor: "red" }}
                  >
                    <h1 style={{ marginTop: "75px" }}>
                      {parseFloat(
                        this.FetchingProductAndServiceScore()
                      ).toFixed(2)}
                    </h1>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <div className="text-center">
                  <h1 className="card-title btn btn-danger btn-lg">
                    2. Salesperson
                  </h1>
                </div>
                <div style={{ marginLeft: "20px", marginTop: "20px" }}>
                  <p className="card-text">a. Shares market information</p>
                  <p className="card-text">b. Easy to contact</p>
                  <p className="card-text">c. Fast response</p>
                </div>
                {parseFloat(this.FetchingSalespersonScore()).toFixed(2) >
                  3.5 ? (
                  <div
                    className="text-center circle"
                    style={{ backgroundColor: "green" }}
                  >
                    <h1 style={{ marginTop: "75px" }}>
                      {parseFloat(this.FetchingSalespersonScore()).toFixed(2)}
                    </h1>
                  </div>
                ) : null}
                {parseFloat(this.FetchingSalespersonScore()).toFixed(2) <=
                  3.5 &&
                  parseFloat(this.FetchingSalespersonScore()).toFixed(2) >=
                  2.5 ? (
                  <div
                    className="text-center circle"
                    style={{ backgroundColor: "#DAA520" }}
                  >
                    <h1 style={{ marginTop: "75px" }}>
                      {parseFloat(this.FetchingSalespersonScore()).toFixed(2)}
                    </h1>
                  </div>
                ) : null}
                {parseFloat(this.FetchingSalespersonScore()).toFixed(2) <
                  2.5 ? (
                  <div
                    className="text-center circle"
                    style={{ backgroundColor: "red" }}
                  >
                    <h1 style={{ marginTop: "75px" }}>
                      {parseFloat(this.FetchingSalespersonScore()).toFixed(2)}
                    </h1>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <div className="text-center">
                  <h1 className="card-title btn btn-danger btn-lg">
                    3. Delivery
                  </h1>
                </div>
                <div style={{ marginLeft: "20px", marginTop: "20px" }}>
                  <p className="card-text">a. Shipment status update</p>
                  <p className="card-text">b. On time delivery</p>
                  <p className="card-text">c. Accuracy of shipping document</p>
                </div>
                {parseFloat(this.FetchingDeliveryScore()).toFixed(2) > 3.5 ? (
                  <div
                    className="text-center circle"
                    style={{ backgroundColor: "green" }}
                  >
                    <h1 style={{ marginTop: "75px" }}>
                      {parseFloat(this.FetchingDeliveryScore()).toFixed(2)}
                    </h1>
                  </div>
                ) : null}
                {parseFloat(this.FetchingDeliveryScore()).toFixed(2) <= 3.5 &&
                  parseFloat(this.FetchingDeliveryScore()).toFixed(2) >= 2.5 ? (
                  <div
                    className="text-center circle"
                    style={{ backgroundColor: "#DAA520" }}
                  >
                    <h1 style={{ marginTop: "75px" }}>
                      {parseFloat(this.FetchingDeliveryScore()).toFixed(2)}
                    </h1>
                  </div>
                ) : null}
                {parseFloat(this.FetchingDeliveryScore()).toFixed(2) < 2.5 ? (
                  <div
                    className="text-center circle"
                    style={{ backgroundColor: "red" }}
                  >
                    <h1 style={{ marginTop: "75px" }}>
                      {parseFloat(this.FetchingDeliveryScore()).toFixed(2)}
                    </h1>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12 ">
              <div className="info-box ">
                <div className="text-center">
                  <h1 className="card-title btn btn-danger btn-lg">
                    4. Claim and support
                  </h1>
                </div>
                <div style={{ marginLeft: "20px", marginTop: "20px" }}>
                  <p className="card-text">a. Claim process</p>
                  <p className="card-text">b. Speed of claim response</p>
                  <p className="card-text">c. After sale service</p>
                </div>
                {parseFloat(this.FetchingClaimAndSupportScore()).toFixed(2) >
                  3.5 ? (
                  <div
                    className="text-center circle"
                    style={{ backgroundColor: "green" }}
                  >
                    <h1 style={{ marginTop: "75px" }}>
                      {parseFloat(this.FetchingClaimAndSupportScore()).toFixed(
                        2
                      )}
                    </h1>
                  </div>
                ) : null}
                {parseFloat(this.FetchingClaimAndSupportScore()).toFixed(2) <=
                  3.5 &&
                  parseFloat(this.FetchingClaimAndSupportScore()).toFixed(2) >=
                  2.5 ? (
                  <div
                    className="text-center circle"
                    style={{ backgroundColor: "#DAA520" }}
                  >
                    <h1 style={{ marginTop: "75px" }}>
                      {parseFloat(this.FetchingClaimAndSupportScore()).toFixed(
                        2
                      )}
                    </h1>
                  </div>
                ) : null}
                {parseFloat(this.FetchingClaimAndSupportScore()).toFixed(2) <
                  2.5 ? (
                  <div
                    className="text-center circle"
                    style={{ backgroundColor: "red" }}
                  >
                    <h1 style={{ marginTop: "75px" }}>
                      {parseFloat(this.FetchingClaimAndSupportScore()).toFixed(
                        2
                      )}
                    </h1>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 text-center">
              <h1 className="card-title btn btn-danger btn-lg">5. NPS Score</h1>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 ">
              <div className="info-box ">
                <div className="text-center">
                  {/* <h1 className="card-title btn btn-danger btn-lg">
                    5. NPS Score
                  </h1> */}
                </div>
                <div style={{ marginLeft: "20px", marginTop: "20px" }}>
                  <p className="card-text">
                    One a scale of 0 - 10, how likely are you to recommend our
                    products / services to friends
                  </p>
                </div>
                <NPSScoreRange />
                {/* <NPS value={this.props.dashboardReducer}/> */}
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 ">
              <div className="info-box ">
                <div className="text-center">
                  {/* <h1 className="card-title btn btn-danger btn-lg">
                  
                  </h1> */}
                </div>
                <div style={{ marginLeft: "20px", marginTop: "20px" }}>
                  {/* <p className="card-text">One a scale of 0 - 10, how likely are you to recommend our products / services to friends</p> */}
                </div>
                <NPS value={this.props.dashboardReducer} />
                {/* <NPSBars value={this.props.dashboardReducer}/> */}
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 ">
              <div className="info-box ">
                <div className="text-center">
                  {/* <h1 className="card-title btn btn-danger btn-lg">
                  
                  </h1> */}
                </div>
                <div style={{ marginLeft: "20px", marginTop: "20px" }}>
                  {/* <p className="card-text">One a scale of 0 - 10, how likely are you to recommend our products / services to friends</p> */}
                </div>
                {/* <NPS value={this.props.dashboardReducer}/> */}
                <NPSBars value={this.props.dashboardReducer} />
                {/* <NPSScoreRange/> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  PageComponent = () => {
    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 ">
              <div className="info-box ">
                <div className="text-center">
                  <h1 className="card-title btn btn-danger btn-lg">
                    1. Products / Services
                  </h1>
                </div>
              </div>
              <div>
                <ProductsAndServicesPage value={this.props.dashboardReducer} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 ">
              <div className="info-box ">
                <div className="text-center">
                  <h1 className="card-title btn btn-danger btn-lg">
                    2. Salesperson
                  </h1>
                </div>
              </div>
              <div>

                <SalesPersonPage value={this.props.dashboardReducer} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 ">
              <div className="info-box ">
                <div className="text-center">
                  <h1 className="card-title btn btn-danger btn-lg">
                    3. Delivery
                  </h1>
                </div>
              </div>
              <div>

                <DeliveryPage value={this.props.dashboardReducer} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 ">
              <div className="info-box ">
                <div className="text-center">
                  <h1 className="card-title btn btn-danger btn-lg">
                    4. Claim and support
                  </h1>
                </div>
              </div>
              <div>

                <ClaimAndSupportPage value={this.props.dashboardReducer} />
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 ">
              <div className="info-box ">
                <div className="text-center">
                  <h1 className="card-title btn btn-danger btn-lg">
                    Comments
                  </h1>
                </div>
              </div>
              <div>

                <div className="container">

                  {/* <ul className="container">
        
              {this.props.dashboardReducer.result.Comment.Comments.map((Item,index)=>{
                return <>
                <li className="text-muted" key={index}> {`${index+1}.) ${Item.CustomerName} ----
                ${ Item.Comment}
                 `}</li>
               
                      </>;
              })}
          </ul> */}
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Customer Name</th>
                          <th scope="col">Country</th>
                          {/* <th scope="col">SaleGroup</th> */}
                          <th scope="col">Comments</th>

                        </tr>
                      </thead>
                      <tbody>



                        {this.props.dashboardReducer.result.Comment.Comments.map((Item, index) => {
                          return <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{Item.CustomerName}</td>
                            <td>{Item.Country}</td>
                            {/* <td>{Item.SaleGroup}</td> */}
                            <td>{Item.Comment}</td>

                          </tr>

                        })}



                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <h1>
            Dashboard
            <small>Report</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <a href="#/">
                <i className="fa fa-dashboard" /> Home
              </a>
            </li>
            <li>
              <a href="#/">Dashboard</a>
            </li>
          </ol>
        </section>
        {/* Main content */}
        {
          // <Skeleton width={1768} height={768} />
        }
        {this.props.dashboardReducer.result === null ? (
          <Skeleton width={1768} height={768} />
        ) : (
          <section className="content">
            <div className="row">
              <div className="col-xs-12">
                {this.FetchingFromTo()}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                {this.FetchingCompanys()}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                {this.FetchingProducts()}
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="info-box">
                      <span className="info-box-icon">
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/images/ic_catagory.png`}
                          className="logo"
                        />
                      </span>
                      <div className="info-box-content">
                        {/* <p className="p_custom">Total</p>
                     {this.FetchingTotalEVCar()}
                      */}
                        <a
                          href="#/"
                          onClick={() => {
                            this.FetchingTotalSendingSurvey();
                          }}
                        >
                          <p className="p_custom">
                            Total Sending : {this.FetchingTotalSendingSurvey()}
                          </p>
                          <p className="p_custom">
                            Total Response : {this.FetchingTotalSurveyResult()}{" "}
                            /{" "}
                            {parseFloat(
                              this.FetchingTotalSurveyResultPercentage()
                            ).toFixed(2)}
                            %
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="info-box">
                      <span className="info-box-icon">
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/images/cust_sat/calendar.png`}
                          className="logo"
                        />
                      </span>
                      <div className="info-box-content">
                        <p className="p_custom">
                          From : {this.FetchingFromDate()}
                        </p>
                        <p className="p_custom">
                          To : {this.FetchingLatestDate()}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="info-box">
                      <span className="info-box-icon">
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/images/cust_sat/report.png`}
                          className="logo"
                        />
                      </span>
                      <div className="info-box-content">
                        <p className="p_custom">
                          Weekly Sending : {this.FetchingWeeklySending()}
                          {/* Monthly Sending : {this.FetchingMonthlySending()} */}
                        </p>
                        <p className="p_custom">
                          Monthly Sending : {this.FetchingMonthlySending()}
                        </p>

                        <p className="p_custom">
                          Quarterly Sending : {this.FetchingQuarterlySending()}{" "}
                          {/* , Yearly Sending : {this.FetchingYearlySending()} */}
                        </p>
                        <p className="p_custom">
                          {/* Quarterly Sending : {this.FetchingQuarterlySending()}{" "} */}
                          Yearly Sending : {this.FetchingYearlySending()}
                        </p>
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="box">
                  <div
                    className="box-body"
                    style={{ marginBottom: 50, marginTop: 40 }}
                  >
                    <div
                      className="row"
                      style={{ marginLeft: 30, marginBottom: 50 }}
                    >
                      <div className="col">
                        <p style={{ fontSize: 50 }}>Scoring 1 - 5</p>
                        <p> 5 = Very satisfied</p>
                        <p> 4 = Satisfied</p>
                        <p> 3 = Neutral</p>
                        <p> 2 = Dissatisfied</p>
                        <p> 1 = Very dissatisfied</p>
                      </div>
                    </div>
                    {this.ScoringComponent()}
                    <br></br>
                    {this.PageComponent()}
                  </div>
                  {/* /.box-body */}
                </div>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ dashboardReducer }) => ({
  dashboardReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
