import { httpClient } from "../utils/HttpClient";
import {
    HTTP_GET_ALL_PM_TASK_FETCHING,
    HTTP_GET_ALL_PM_TASK_SUCCESS,
    HTTP_GET_ALL_PM_TASK_FAILED,
  server,
//   HTTP_STOCK_CLEAR
} from "../constants";
import { result } from "lodash";

export const setStateGetAllPMTaskToSuccess = payload => ({
  type: HTTP_GET_ALL_PM_TASK_SUCCESS,
  payload
});

const setStateGetAllPMTaskToFetching = () => ({
  type: HTTP_GET_ALL_PM_TASK_FETCHING
});

const setStateGetAllPMTaskToFailed = () => ({
  type: HTTP_GET_ALL_PM_TASK_FAILED
});







export const getAllPMTask= () => {
  return async dispatch => {
    try {
        dispatch(setStateGetAllPMTaskToFetching()); 
     
        let result1 = await httpClient.get(server.GET_ALL_PM_TASK);
        let result2 = await httpClient.get(server.GET_ALL_BM_TASK);

        if (result1.data.result == 'ok' || result2.data.result == 'ok') {
            let value ={
              PM:result1.data,
              BM:result2.data
            }
          dispatch(setStateGetAllPMTaskToSuccess(value));
  
        } else {        
          // dispatch(setStateGetAllPMTaskToFailed(result.data));
        }
      } catch (error) {        
          dispatch(setStateGetAllPMTaskToFailed({data: {message: error}}));
      }
  };
};
export const deleteBM = (Id,history) => {
  return async dispatch => {
    try {
      dispatch(setStateGetAllPMTaskToFetching()); // fetching
      let result = await httpClient.delete(`${server.DELETE_BM_TASK_BY_ID}${Id}`);
      // alert(result.data.result)
      if (result.data.result == 'ok') {
        // dispatch(setStateGetDATARentalToSuccess(result));
   
        history.push('/maintenance')
      } else {        
          alert(result.data.message)
        dispatch(setStateGetAllPMTaskToFailed(result));
        history.push('/maintenance')
      }
    } catch (error) {        
        dispatch(setStateGetAllPMTaskToFailed({data: {message: error}}));
        history.push('/maintenance')
    }
  };
};
export const deletePM = (Id,history) => {
  return async dispatch => {
    try {
      dispatch(setStateGetAllPMTaskToFetching()); // fetching
      let result = await httpClient.delete(`${server.DELETE_PM_TASK_BY_ID}${Id}`);
      // alert(result.data.result)
      if (result.data.result == 'ok') {
        // dispatch(setStateGetDATARentalToSuccess(result));
       
        history.push('/maintenance')
        
      } else {        
          alert(result.data.message)
        dispatch(setStateGetAllPMTaskToFailed(result));
        history.push('/maintenance')
      }
    } catch (error) {        
        dispatch(setStateGetAllPMTaskToFailed({data: {message: error}}));
        history.push('/maintenance')
    }
  };
};
