import React, { Component } from 'react';
import * as actions from "../../actions/customer.action";
import { Formik } from "formik";
import { connect } from "react-redux";
import { imageUrl } from "../../constants";


class CustomerEdit extends Component {
  

  componentDidMount(){
    let id = this.props.match.params.id
    this.props.getCustomerDetail(id)
  }

  // showPreviewImage = values => {
  //   if (values.file_obj) {
  //     return <img src={values.file_obj} style={{ height: 100 }} />;
  //   }else if (values.image){
  //     return <img src={`${imageUrl}/images/${values.image}`} style={{ height: 100 }} />
  //   }
  // };

  showForm =({values,handleChange,handleSubmit, setFieldValue})=>{
    const ArrCustomer = values.ArrCustomer
    const ArrData = values.ArrData
    const ArrLocation = values.ArrLocation
   
    if(ArrCustomer !=null && ArrData !=null && ArrLocation !=null){

    return (
      <form
      className="form-horizontal"
      onSubmit={handleSubmit}>
      <div className="form-group">
 
        <label className="col-sm-2 control-label" htmlFor="name">
          Customer Code
        </label>
        <div className="col-sm-10">
          <div  className="input-group">
          <input
            name="Customer_Code"
            onChange={handleChange}
            value={ArrCustomer.Customer_Code}
            placeholder="โปรดระบุ"
            className="form-control"
            type="text"
            id="name"
          />
          </div>
        </div>
      </div>
      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Customer_Name">
          Customer Name
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="Customer_Name"
              onChange={handleChange}
              value={ArrCustomer.Customer_Name}
              className="form-control"
              type="text"
            />
            {/* <span className="input-group-addon input-group-addon_custom">
              PCS.
            </span> */}
          </div>
        </div>
      </div>
      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Customer_Email">
        Customer Email
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="Customer_Email"
              onChange={handleChange}
              value={ArrCustomer.Customer_Email}
              className="form-control"
              type="email"
            />
            {/* <span className="input-group-addon input-group-addon_custom">
              PCS.
            </span> */}
          </div>
        </div>
      </div>
      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Customer_Address">
        Customer Address
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="Customer_Address"
              onChange={handleChange}
              value={ArrCustomer.Customer_Address}
              className="form-control"
              type="text"
            />
            {/* <span className="input-group-addon input-group-addon_custom">
              PCS.
            </span> */}
          </div>
        </div>
      </div>
      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Customer_Telephone">
        Customer Telephone
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="Customer_Telephone"
              onChange={handleChange}
              value={ArrCustomer.Customer_Telephone}
              className="form-control"
              type="text"
            />
            {/* <span className="input-group-addon input-group-addon_custom">
              PCS.
            </span> */}
          </div>
        </div>
      </div>
            
      <div className="container">
            <div className="table-responsive" style={{width:'50%'}}>
        <table className="table">
        <caption>List of EV car by Serial Number</caption>
  <thead>
    <tr>
     
      <th scope="col">Number Id</th>
      <th scope="col">Serial No.</th>
      <th scope="col">#</th>

    </tr>
  </thead>
 
   
  {
    ArrData.map(item=>{
      return  <tbody>
         <tr  key={item}>
   
    <td>{item.Id}</td>
    <td>{item.Serial_Number}</td>
    <td>
      <a onClick={()=>{alert(`Do you want to remove EV Car No. ${item.Serial_Number}`)}}>Remove</a>
    </td>
    </tr>
    </tbody>
    })
  }


    

        </table>
      </div>
            </div>
            <div className="container">
            <div className="table-responsive" style={{width:'50%'}}>
        <table className="table">
        <caption>List of EV car by Location base</caption>
  <thead>
    <tr>
     
      <th scope="col">Location</th>
      <th scope="col">Total</th>

    </tr>
  </thead>
 
   
  {
    ArrLocation.map(item=>{
      return  <tbody>
         <tr key={item}>
   
    <td>{item.Location_Name}</td>
    <td>{item.Total}</td>
    </tr>
    </tbody>
    })
  }


    

        </table>
      </div>
            </div>

      {/* <div className="form-group" style={{ marginTop: 15 }}>
        <div className="col-sm-12 col-sm-offset-2">
       

          <div className="wrap-upload-buttons control-label">
            <ul className="btn-nav row" id="rcorners">
              <li>
                <span style={{ marginLeft: 2 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ic_photo.png`}
                    style={{ width: 25, height: 20 }}
                  />
                  <span
                    style={{ color: "#00B0CD", marginLeft: 10 }}
                  >
                    Add Picture
                  </span>
                  <input
                    type="file"
                    onChange={e=>{
                      e.preventDefault();
                      setFieldValue("file", e.target.files[0]); // for upload
                      setFieldValue("file_obj",URL.createObjectURL(e.target.files[0])); // for preview image
                    }}
                    name="image"
                    click-type="type1"
                    className="picupload"
                    multiple
                    accept="image/*"
                    id="files"
                    style={{ padding: "20px 0" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      <div className="box-footer" style={{ marginTop: 50 }}>
        <button
          type="submit"
          className="btn btn-primary pull-right"
        >
          Update
        </button>
        <a
          onClick={() => {
            // this.props.clearProduct()
            this.props.history.goBack();
          }}
          type="Button"
          className="btn btn-default pull-right"
          style={{ marginRight: 10 }}
        >
          Cancel
        </a>
      </div>
    </form>
  
    )
    }
  }

  showFormTest=(result)=>{
   
if(result!=null){

  // const {ArrCustomer,ArrData,ArrLocation} =result
  const ArrCustomer = result.ArrCustomer
  const ArrData = result.ArrData
  let Customer = {result}
  return (
   
    <div>
        {ArrCustomer!=null ? <p>{ArrCustomer.Id}</p>:null} 
   
    {/* {ArrCustomer!=null ? ArrCustomer.map(item=>{
          return <p key={item.Id}>{item.Customer_Code}</p>
        }):null }  */}

     
    </div>
       )
}
    
  }
  render() {
    const {result}= this.props.customerReducer
    

    return (
      <div className="content-wrapper">
        {/* Main content */}
        <section className="content" style={{ maxWidth: "80%" }}>
          <div className="box box-primary" style={{ marginTop: 70 }}>
            <div className="box-header with-border">
              <p className="box-title" style={{ fontSize: 30 }}>
              Customer Detail
              </p>
              {/* {this.showFormTest(result)} */}
            </div>
            <div className="box-body" style={{ marginTop: 30 }}>
              <Formik
                enableReinitialize
                initialValues={result ? result: {}}
                onSubmit={(values, { setSubmitting }) => {
                   alert(values)
                  // let formData = new FormData()
                  // formData.append("Id", values.Id)
                  // formData.append("name", values.name)
                  // formData.append("price", values.price);
                  // formData.append("stock", values.stock);
                  // if (values.file){ 
                  //   formData.append("image",  values.file) 
                  // }
                  // this.props.updateProduct(formData, this.props.history)

                  setSubmitting(false);
                  // this.props.clearProduct()
                }} >
               {props=> this.showForm(props)} 
          
              
              </Formik>
              
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>
    );
  }
}


const mapStateToProps = ({ customerReducer }) => ({
  customerReducer
});
const mapDispatchToProps = {
  ...actions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerEdit);

