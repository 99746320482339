import { httpClient } from "../utils/HttpClient";
import {
HTTP_RENTAL_FETCHING,
HTTP_RENTAL_SUCCESS,
HTTP_RENTAL_FAILED,
  server,
//   HTTP_STOCK_CLEAR
} from "../constants";

export const setStateGetRentalToSuccess = payload => ({
  type: HTTP_RENTAL_SUCCESS,
  payload
});

const setStateGetRentalToFetching = () => ({
  type: HTTP_RENTAL_FETCHING
});

const setStateGetRentalToFailed = () => ({
  type: HTTP_RENTAL_FAILED
});






export const getAllRentalContract = () => {
    return async dispatch => {
        try {
            dispatch(setStateGetRentalToFetching()); 
         
            let result = await httpClient.get(server.GET_RENTAL);
    
            if (result.data.result == 'ok') {
         
              dispatch(setStateGetRentalToSuccess(result.data));
      
            } else {        
              dispatch(setStateGetRentalToFailed(result.data));
            }
          } catch (error) {        
              dispatch(setStateGetRentalToFailed({data: {message: error}}));
          }
      };
};
