import React, { Component } from "react";
import { Bar } from 'react-chartjs-2';
import { httpClient } from "./../utils/HttpClient";
import * as dayjs from 'dayjs'
import {
  GET_DETAILS_SCORE_BY_CLICK,
  server,
} from "../constants";

var options={
  legend: {
    display: true,
    labels: {
        // fontColor: "#000000",
        fontSize: 20
    }},
  scales: {
    yAxes: [{
        ticks: {
            fontSize: 15
        },
        scaleLabel: {
          display: true,
          labelString: 'No. of Respondents',
          fontSize: 25
        }
    }],
    xAxes: [{
      ticks: {
          fontSize: 25
      },
      scaleLabel: {
        display: true,
        labelString: 'Level of Satisfaction',
        fontSize: 25
      }
  }]
},
  maintainAspectRatio: false
}
export default class ClaimAndSupportPage extends Component {


  closeDataResult(e) {
    this.setState({
      dataResults: []
    })
  }
  async fetchScoreDetailByClick(_dataLabel, _question, _dataScore, _productparm = "All", _companyparm = "All", _startDateParm = "", _endDateParm = "") {

    
    var question = 0
    var score = 0

    score = _dataScore
    question = _question


    if (_startDateParm === "") {
      _startDateParm = new Date(new Date().getFullYear() + "-01-01T00:00:00")
    }
    if (_endDateParm === "") {
      _endDateParm = new Date(new Date().getFullYear() + "-12-31T23:59:59")
    }

    let queryString = "?producth1=" + _productparm + "&companyid=" + _companyparm + "&startdate=" + _startDateParm + "&enddate=" + _endDateParm + "&score=" + score + "&question=" + question

    let result1 = await httpClient.get(server.GET_DETAILS_SCORE_BY_CLICK + queryString);
    console.log("result1")
    console.log(result1)
    this.setState({
      dataLabel: _dataLabel

    })

    this.setState({
      dataScore: _dataScore
    })

    this.setState({
      dataResults: result1.data.Comments,

    })
    //alert(_data)
  }

  componentDidMount(){
    if (this.props.value.result) {
      let result = this.props.value.result.ClaimAndSupport;
      // alert(JSON.stringify(result.ProductsAndServices))
      this.setState({ ClaimProcess_L1: result.ClaimProcess_L1 });
      this.setState({ ClaimProcess_L2: result.ClaimProcess_L2 });
      this.setState({ ClaimProcess_L3: result.ClaimProcess_L3 });
      this.setState({ ClaimProcess_L4: result.ClaimProcess_L4 });
      this.setState({ ClaimProcess_L5: result.ClaimProcess_L5 });

      this.setState({ SpeedOfClaimResponse_L1: result.SpeedOfClaimResponse_L1 });
      this.setState({ SpeedOfClaimResponse_L2: result.SpeedOfClaimResponse_L2 });
      this.setState({ SpeedOfClaimResponse_L3: result.SpeedOfClaimResponse_L3 });
      this.setState({ SpeedOfClaimResponse_L4: result.SpeedOfClaimResponse_L4 });
      this.setState({ SpeedOfClaimResponse_L5: result.SpeedOfClaimResponse_L5 });

      this.setState({ AfterSaleService_L1: result.AfterSaleService_L1 });
      this.setState({ AfterSaleService_L2: result.AfterSaleService_L2 });
      this.setState({ AfterSaleService_L3: result.AfterSaleService_L3 });
      this.setState({ AfterSaleService_L4: result.AfterSaleService_L4 });
      this.setState({ AfterSaleService_L5: result.AfterSaleService_L5 });
      this.setState({CommentOfClaimAndSupport:result.CommentOfClaimAndSupport});

  
    }
  }
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      ClaimProcess_L1:0,
      ClaimProcess_L2:0,
      ClaimProcess_L3:0,
      ClaimProcess_L4:0,
      ClaimProcess_L5:0,

      SpeedOfClaimResponse_L1:0,
      SpeedOfClaimResponse_L2:0,
      SpeedOfClaimResponse_L3:0,
      SpeedOfClaimResponse_L4:0,
      SpeedOfClaimResponse_L5:0,

      AfterSaleService_L1:0,
      AfterSaleService_L2:0,
      AfterSaleService_L3:0,
      AfterSaleService_L4:0,
      AfterSaleService_L5:0,
      CommentOfClaimAndSupport:[],
      isLoading: 1,

      dataLabel: '',
      dataScore: '',
      dataResults: [],
    };
  }
  
  RenderData1 = ()=>{
      return {
        labels: ['1','2','3','4','5'],
        datasets: [
          {
            label: 'a. Claim process',
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [
               this.state.ClaimProcess_L1,
               this.state.ClaimProcess_L2,
               this.state.ClaimProcess_L3,
               this.state.ClaimProcess_L4,
               this.state.ClaimProcess_L5
            ]
          }
        ]
      };
  }
  RenderData2 = ()=>{
    return {
      labels: ['1','2','3','4','5'],
      datasets: [
        {
          label: 'b. Speed of claim response',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [
             this.state.SpeedOfClaimResponse_L1,
             this.state.SpeedOfClaimResponse_L2,
             this.state.SpeedOfClaimResponse_L3,
             this.state.SpeedOfClaimResponse_L4,
             this.state.SpeedOfClaimResponse_L5
          ]
        }
      ]
    };
}
RenderData3 = ()=>{
  return {
    labels: ['1','2','3','4','5'],
    datasets: [
      {
        label: 'c. After sale service',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [
           this.state.AfterSaleService_L1,
           this.state.AfterSaleService_L2,
           this.state.AfterSaleService_L3,
           this.state.AfterSaleService_L4,
           this.state.AfterSaleService_L5
        ]
      }
    ]
  };
}

  render() {
    return (
      <div>
        <div className="row">
        <div className="col-md-4 col-sm-6 col-xs-12">
        <Bar
          data={this.RenderData1()}
          width={100}
          height={400}
          options={options}
          getElementAtEvent={(elems, event) => {

            try {
              const chart = elems[0]._chart;
              const element = chart.getElementAtEvent(event)[0];
              const dataset = chart.data.datasets[element._datasetIndex];
              const xLabel = chart.data.labels[element._index];
              const value = dataset.data[element._index];
              //console.log(dataset.label + " at " + xLabel + ": " + value);
              //console.log("'" + dataset.label + "'")
              const { isFetching, result } = this.props.value;
              if (dataset.label == 'a. Claim process') {
                this.fetchScoreDetailByClick(dataset.label, 10, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
              } else if (dataset.label == 'b. Speed of claim response') {
                this.fetchScoreDetailByClick(dataset.label, 11, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
              } else if (dataset.label == 'c. After sale service') {
                this.fetchScoreDetailByClick(dataset.label, 12, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
              }
            } catch (error) {

            }
          }}
        />
        
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
        <Bar
          data={this.RenderData2()}
          width={100}
          height={400}
          options={options}
          getElementAtEvent={(elems, event) => {

            try {
              const chart = elems[0]._chart;
              const element = chart.getElementAtEvent(event)[0];
              const dataset = chart.data.datasets[element._datasetIndex];
              const xLabel = chart.data.labels[element._index];
              const value = dataset.data[element._index];
              //console.log(dataset.label + " at " + xLabel + ": " + value);
              //console.log("'" + dataset.label + "'")
              const { isFetching, result } = this.props.value;
              if (dataset.label == 'a. Claim process') {
                this.fetchScoreDetailByClick(dataset.label, 10, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
              } else if (dataset.label == 'b. Speed of claim response') {
                this.fetchScoreDetailByClick(dataset.label, 11, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
              } else if (dataset.label == 'c. After sale service') {
                this.fetchScoreDetailByClick(dataset.label, 12, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
              }
            } catch (error) {

            }
          }}
        />
        
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
        <Bar
          data={this.RenderData3()}
          width={100}
          height={400}
          options={options}
          getElementAtEvent={(elems, event) => {

            try {
              const chart = elems[0]._chart;
              const element = chart.getElementAtEvent(event)[0];
              const dataset = chart.data.datasets[element._datasetIndex];
              const xLabel = chart.data.labels[element._index];
              const value = dataset.data[element._index];
              //console.log(dataset.label + " at " + xLabel + ": " + value);
              //console.log("'" + dataset.label + "'")
              const { isFetching, result } = this.props.value;
              if (dataset.label == 'a. Claim process') {
                this.fetchScoreDetailByClick(dataset.label, 10, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
              } else if (dataset.label == 'b. Speed of claim response') {
                this.fetchScoreDetailByClick(dataset.label, 11, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
              } else if (dataset.label == 'c. After sale service') {
                this.fetchScoreDetailByClick(dataset.label, 12, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
              }
            } catch (error) {

            }
          }}
        />
        
        </div>
        {/* <div className="container">
           <div className="text-center"> <h4>Comments</h4></div>
          <ul className="container">
              {this.state.CommentOfClaimAndSupport.slice(0, 5).map((Item,index)=>{
                return <li className="text-muted" key={index}>{Item.Comment}</li>;
              })}
          </ul>
        </div> */}
       
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            {this.state.dataResults.length > 0 && (
              <div className="table-responsive">

                <table className="table table-bordered table-sm">
                  <thead>
                    <tr className="bg-yellow">
                      <th scope="col">#</th>
                      <th scope="col">Topic</th>
                      <th scope="col">Score</th>
                      <th scope="col">Customer Name</th>
                      <th scope="col">Country</th>
                      {/* <th scope="col">SaleGroup</th> */}
                      <th scope="col">Comments</th>
                      <th scope="col"><button style={{ color: 'black' }} onClick={(e) => this.closeDataResult(e)}>X</button></th>
                    </tr>
                  </thead>
                  <tbody>




                    {this.state.dataResults.length > 0 && this.state.dataResults.map((Item, index) => {
                      return <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{this.state.dataLabel}</td>
                        <td>{this.state.dataScore}</td>
                        <td>{Item.CustomerName}</td>
                        <td>{Item.Country}</td>

                        <td>{Item.Comment}</td>
                        <td></td>
                      </tr>

                    })}



                  </tbody>
                </table>
              </div>
            )}

          </div>
        </div>
       
      </div>
    );
  }
}
