import { httpClient } from "./../utils/HttpClient";
import * as dayjs from 'dayjs'
import {
    HTTP_GET_ALL_SURVEY_RESULT_FETCHING,
    HTTP_GET_ALL_SURVEY_RESULT_SUCCESS,
    HTTP_GET_ALL_SURVEY_RESULT_FAILED,
  server,
} from "../constants";

export const setStateSurveyResultToSuccess = payload => ({
  type: HTTP_GET_ALL_SURVEY_RESULT_SUCCESS,
  payload
});

const setStateSurveyResultToFetching = () => ({
  type: HTTP_GET_ALL_SURVEY_RESULT_FETCHING
});

const setStateSurveyResultToFailed = () => ({
  type: HTTP_GET_ALL_SURVEY_RESULT_FAILED
});


export const getAllSurveyResults = (_productparm="All",_companyparm="All",_startDateParm = "",_endDateParm = "") => {
  
   

   if(_startDateParm == ""){
    _startDateParm = new Date(new Date().getFullYear()+"-01-01T00:00:00")
   }
   if(_endDateParm == ""){
    _endDateParm = new Date(new Date().getFullYear()+"-12-31T23:59:59")
   }
   let queryString = "?producth1="+_productparm+"&companyid="+_companyparm+"&startdate="+_startDateParm+"&enddate="+_endDateParm
    
   console.log("queryString")
   console.log(queryString)
   console.log(_startDateParm)
   console.log(_endDateParm)
   return async dispatch => {
      try {
          dispatch(setStateSurveyResultToFetching()); 
       
          let result1 = await httpClient.get(server.GET_ALL_SURVEY_RESULT+queryString);
          let result2 = await httpClient.get(server.GET_SURVEY_RESULT_BY_LEVEL+queryString);
          let result3 = await httpClient.get(server.GET_ALL_NPS+queryString);

          let result4 = await httpClient.get(server.GET_PRODUCTS_AND_SERVICES+queryString);
          let result5 = await httpClient.get(server.GET_SALESPERSON+queryString);
          let result6 = await httpClient.get(server.GET_DELIVERY+queryString);
          let result7 = await httpClient.get(server.GET_CLAIM_AND_SUPPORT+queryString);
          let result8 = await httpClient.get(server.GET_ALL_COMMENT+queryString);
          let result9 = await httpClient.get(server.GET_ALL_PRODUCTS) //samano added
          //let result10 = await httpClient.get(server.GET_ALL_COUNTRIES) //samano added
          let result11 = await httpClient.get(server.GET_ALL_COMPANYS) //samano added
         
          console.log(result11)
          if (
            result1.data.result === 'ok' && 
            result2.data.result === 'ok' &&  
            result3.data.result === 'ok' &&
            result4.data.result === 'ok' &&
            result5.data.result === 'ok' &&
            result6.data.result === 'ok' &&
            result7.data.result === 'ok' &&
            result8.data.result === 'ok' &&
            result9.data.result === 'ok' &&
          //  result10.data.result === 'ok' &&
            result11.data.result === 'ok' 
            ) {
              let Arr ={
                AllSurvey:result1.data,
                ResultByLevel:result2.data,
                AllNPS:result3.data,
                ProductsAndServices:result4.data,
                SalesPerson:result5.data,
                Delivery:result6.data,
                ClaimAndSupport:result7.data,
                Comment:result8.data,
                products:result9.data, //samano added
               // countries:result10.data,//samano added
                companys:result11.data,//samano added
                productparm:_productparm,//samano added
                //countryparm:_countryparm,//samano added
                companyparm:_companyparm, //samano added
                startDateParm: new Date(_startDateParm),//samano added
                endDateParm: new Date(_endDateParm),//samano added
              }
            dispatch(setStateSurveyResultToSuccess(Arr));
    
          } else {     
         
            if(result1.data.message==="Access Deny")
            {
              localStorage.removeItem(server.TOKEN_KEY)
              window.location.reload(); 
            }
            dispatch(setStateSurveyResultToFailed(result1.data));
          }
        } catch (error) {     
          if(error.message==="Access Deny")
          {
            localStorage.removeItem(server.TOKEN_KEY)
            window.location.reload(); 
          }   
            dispatch(setStateSurveyResultToFailed({data: {message: error}}));
        }
    };
  };
  

