import React, { Component } from "react";
import Header from "./components/header";
import Menu from "./components/menu";
import Footer from "./components/footer";
import Login from "./components/login";
import Singlesignon from "./components/singlesignon";
import Register from "./components/register";
// import Stock from "./components/stock";
import StockEdit from "./components/stockEdit";
import StockCreate from "./components/stockCreate";
import Shop from "./components/shop";
import Report from "./components/report";
import Transaction from "./components/transaction";
import "./App.css"

import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { server } from "./constants";
import { connect } from 'react-redux';
import Customer from "./components/customer";
import CustomerEdit from "./components/customerEdit/customerEdit";
import Rentals from "./components/Rental";
import RentalCreate from "./components/createRental";
import RentalEdit from "./components/rentalEdit";
import CreateCustomer from "./components/createCustomer";
import EditCustomer from "./components/editCustomer";
import CreateSite from "./components/createSite";
import site from "./components/site/site";
import EditSite from "./components/editSite";
import CreateEVCar from "./components/createEVCar/CreateEVCar";
import EditEVCar from "./components/editEVCar";
import Maintenance from "./components/maintenance";
import createPM from "./components/createPM";
import editPM from "./components/editPM";
import createProduct from "./components/createProduct";
import product from "./components/product/product";
import editProduct from "./components/editProduct/editProduct";
import createBM from "./components/createBM";
import editBM from "./components/editBM";
import dashboard from "./components/dashboard";
import followtomanager  from "./components/followtomanager";
import urlbycustomer from "./components/urlbycustomer";

const isLoggedIn = ()=>{
  return localStorage.getItem(server.TOKEN_KEY) != null
}

// Protected Route
const SecuredRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      // ternary condition
      isLoggedIn() === true ? (
        <Component {...props}/>
      )
      :
      (<Redirect to="/login"/>)
    }
  />
);


class App extends Component {

  render() {
    return (
    
      <Router basename={process.env.REACT_APP_IS_PRODUCTION == 1 ? "/demo" : ""} >
            
        <Switch>

        <div>

          {isLoggedIn() && <Header /> }  
          {isLoggedIn() && <Menu /> }  
          <Route path="/SSO" component={Singlesignon}/>
          <Route path="/login" component={Login}/>
          <Route path="/register" component={Register}/> 
          <SecuredRoute path="/dashboard" component={dashboard}/>   
          <SecuredRoute path="/followtomanager" component={followtomanager}/>  
          <SecuredRoute path="/urlbycustomer" component={urlbycustomer}/>        

          <SecuredRoute exact={true} path="/customer" component={Customer}/>  
          {/* <SecuredRoute path="/customer-edit/:id" component={CustomerEdit}/> */}
          <SecuredRoute path="/customer-create" component={CreateCustomer}/>
          <SecuredRoute path="/customer-edit/:id" component={EditCustomer}/>

          <SecuredRoute path="/site" component={site}/>
          <SecuredRoute path="/site-create" component={CreateSite}/>
          <SecuredRoute path="/site-edit/:id" component={EditSite}/>
        
          <SecuredRoute path="/evcar-create" component={CreateEVCar}/>
          <SecuredRoute path="/evcar-edit/:id" component={EditEVCar}/>
        
          <SecuredRoute path="/rental" component={Rentals}/>        
          <SecuredRoute path="/rental-create" component={RentalCreate}/>
          <SecuredRoute path="/rental-edit/:id" component={RentalEdit}/>       

          <SecuredRoute path="/maintenance" component={Maintenance}/>
          <SecuredRoute path="/pm-create" component={createPM}/>
          <SecuredRoute path="/pm-edit/:id" component={editPM}/> 
          <SecuredRoute path="/bm-create" component={createBM}/>    
          <SecuredRoute path="/bm-edit/:id" component={editBM}/>    

          <SecuredRoute path="/product-create" component={createProduct}/>
          <SecuredRoute path="/product" component={product}/>      
          <SecuredRoute path="/product-edit/:id" component={editProduct}/>      
          {/* <SecuredRoute path="/stock" component={Stock}/>
          <SecuredRoute path="/stock-create" component={StockCreate}/>
          <SecuredRoute path="/stock-edit/:id/" component={StockEdit}/>
          <SecuredRoute path="/shop" component={Shop}/>
          <SecuredRoute path="/report" component={Report} />
          <SecuredRoute path="/transaction" component={Transaction} /> */}
          <SecuredRoute exact={true} path="/" component={()=>(<Redirect to="/login"/>)}/>
          {/* <Route render={props=>(<Redirect to="/report"/>)}/>  */}
          {isLoggedIn() && <Footer /> }  
         
          </div>
        </Switch>
      
      </Router>
    
    );
  }
}



const mapStateToProps = ({loginReducer}) => ({
  loginReducer
})

const mapDispatchToProps = {  
}

export default connect(mapStateToProps, mapDispatchToProps)(App)