import React, { Component } from "react";
import { Pie, Doughnut, HorizontalBar } from "react-chartjs-2";
import GaugeChart from "react-gauge-chart";
import "./NPS.css";

// const data = {
//   labels: ["Promoters","Passives","Detractors"],
//   datasets: [
//     {
//       label: "NPS data",
//       backgroundColor: "rgba(255,99,132,0.2)",
//       borderColor: "rgba(255,99,132,1)",
//       borderWidth: 1,
//       hoverBackgroundColor: "rgba(255,99,132,0.4)",
//       hoverBorderColor: "rgba(255,99,132,1)",
//       data: [65, 59, 80],
//     },
//   ],
// };

export default class NPSBars extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      NPS: 0,
      Promoters: 0,
      Passives: 0,
      Detractors: 0,
      P_Promoters: 0,
      P_Passives: 0,
      P_Detractors: 0,
      isLoading: 1,
    };
  }
  componentDidMount() {
    // alert(JSON.stringify(this.props.value.result.AllSurvey))
    if (this.props.value.result) {
      let result = this.props.value.result.AllNPS;
      this.setState({ NPS: result.NPS });
      this.setState({ Promoters: result.Promoters });
      this.setState({ Passives: result.Passives });
      this.setState({ Detractors: result.Detractors });
      this.setState({ P_Promoters: result.P_Promoters });
      this.setState({ P_Passives: result.P_Passives });
      this.setState({ P_Detractors: result.P_Detractors });
      // alert(JSON.stringify(result))
      this.setState({ isLoading: 0 });
    }
  }
  NPSData = () => {
    return {
      labels: ["Promoters", "Passives", "Detractors"],
      datasets: [
        {
          label: "NPS Data",
          // fontSize: 25,
          backgroundColor: ["#5BE12C", "#808080","#EA4228"],
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: [
            this.state.Promoters,
            this.state.Passives,
            this.state.Detractors,
          ],
        },
      ],
    };
  };
  render() {
    return (
      <div>
        <div className="">
          {this.state.isLoading === 0 ? (
       <HorizontalBar
       data={this.NPSData()}
       width={100}
       height={400}
       options={{ 
         maintainAspectRatio: false,
         scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Level of NPS',
              fontSize: 25
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'No. of Respondents',
              fontSize: 25
            }
          }],
        }     
       }}
     />
          ) : null}
        </div>
       
      </div>
    );
  }
}
