import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class Menu extends Component {
  render() {
    const { pathname } = this.props.location;

    return (
      <aside className="main-sidebar">
        {/* sidebar: style can be found in sidebar.less */}
        <section className="sidebar">
          {/* Sidebar user panel */}
          <div className="user-panel">
            <div className="pull-left image">
              <img
                src="dist/img/user2-160x160.jpg"
                className="img-circle"
                alt="User Image"
              />
            </div>
            <div className="pull-left info">
               <p>{localStorage.getItem("Name")}</p>
              <a href="#">
                <i className="fa fa-circle text-success" /> Online
              </a>
            </div>
          </div>
          {/* search form */}
          <form action="#" method="get" className="sidebar-form">
            <div className="input-group">
              <input
                type="text"
                name="q"
                className="form-control"
                placeholder="Search..."
              />
              <span className="input-group-btn">
                <button
                  type="submit"
                  name="search"
                  id="search-btn"
                  className="btn btn-flat"
                >
                  <i className="fa fa-search" />
                </button>
              </span>
            </div>
          </form>
          {/* /.search form */}
          {/* sidebar menu: : style can be found in sidebar.less */}
          <ul className="sidebar-menu" data-widget="tree">
            {/* <li className="header">MAIN NAVIGATION</li> */}
            <li className="active treeview menu-open">
              <a href="">
                <i className="fa fa-dashboard" /> <span>Report</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </a>
              <ul className="treeview-menu">
                <li className={pathname == "/dashboard" ? "active" : null}>
                  <Link to="/dashboard">
                    <i className="fa fa-circle-o" />
                    Dashboard
                  </Link>
                </li>
                <li className={pathname == "/followtomanager" ? "active" : null}>
                  <Link to="/followtomanager">
                    <i className="fa fa-circle-o" />
                    Follow to Manager
                  </Link>
                </li>
                <li className={pathname == "/urlbycustomer" ? "active" : null}>
                  <Link to="/urlbycustomer">
                    <i className="fa fa-circle-o" />
                    Cust Sat Url by Customer
                  </Link>
                </li>
              
              </ul>
            </li>
            
        
          </ul>
        </section>
        {/* /.sidebar */}
      </aside>
    );
  }
}

export default withRouter(Menu);
