import React, { Component } from "react";
import { connect } from "react-redux";
import { login, sso } from "./../../actions/login.action"

import { Link } from "react-router-dom";
import { server } from "../../constants";
import queryString from "query-string";
import "./singlesignon.css";

class Singlesignon extends Component {


    componentDidMount() {

        if (localStorage.getItem(server.TOKEN_KEY) != null) {
            this.props.history.push("/dashboard")
        }
        let params = queryString.parse(this.props.location.search)
        console.log(params.token)
        this.props.sso(params.email, params.token, this.props.history)
    }



    render() {
        return (
            <div>
                <div className="loader2">
    
                </div>

            </div>
        )
    }
}
const mapStateToProps = ({ loginReducer }) => ({
    loginReducer
})
const mapDispatchToProps = {
    login,
    sso
}

export default connect(mapStateToProps, mapDispatchToProps)(Singlesignon)
//export default Singlesignon;