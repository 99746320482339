export class TransactionRequest {
    subtotal = 0;
    discount = 0;
    shipping_cost = 0;
    tax_percent = 0;
    total = 0;
    paid = 0;
    change = 0;
    order_list = "x";
    payment_type = "x";
    payment_detail = "x";
    staff_id = "x";
    seller_id = "x";
    buyer_id = "x";
    comment = "x";
}