import { httpClient } from "../utils/HttpClient";
import {
HTTP_RENTAL_GET_DATA_FETCHING,
HTTP_RENTAL_GET_DATA_SUCCESS,
HTTP_RENTAL_GET_DATA_FAILED,
  server,
//   HTTP_STOCK_CLEAR
} from "../constants";

export const setStateGetDATARentalToSuccess = payload => ({
  type: HTTP_RENTAL_GET_DATA_SUCCESS,
  payload
});

const setStateGetDATARentalToFetching = () => ({
  type: HTTP_RENTAL_GET_DATA_FETCHING
});

const setStateGetDATARentalToFailed = () => ({
  type: HTTP_RENTAL_GET_DATA_FAILED
});







export const getAllDataForSelect = () => {
    return async dispatch => {
      try {
          dispatch(setStateGetDATARentalToFetching()); 
       
          let result1 = await httpClient.get(server.GET_EV_CAR_FOR_RENT);
          let result2 = await httpClient.get(server.GET_CUSTOMER_FOR_RENT);
          let result3 = await httpClient.get(server.GET_LOCATION_FOR_RENT);
        // alert(JSON.stringify(result1.data.ArrData))
          if (result1.data.result == 'ok' && result2.data.result == 'ok'&& result3.data.result == 'ok') {
              let Consolidate = {
                  EVCar:result1.data.ArrData,
                  Customer:result2.data.ArrData,
                  Location:result3.data.ArrData
  
              }
            //   alert(JSON.stringify(Consolidate.EVCar))
            dispatch(setStateGetDATARentalToSuccess(Consolidate));
    
          } else {        
            dispatch(setStateGetDATARentalToFailed(result2.data));
          }
        } catch (error) {        
            dispatch(setStateGetDATARentalToFailed({data: {message: error}}));
        }
    };
  };

  export const addNewRentalContract = (value, history) => {
    return async dispatch => {
      try {
        dispatch(setStateGetDATARentalToFetching()); // fetching
        let result = await httpClient.post(server.ADD_NEW_RENTAL_CONTRACT, value);
        if (result.data.result == 'ok') {
          // dispatch(setStateGetDATARentalToSuccess(result));
          history.push('/rental')
        } else {        
          alert(result.data.message)
          dispatch(setStateGetDATARentalToFailed(result));
          history.push('/rental')
        }
      } catch (error) {        
          dispatch(setStateGetDATARentalToFailed({data: {message: error}}));
          history.push('/rental')
      }
    };
  };
  
  