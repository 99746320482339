import { httpClient } from "../utils/HttpClient";
import {
HTTP_GET_EV_MODEL_FAILED,
HTTP_GET_EV_MODEL_SUCCESS,
HTTP_GET_EV_MODEL_FETCHING,
  server,
//   HTTP_STOCK_CLEAR
} from "../constants";
import { join } from "lodash";

export const setStateGetEVModelToSuccess = payload => ({
  type: HTTP_GET_EV_MODEL_SUCCESS,
  payload
});

const setStateGetEVModelToFetching = () => ({
  type: HTTP_GET_EV_MODEL_FETCHING
});

const setStateGetEVModelToFailed = payload => ({
  type: HTTP_GET_EV_MODEL_FAILED,
  payload
});



  export const getEVModel= () => {
    return async dispatch => {
      try {
        dispatch(setStateGetEVModelToFetching()); // fetching
        let result = await httpClient.get(server.GET_EV_MODEL);
        if (result.data.result == 'ok') {
          dispatch(setStateGetEVModelToSuccess(result.data));
        } else {        
          // alert(JSON.stringify(result.data.message))
          dispatch(setStateGetEVModelToFailed(result.data));
        //   history.push('/rental')
        }
      } catch (error) {        
          dispatch(setStateGetEVModelToFailed({data: {message: error}}));
        //   history.push('/rental')
      }
    };
  };
  export const createEVCar= (value,history) => {
    return async dispatch => {
      try {
        dispatch(setStateGetEVModelToFetching()); // fetching
        let result = await httpClient.post(server.CREATE_EV_CAR,value);
        // alert(JSON.stringify(result.data))
        if (result.data.result == 'ok') {
          dispatch(setStateGetEVModelToSuccess(result.data));
        history.push('/rental')
        } else {        
          // alert(JSON.stringify(result.data.message))
          dispatch(setStateGetEVModelToFailed(result.data));
          // history.push('/rental')
        }
      } catch (error) {        
          dispatch(setStateGetEVModelToFailed({data: {message: error}}));
          history.push('/rental')
      }
    };
  };


  export const updateEVCar= (Id,value,history) => {
    return async dispatch => {
      try {
        dispatch(setStateGetEVModelToFetching()); // fetching
        let result = await httpClient.put(`${server.UPDATE_EV_CAR}${Id}`,value);
        // alert(JSON.stringify(result.data))
        if (result.data.result == 'ok') {
          dispatch(setStateGetEVModelToSuccess(result.data));
        history.push('/stock')
        } else {        
          // alert(JSON.stringify(result.data.message))
          dispatch(setStateGetEVModelToFailed(result.data));
          history.push('/stock')
        }
      } catch (error) {        
          dispatch(setStateGetEVModelToFailed({data: {message: error}}));
          history.push('/stock')
      }
    };
  };
  
  
  