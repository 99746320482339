import { httpClient } from "../utils/HttpClient";
import {
    HTTP_RENTAL_FETCHING_BY_ID,
    HTTP_RENTAL_SUCCESS_BY_ID,
    HTTP_RENTAL_FAILED_BY_ID,
  server,
//   HTTP_STOCK_CLEAR
} from "../constants";

export const setStateGetRentalByIdlToSuccess = payload => ({
  type: HTTP_RENTAL_SUCCESS_BY_ID,
  payload
});

const setStateGetRentalByIdToFetching = () => ({
  type: HTTP_RENTAL_FETCHING_BY_ID
});

const setStateGetRentalByIdToFailed = () => ({
  type: HTTP_RENTAL_FAILED_BY_ID
});







export const getRentalContractById = (Id) => {
    return async dispatch => {
      try {
          dispatch(setStateGetRentalByIdToFetching()); 
          let result1 = await httpClient.get(server.GET_EV_CAR_FOR_RENT);
          let result2 = await httpClient.get(`${server.GET_RENTAL_CONTRACT_BY_ID}${Id}`);
          let result3 = await httpClient.get(server.GET_ALL_SITE)
          let result4 = await httpClient.get(server.GET_CUSTOMER)
    
        // alert(JSON.stringify(result.data))
          if (result1.data.result == 'ok' && result2.data.result == 'ok' &&result3.data.result == 'ok' &&result4.data.result == 'ok') {
            let Consolidate = {
              EVCar:result1.data.ArrData,
              ArrEVList:result2.data.ArrEVList,
              ArrRentalContract:result2.data.ArrRentalContract,
              ArrSite:result3.data.ArrData,
              ArrCustomer:result4.data.ArrData

          }
            //   alert(JSON.stringify(Consolidate.EVCar))
            dispatch(setStateGetRentalByIdlToSuccess(Consolidate));
    
          } else {        
            dispatch(setStateGetRentalByIdToFailed(result2.data));
          }
        } catch (error) {        
            dispatch(setStateGetRentalByIdToFailed({data: {message: error}}));
        }
    };
  };
  export const updateRentalContractById = (values,Id,history) => {
    return async dispatch => {
      try {
          dispatch(setStateGetRentalByIdToFetching()); 
       
          let result = await httpClient.put(`${server.UPDATE_RENTAL_CONTRACT_BY_ID}${Id}`,values);
    
        // alert(JSON.stringify(result.data))
          if (result.data.result == 'ok') {
            history.push('/rental')
          
            //   alert(JSON.stringify(Consolidate.EVCar))
            // dispatch(setStateGetRentalByIdlToSuccess());
    
          } else {        
            history.push('/rental')
            // dispatch(setStateGetRentalByIdToFailed(result.data));
          }
        } catch (error) {        
          history.push('/rental')
            dispatch(setStateGetRentalByIdToFailed({data: {message: error}}));
        }
    };
  };

