import { httpClient } from "../utils/HttpClient";
import {
    HTTP_GET_ALL_PRODUCT_FETCHING,
    HTTP_GET_ALL_PRODUCT_SUCCESS,
    HTTP_GET_ALL_PRODUCT_FAILED,
  server,
//   HTTP_STOCK_CLEAR
} from "../constants";

export const setStateGetAllProductToSuccess = payload => ({
  type: HTTP_GET_ALL_PRODUCT_SUCCESS,
  payload
});

const setStateGetAllProductToFetching = () => ({
  type: HTTP_GET_ALL_PRODUCT_FETCHING
});

const setStateGetAllProductToFailed = () => ({
  type: HTTP_GET_ALL_PRODUCT_FAILED
});







export const getAllProduct = () => {
  return async dispatch => {
    try {
        dispatch(setStateGetAllProductToFetching()); 
     
        let result = await httpClient.get(server.GET_ALL_PRODUCT);

        if (result.data.result == 'ok') {
     
          dispatch(setStateGetAllProductToSuccess(result.data));
  
        } else {        
          dispatch(setStateGetAllProductToFailed(result.data));
        }
      } catch (error) {        
          dispatch(setStateGetAllProductToFailed({data: {message: error}}));
      }
  };
};
export const deleteProduct = (Id,history) => {
    return async dispatch => {
      try {
        //   alert(Id)
        dispatch(setStateGetAllProductToFetching()); // fetching
        let result = await httpClient.delete(`${server.DELETE_PRODUCT_BY_ID}${Id}`);
        alert(JSON.stringify(result.data))
        if (result.data.result == 'ok') {
        //   dispatch(setStateGetAllProductToSuccess(result.data));
        history.push('/product')
        } else {        
          alert(JSON.stringify(result.data))
          dispatch(setStateGetAllProductToFailed(result.data));
          history.push('/product')
        }
      } catch (error) {        
        alert(JSON.stringify(error))
          dispatch(setStateGetAllProductToFailed({data: {message: error}}));
          history.push('/product')
      }
    };
  };
// export const getCustomerDetail = (Id) => {
//     return async dispatch => {
//       try {
//           dispatch(setStateGetCustomerToFetching()); 
       
//           let result = await httpClient.get(`${server.GET_CUSTOMER_DETAIL}${Id}`);
//           // alert(JSON.stringify(result.data))
//           if (result.data.result == 'ok') {
       
//             dispatch(setStateGetCustomerToSuccess(result.data));
    
//           } else {        
//             dispatch(setStateGetCustomerToFailed(result.data));
//           }
//         } catch (error) {        
//             dispatch(setStateGetCustomerToFailed({data: {message: error}}));
//         }
//     };
//   };


