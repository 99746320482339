import { combineReducers } from "redux";
import loginReducer from './login.reducer';
import registerReducer from './register.reducer';
import stockReducer from './stock.reducer';
import shopReducer from './shop.reducer';
import transactionReducer from './transaction.reducer';
import getEVCarByCategoryReducer from './getEvCarByCategory.reducer';
import customerReducer from './customer.reducer'
import rentalReducer from './rental.reducer'
import rentalCreateReducer from './rentalCreate.reducer'
import rentalEditReducer from './rentalEdit.reducer'
import createNewCustomerReducer from './createNewCustomer.reducer'
import editCustomerReducer from './editCustomer.reducer'
import createNewSiteReducer from './createNewSite.reducer'
import siteReducer from './site.reducer'
import editSiteReducer from './editSite.reducer'
import createNewEVCarReducer from './createNewEVCar.reducer'
import createPMReducer from './createPM.reducer'
import maintenanceReducer from './maintenance.reducer'
import editPmTaskReducer from './editPMTask.reducer'
import createProductReducer from './createNewProduct.reducer'
import productReducer from './product.reducer'
import createBMReducer from './createBM.reducer'
import editBmTaskReducer from './editBMTask.reducer'

import dashboardReducer from './dashboard.reducer'
export default combineReducers({
    dashboardReducer,
    loginReducer,
    registerReducer,
    stockReducer,
    shopReducer,
    transactionReducer,
    getEVCarByCategoryReducer,
    customerReducer,
    rentalReducer,
    rentalCreateReducer,
    rentalEditReducer,
    createNewCustomerReducer,
    editCustomerReducer,
    createNewSiteReducer,
    siteReducer,
    editSiteReducer,
    createNewEVCarReducer,
    createPMReducer,
    maintenanceReducer,
    editPmTaskReducer,
    createProductReducer,
    productReducer,
    createBMReducer,
    editBmTaskReducer
})