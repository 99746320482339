import React, { Component } from "react";
import * as actions from "../../actions/dashboard.action";
import { connect } from "react-redux";
import { imageUrl } from "../../constants";
import { Link } from "react-router-dom";
import _, { result } from "lodash";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import "./followtomanager.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { httpClient } from "../../utils/HttpClient";

import * as dayjs from 'dayjs'

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
    GET_FOLLOW_TO_MANAGER,
    GET_FOLLOW_BY_BYCOMPANY,
    server,
} from "../../constants";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const MySwal = withReactContent(Swal);

class Followtomanager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataResults: [],
            startDateParm: new Date(new Date().getFullYear() + "-01-01T00:00:00"),//samano added
            endDateParm: new Date(new Date().getFullYear() + "-12-31T23:59:59"),//samano added
            isLoading: false,
            customers: [],
            custid: "",
        };
    }
    componentDidMount() {
        this.fetchCustomers();


    }
    async fetchCustomers() {
        this.setState({
            customers: []

        })

        this.setState({
            isLoading: true
        })

        //let queryString = "?producth1=" + _productparm + "&companyid=" + _companyparm + "&startdate=" + _startDateParm + "&enddate=" + _endDateParm + "&score=" + score + "&question=" + question
        // let queryString = "?startdate=" + _startDateParm + "&enddate=" + _endDateParm + "&custid=" + _custid
        // let result1 = await httpClient.get(server.GET_FOLLOW_TO_MANAGER + queryString);
        let result1 = await httpClient.get(server.GET_ALL_CUSTOMERS);


        this.setState({
            customers: result1.data.Customers,

        })

        this.setState({
            isLoading: false
        })


        console.log(result1.data)



    }
    handleStartDate = (_date) => {
        this.setState({
            startDateParm: _date
        })
        // this.fetchData(_date, this.state.endDateParm)
        //this.props.getAllSurveyResults(_productparm,_companyparm,_date,_endDateParm);
    }
    handleEndDate = (_date) => {
        this.setState({
            endDateParm: _date
        })

        this.fetchData(this.state.startDateParm, this.state.endDateParm,this.state.custid)
    }


    async fetchData(_startDateParm = "", _endDateParm = "",_custid = "") {
        this.setState({
            dataResults: []

        })

        this.setState({
            isLoading: true
        })

        //let queryString = "?producth1=" + _productparm + "&companyid=" + _companyparm + "&startdate=" + _startDateParm + "&enddate=" + _endDateParm + "&score=" + score + "&question=" + question
        let queryString = "?startdate=" + _startDateParm + "&enddate=" + _endDateParm +"&customercode="+_custid+"&companycode="
        // let result1 = await httpClient.get(server.GET_FOLLOW_TO_MANAGER + queryString);
        let result1 = await httpClient.get(server.GET_FOLLOW_BY_BYCOMPANY + queryString);


        this.setState({
            dataResults: result1.data.FollowToManagers,

        })

        this.setState({
            isLoading: false
        })


        console.log(result1.data)



    }

    handleSearch(e) {

        this.fetchData(this.state.startDateParm, this.state.endDateParm,this.state.custid)

    }
    handleSelectCustomers(e) {
        //alert(e.target.value)
        this.setState({custid: e.target.value})
    }
    handleExport(e) {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        let fileName = "summaryreport"

        // const ws = XLSX.utils.json_to_sheet(this.state.dataResults);
        var ws = XLSX.utils.table_to_sheet(document.getElementById('emp'));
        var wscols = [
            { wch: 5 },
            { wch: 12 },
            { wch: 40 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
        ];

        ws['!cols'] = wscols;



        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };



        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        // this.fetchData(this.state.startDateParm,this.state.endDateParm)

    }
    render() {
        return (
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <h1>
                        Follow up by manager
                        <small>Report</small>
                    </h1>
                    {/* <ol className="breadcrumb">
            <li>
              <a href="#/">
                <i className="fa fa-dashboard" /> Home
              </a>
            </li>
            <li>
              <a href="#/">Dashboard</a>
            </li>
          </ol> */}
                </section>

                <section className="content">

                    <div className="row">
                        <div className="col-xs-12">
                            <div className="form-group row">
                                <div className="col-md-1">
                                    <label htmlFor="inputFirstname">From :</label>
                                    <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.startDateParm} onChange={(date) => this.handleStartDate(date)} />

                                </div>
                                <div className="col-md-1">
                                    <label htmlFor="inputLastname">To:</label>
                                    <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.endDateParm} onChange={(date) => this.handleEndDate(date)} />

                                </div>
                                <div className="col-md-1">
                                    <label htmlFor="inputLastname">&nbsp;</label>
                                    <div>
                                        <button onClick={(e) => this.handleSearch(e)}>Search</button>

                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <label htmlFor="inputLastname">&nbsp;</label>
                                    <div>

                                        <button onClick={(e) => this.handleExport(e)}>Download</button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12">
                            <label htmlFor="inputCustomername">Customer :</label><br />

                            <select className="form-select form-select-lg mb-3" name="customers" id="customers" onChange={(e) => this.handleSelectCustomers(e)}>
                                <option value="All">All</option>
                                {this.state.customers.length > 0 && this.state.customers.map((Item, index) => {

                                    return (
                                        <>
                                            <option key={index} value={Item.Customer_Code}>{Item.Customer_Company_Name} ({Item.Customer_Code})</option>
                                        </>
                                    )

                                })}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            {/* {this.state.dataResults.length > 0 && ( */}
                            <div className="table-responsive">

                                <table id="emp" className="table table-bordered table-sm">
                                    <thead>
                                        <tr className="bg-secondary">
                                            <th scope="col">No.</th>
                                            {/* <th scope="col">Product H1</th>
                                            <th scope="col">Product Cat</th> */}
                                            <th scope="col">Company Code</th>
                                            <th scope="col">Company Name</th>
                                            <th scope="col">&nbsp;</th>
                                            <th scope="col">Total Billing</th>
                                            <th scope="col">Total Sending</th>
                                            <th scope="col">Total Response</th>
                                            <th scope="col">Percentage</th>
                                            <th scope="col">Action Need</th>

                                            {/* <th scope="col">SaleGroup</th> */}
                                            {/* <th scope="col">Action</th> */}
                                            {/* <th scope="col"><button style={{ color: 'black' }} onClick={(e) => this.closeDataResult(e)}>X</button></th> */}
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {this.state.isLoading === true && (
                                            <tr align="center"><td colSpan="6">
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <div className="loader"></div>
                                                    </div>
                                                </div>
                                            </td></tr>
                                        )}


                                        {this.state.dataResults.length > 0 && this.state.dataResults.map((Item, index) => {
                                            return (
                                                <>
                                                    <tr key={index} >
                                                        <td>#{Item.runno}</td>

                                                        <td>{Item.Company_Code}</td>
                                                        <td>{Item.Company_Name}</td>
                                                        <td>&nbsp;</td>
                                                        <td>{Item.Total_Billing}</td>
                                                        <td>{Item.Total_Sending}</td>
                                                        <td>{Item.Total_Response}</td>
                                                        <td>{Item.Total_Response_Percentage}</td>
                                                        <td></td>


                                                    </tr>
                                                    <tr className="bg-primary">
                                                        <td></td>
                                                        <td>Company Code</td>
                                                        <td>Customer Code</td>
                                                        <td>Customer Name</td>
                                                        <td>Survey Frequency</td>
                                                        <td>Billing</td>
                                                        <td>Sending</td>
                                                        <td>Response </td>
                                                        <td>Action Need</td>

                                                    </tr>
                                                    {Item.Details ? (
                                                        <>
                                                            {Item.Details.length > 0 && Item.Details.map((Item2, index2) => {
                                                                return (
                                                                    <>
                                                                        <tr key={index2} className="bg-info">
                                                                            <td>{index2 + 1}</td>
                                                                            <td>{Item.Company_Code}</td>
                                                                            <td>{Item2.Customer_Code}</td>
                                                                            <td>{Item2.Customer_Name}</td>
                                                                            <td>{Item2.Frequency_Sending_Survey}</td>
                                                                            <td>{Item2.BillCount}</td>
                                                                            <td>{Item2.SendingCount}</td>
                                                                            <td>{Item2.ResponseCount}</td>
                                                                            <td>{Item2.Status}</td>


                                                                        </tr>

                                                                    </>


                                                                )

                                                            })}
                                                        </>

                                                    ) : ""

                                                    }

                                                </>


                                            )

                                        })}



                                    </tbody>

                                </table>


                            </div>
                            {/* )} */}

                        </div>
                    </div>
                </section>


            </div>
        )

    }

}

const mapStateToProps = ({ dashboardReducer }) => ({
    dashboardReducer,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Followtomanager);
