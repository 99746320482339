import { httpClient } from "../utils/HttpClient";
import {
HTTP_GET_SITE_BY_ID_FAILED,
HTTP_GET_SITE_BY_ID_SUCCESS,
HTTP_GET_SITE_BY_ID_FETCHING,
  server,
//   HTTP_STOCK_CLEAR
} from "../constants";

export const setStateGetSiteByIdToSuccess = payload => ({
  type: HTTP_GET_SITE_BY_ID_SUCCESS,
  payload
});

const setStateGetSiteByIdToFetching = () => ({
  type: HTTP_GET_SITE_BY_ID_FETCHING
});

const setStateGetSiteByIdToFailed = payload => ({
  type: HTTP_GET_SITE_BY_ID_FAILED,
  payload
});



  export const getSiteByIdFunction= (Id) => {
    return async dispatch => {
      try {
        dispatch(setStateGetSiteByIdToFetching()); // fetching
        let result = await httpClient.get(`${server.GET_SITE_BY_ID}${Id}`);
        if (result.data.result == 'ok') {
          dispatch(setStateGetSiteByIdToSuccess(result.data));
         
        } else {        
    
          dispatch(setStateGetSiteByIdToFailed(result.data));

        }
      } catch (error) {        
          dispatch(setStateGetSiteByIdToFailed({data: {message: error}}));
      }
    };
  };
  export const updateSiteByIdFunction= (value,Id,history) => {
    return async dispatch => {
      try {
        dispatch(setStateGetSiteByIdToFetching()); // fetching
        let result = await httpClient.put(`${server.UPDATE_SITE_BY_ID}${Id}`,value);
        if (result.data.result == 'ok') {
          dispatch(setStateGetSiteByIdToSuccess(result.data));
          history.push('/site')
         
        } else {        
    
          dispatch(setStateGetSiteByIdToFailed(result.data));
          history.push('/site')

        }
      } catch (error) {        
          dispatch(setStateGetSiteByIdToFailed({data: {message: error}}));
      }
    };
  };
  
  
  