import React, { Component } from "react";
import { Bar } from 'react-chartjs-2';
import { httpClient } from "./../utils/HttpClient";
import * as dayjs from 'dayjs'
import {
  GET_DETAILS_SCORE_BY_CLICK,
  server,
} from "../constants";


var options = {
  legend: {
    display: true,
    labels: {
      // fontColor: "#000000",
      fontSize: 20
    }
  },
  scales: {
    yAxes: [{
      ticks: {
        fontSize: 15
      },
      scaleLabel: {
        display: true,
        labelString: 'No. of Respondents',
        fontSize: 25
      }
    }],
    xAxes: [{
      ticks: {
        fontSize: 25
      },
      scaleLabel: {
        display: true,
        labelString: 'Level of Satisfaction',
        fontSize: 25
      }
    }]
  },
  maintainAspectRatio: false,

}
export default class ProductsAndServicesPage extends Component {

  closeDataResult(e) {
    this.setState({
      dataResults: []
    })
  }
  async fetchScoreDetailByClick(_dataLabel, _question, _dataScore, _productparm = "All", _companyparm = "All", _startDateParm = "", _endDateParm = "") {

    
    var question = 0
    var score = 0

    score = _dataScore
    question = _question


    if (_startDateParm === "") {
      _startDateParm = new Date(new Date().getFullYear() + "-01-01T00:00:00")
    }
    if (_endDateParm === "") {
      _endDateParm = new Date(new Date().getFullYear() + "-12-31T23:59:59")
    }

    let queryString = "?producth1=" + _productparm + "&companyid=" + _companyparm + "&startdate=" + _startDateParm + "&enddate=" + _endDateParm + "&score=" + score + "&question=" + question

    let result1 = await httpClient.get(server.GET_DETAILS_SCORE_BY_CLICK + queryString);
    console.log("result1")
    console.log(result1)
    this.setState({
      dataLabel: _dataLabel

    })

    this.setState({
      dataScore: _dataScore
    })

    this.setState({
      dataResults: result1.data.Comments,

    })
    //alert(_data)
  }

  componentDidMount() {



    if (this.props.value.result) {
      let result = this.props.value.result.ProductsAndServices;
      // alert(JSON.stringify(result.ProductsAndServices))
      this.setState({ QualityConsistency_L1: result.QualityConsistency_L1 });
      this.setState({ QualityConsistency_L2: result.QualityConsistency_L2 });
      this.setState({ QualityConsistency_L3: result.QualityConsistency_L3 });
      this.setState({ QualityConsistency_L4: result.QualityConsistency_L4 });
      this.setState({ QualityConsistency_L5: result.QualityConsistency_L5 });

      this.setState({ GoodValueForMoney_L1: result.GoodValueForMoney_L1 });
      this.setState({ GoodValueForMoney_L2: result.GoodValueForMoney_L2 });
      this.setState({ GoodValueForMoney_L3: result.GoodValueForMoney_L3 });
      this.setState({ GoodValueForMoney_L4: result.GoodValueForMoney_L4 });
      this.setState({ GoodValueForMoney_L5: result.GoodValueForMoney_L5 });

      this.setState({ Variety_L1: result.Variety_L1 });
      this.setState({ Variety_L2: result.Variety_L2 });
      this.setState({ Variety_L3: result.Variety_L3 });
      this.setState({ Variety_L4: result.Variety_L4 });
      this.setState({ Variety_L5: result.Variety_L5 });
      this.setState({ CommentOfProductsAndServices: result.CommentOfProductsAndServices });


    }
  }
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      QualityConsistency_L1: 0,
      QualityConsistency_L2: 0,
      QualityConsistency_L3: 0,
      QualityConsistency_L4: 0,
      QualityConsistency_L5: 0,

      GoodValueForMoney_L1: 0,
      GoodValueForMoney_L2: 0,
      GoodValueForMoney_L3: 0,
      GoodValueForMoney_L4: 0,
      GoodValueForMoney_L5: 0,

      Variety_L1: 0,
      Variety_L2: 0,
      Variety_L3: 0,
      Variety_L4: 0,
      Variety_L5: 0,
      CommentOfProductsAndServices: [],
      isLoading: 1,

      dataLabel: '',
      dataScore: '',
      dataResults: [],
    };
  }

  RenderData1 = () => {
    return {
      labels: ['1', '2', '3', '4', '5'],
      datasets: [
        {
          label: 'a. Quality Consistency',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [
            this.state.QualityConsistency_L1,
            this.state.QualityConsistency_L2,
            this.state.QualityConsistency_L3,
            this.state.QualityConsistency_L4,
            this.state.QualityConsistency_L5
          ]
        }
      ]
    };
  }
  RenderData2 = () => {
    return {
      labels: ['1', '2', '3', '4', '5'],
      datasets: [
        {
          label: 'b. Good value for money',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [
            this.state.GoodValueForMoney_L1,
            this.state.GoodValueForMoney_L2,
            this.state.GoodValueForMoney_L3,
            this.state.GoodValueForMoney_L4,
            this.state.GoodValueForMoney_L5
          ]
        }
      ]
    };
  }
  RenderData3 = () => {
    return {
      labels: ['1', '2', '3', '4', '5'],
      datasets: [
        {
          label: 'c. Variety',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [
            this.state.Variety_L1,
            this.state.Variety_L2,
            this.state.Variety_L3,
            this.state.Variety_L4,
            this.state.Variety_L5
          ]
        }
      ]
    };
  }



  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-12">
            <Bar
              data={this.RenderData1()}
              width={100}
              height={400}
              options={options}
              getElementAtEvent={(elems, event) => {

                try {
                  const chart = elems[0]._chart;
                  const element = chart.getElementAtEvent(event)[0];
                  const dataset = chart.data.datasets[element._datasetIndex];
                  const xLabel = chart.data.labels[element._index];
                  const value = dataset.data[element._index];
                  //console.log(dataset.label + " at " + xLabel + ": " + value);
                  //console.log("'" + dataset.label + "'")
                  const { isFetching, result } = this.props.value;
                  if (dataset.label == 'a. Quality Consistency') {
                    this.fetchScoreDetailByClick(dataset.label, 1, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
                  } else if (dataset.label == 'b. Good value for money') {
                    this.fetchScoreDetailByClick(dataset.label, 2, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
                  } else if (dataset.label == 'c. Variety') {
                    this.fetchScoreDetailByClick(dataset.label, 3, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
                  }
                } catch (error) {

                }
              }}

            />


          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <Bar
              data={this.RenderData2()}
              width={100}
              height={400}
              options={options}
              getElementAtEvent={(elems, event) => {

                try {
                  const chart = elems[0]._chart;
                  const element = chart.getElementAtEvent(event)[0];
                  const dataset = chart.data.datasets[element._datasetIndex];
                  const xLabel = chart.data.labels[element._index];
                  const value = dataset.data[element._index];
                  //console.log(dataset.label + " at " + xLabel + ": " + value);
                  //console.log("'" + dataset.label + "'")
                  const { isFetching, result } = this.props.value;
                  if (dataset.label == 'a. Quality Consistency') {
                    this.fetchScoreDetailByClick(dataset.label, 1, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
                  } else if (dataset.label == 'b. Good value for money') {
                    this.fetchScoreDetailByClick(dataset.label, 2, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
                  } else if (dataset.label == 'c. Variety') {
                    this.fetchScoreDetailByClick(dataset.label, 3, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
                  }
                } catch (error) {

                }
              }}

            />

          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <Bar
              data={this.RenderData3()}
              width={100}
              height={400}
              options={options}
              getElementAtEvent={(elems, event) => {

                try {
                  const chart = elems[0]._chart;
                  const element = chart.getElementAtEvent(event)[0];
                  const dataset = chart.data.datasets[element._datasetIndex];
                  const xLabel = chart.data.labels[element._index];
                  const value = dataset.data[element._index];
                  //console.log(dataset.label + " at " + xLabel + ": " + value);
                  //console.log("'" + dataset.label + "'")
                  const { isFetching, result } = this.props.value;
                  if (dataset.label == 'a. Quality Consistency') {
                    this.fetchScoreDetailByClick(dataset.label, 1, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
                  } else if (dataset.label == 'b. Good value for money') {
                    this.fetchScoreDetailByClick(dataset.label, 2, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
                  } else if (dataset.label == 'c. Variety') {
                    this.fetchScoreDetailByClick(dataset.label, 3, xLabel, result.productparm, result.companyparm, result.startDateParm, result.endDateParm)
                  }
                } catch (error) {

                }
              }}
            />

          </div>
          {/* <div className="container">
           <div className="text-center"> <h4>Comments</h4></div>
          <ul className="container">
              {this.state.CommentOfProductsAndServices.slice(0, 5).map((Item,index)=>{
                return <li className="text-muted" key={index}>{Item.Comment}</li>;
              })}
          </ul>
        </div> */}

        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            {this.state.dataResults.length > 0 && (
              <div className="table-responsive">

                <table className="table table-bordered table-sm">
                  <thead>
                    <tr className="bg-yellow">
                      <th scope="col">#</th>
                      <th scope="col">Topic</th>
                      <th scope="col">Score</th>
                      <th scope="col">Customer Name</th>
                      <th scope="col">Country</th>
                      {/* <th scope="col">SaleGroup</th> */}
                      <th scope="col">Comments</th>
                      <th scope="col"><button style={{ color: 'black' }} onClick={(e) => this.closeDataResult(e)}>X</button></th>
                    </tr>
                  </thead>
                  <tbody>




                    {this.state.dataResults.length > 0 && this.state.dataResults.map((Item, index) => {
                      return <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{this.state.dataLabel}</td>
                        <td>{this.state.dataScore}</td>
                        <td>{Item.CustomerName}</td>
                        <td>{Item.Country}</td>

                        <td>{Item.Comment}</td>
                        <td></td>
                      </tr>

                    })}



                  </tbody>
                </table>
              </div>
            )}

          </div>
        </div>
      </div>
    );
  }
}
