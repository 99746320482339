import { httpClient } from "../utils/HttpClient";
import {
  HTTP_GET_ALL_RENTAL_CONTRACT_FETCHING,
  HTTP_GET_ALL_RENTAL_CONTRACT_SUCCESS,
  HTTP_GET_ALL_RENTAL_CONTRACT_FAILED,
  server,
//   HTTP_STOCK_CLEAR
} from "../constants";
import { result } from "lodash";

export const setStateGetAllRentalContractToSuccess = payload => ({
  type: HTTP_GET_ALL_RENTAL_CONTRACT_SUCCESS,
  payload
});

const setStateGetAllRentalContractToFetching = () => ({
  type: HTTP_GET_ALL_RENTAL_CONTRACT_FETCHING
});

const setStateGetAllRentalContractToFailed = () => ({
  type: HTTP_GET_ALL_RENTAL_CONTRACT_FAILED
});







export const getAllDataForSelect = () => {
    return async dispatch => {
      try {
          dispatch(setStateGetAllRentalContractToFetching()); 
       
          let result1 = await httpClient.get(server.GET_EV_CAR_FOR_RENT);
          let result2 = await httpClient.get(server.GET_CUSTOMER_FOR_RENT);
          let result3 = await httpClient.get(server.GET_LOCATION_FOR_RENT);
          let result4 = await httpClient.get(server.GET_RENTAL_AND_EV_LIST_FOR_PM)
          let result5 = await httpClient.get(server.GET_PRODUCT_FOR_PM)
          let result6 = await httpClient.get(server.GET_ALL_CONTRACTOR)
        // alert(JSON.stringify(result1.data.ArrData))
          if (result1.data.result === 'ok' && result2.data.result === 'ok'&& result3.data.result === 'ok'&& result4.data.result==='ok'&& result5.data.result==='ok'&& result6.data.result==='ok') {
              let Consolidate = {
                  EVCar:result1.data.ArrData,
                  Customer:result2.data.ArrData,
                  Location:result3.data.ArrData,
                  Rental:result4.data.ArrData,
                  Product:result5.data.ArrData,
                  Contractor:result6.data.ArrData
                
  
              }
            //   alert(JSON.stringify(Consolidate.EVCar))
            dispatch(setStateGetAllRentalContractToSuccess(Consolidate));
    
          } else {        
            dispatch(setStateGetAllRentalContractToFailed(result2.data));
          }
        } catch (error) {        
            dispatch(setStateGetAllRentalContractToFailed({data: {message: error}}));
        }
    };
  };

  export const createPM = (value, history) => {
    return async dispatch => {
      try {
        dispatch(setStateGetAllRentalContractToFetching()); // fetching
        let result = await httpClient.post(server.CREATE_NEW_PM_ORDER, value);
        if (result.data.result == 'ok') {
          // dispatch(setStateGetDATARentalToSuccess(result));
          history.push('/maintenance')
        } else {     
          // alert(result.data.result)   
          dispatch(setStateGetAllRentalContractToFailed(result));
          
          history.push('/maintenance')
        }
      } catch (error) {        
          dispatch(setStateGetAllRentalContractToFailed({data: {message: error}}));
          history.push('/maintenance')
      }
    };
  };
  
  