import {
  LOGIN_FETCHING,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  server
} from "../constants";
import { httpClient } from "./../utils/HttpClient";

// Forward to reducer
export const setLoginStateToFetch = () => ({
  type: LOGIN_FETCHING
});

export const setLoginStateToSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload
});

export const setLoginStateToFailed = payload => ({
  type: LOGIN_FAILED,
  payload
});

export const setLoginStateToLogout = () => ({
  type: LOGOUT,
});

// Called by Login Component
export const login = (value,history) => {
  return async dispatch => {
    try {
      dispatch(setLoginStateToFetch()); // fetching
      let result = await httpClient.post(server.LOGIN_URL, value);
      // alert(result)
      if (result.data.result === 'ok') {
        // localStorage.setItem(server.TOKEN_KEY, result.data.token);
        // localStorage.setItem("Name",result.data.Username);
        setWithExpiry(server.TOKEN_KEY,result.data.token,1)
        dispatch(setLoginStateToSuccess(result));
        history.push("/dashboard")
      } else {        
        alert(result.data.message)
        history.push("/login")
        dispatch(setLoginStateToFailed(result));
      }
    } catch (error) {        
      alert(error)
      history.push("/login")
        dispatch(setLoginStateToFailed({data: {message: error}}));
    }
  };
};


export const logout = (history)=> {
    return (dispatch) =>{
      localStorage.removeItem(server.TOKEN_KEY);
      dispatch(setLoginStateToLogout())  
      history.push("/login");
    }
  }
  function setWithExpiry(key, value, ttl) {
    const now = new Date()
    ttl= ttl*1000*1000*60
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }


  export const sso = (email,token,history) => {
    return async dispatch => {
      try {
        dispatch(setLoginStateToFetch()); // fetching
        let result = await httpClient.get(server.SSO_URL+'?token='+token+'&email='+email);
        // alert(result)
        if (result.data.result === 'ok') {
          // localStorage.setItem(server.TOKEN_KEY, result.data.token);
          // localStorage.setItem("Name",result.data.Username);
          setWithExpiry(server.TOKEN_KEY,result.data.token,1)
          dispatch(setLoginStateToSuccess(result));
          history.push("/dashboard")
        } else {        
          alert(result.data.message)
          history.push("/sso")
          dispatch(setLoginStateToFailed(result));
        }
      } catch (error) {        
        alert(error)
        history.push("/login")
          dispatch(setLoginStateToFailed({data: {message: error}}));
      }
    };
  };
  
  