import React, { Component } from 'react';
import * as actions from "../../actions/createNewProduct.action";
import { connect } from "react-redux";
import { Formik ,FieldArray,Field} from "formik";
import { imageUrl } from "../../constants";
import { Link } from "react-router-dom";
import _, { join, values } from "lodash";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import "./editProduct.css";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from 'react-loader-spinner'

const MySwal = withReactContent(Swal)
var jwt = require("jsonwebtoken");
const moment = require('moment')

const orderid = require('order-id')('mysecret');
const id = orderid.generate();

class editProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Id:'',
      arrData:'',
      Item_Name:'',
      Image_No1:'',
      Image_No2:'',
      Image_No3:'',
    };
  }
  componentDidMount(){
    let arr = this.props.location.query

      this.setState({arrData:arr})

 
      let id = this.props.match.params.id
      this.setState({Id:id})
    

      this.setStateDefault()

  
   
  }
  setStateDefault = () =>{
    let arr = this.props.location.query
   if(arr){
     this.setState({Image_No1:arr.Image_No1})
     this.setState({Image_No2:arr.Image_No2})
     this.setState({Image_No3:arr.Image_No3})
     this.setState({Item_Name:arr.Item_Name})
     this.setState({Price_List:arr.Price_List})
     this.setState({Part_Number:arr.Part_Number})
     this.setState({Id:arr.Id})
    //  alert(arr.Image_No1)
  
    //  alert(this.props.location.query)
   }
  }

  showPreviewImage1 = values => {
    if (values.file_obj1) {
      return <img src={values.file_obj1} style={{ height: 100 }} />;
    }else{
      return this.state.Image_No1!='' ?  <img src={this.state.Image_No1} style={{ height: 100 }} />:null;
    }
  };
  showPreviewImage2 = values => {
    if (values.file_obj2) {
      return <img src={values.file_obj2} style={{ height: 100 }} />;
    }else{
      return this.state.Image_No2!='' ?  <img src={this.state.Image_No2} style={{ height: 100 }} />:null;
    }
  };
  showPreviewImage3 = values => {
    if (values.file_obj3) {
      return <img src={values.file_obj3} style={{ height: 100 }} />;
    }else{
      return this.state.Image_No3!='' ?  <img src={this.state.Image_No3} style={{ height: 100 }} />:null;
    }
  };

  showForm =({values,handleChange,handleSubmit, setFieldValue})=>{
    // this.ValidateEndDate(values.Start_Date,values.End_Date)

    return (
      <form
      className="form-horizontal"
      onSubmit={handleSubmit}>
  
      <div className="form-group">
 
        <label className="col-sm-2 control-label" htmlFor="item_name">
          Product Name
        </label>
        <div className="col-sm-10">
          <div  className="input-group">
          <input
            name="item_name"
            onChange={handleChange}
            value={values.item_name!=null? values.item_name:this.state.Item_Name}
            placeholder="Wheel"
            className="form-control"
            type="text"
            id="item_name"
            required
          />
          </div>
        </div>
      </div>

      <div className="form-group">
 
 <label className="col-sm-2 control-label" htmlFor="item_name">
   Part Number
 </label>
 <div className="col-sm-10">
   <div  className="input-group">
   <input
     name="part_number"
     onChange={handleChange}
     value={values.part_number}
     placeholder="xxx-xxx-xxx"
     className="form-control"
     type="text"
     id="part_number"
   
   />
   </div>
 </div>
</div>
            
    <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="price_list">
          Price List (Baht / Unit)
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="price_list"
              onChange={handleChange}
              value={values.price_list}
              className="form-control"
              placeholder="50"
              type="number"
              id="price_list"
              required
            /> 
           
      
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginTop: 15 }}>
        <div className="col-sm-12 col-sm-offset-2">
        {this.showPreviewImage1(values)}
          <div className="wrap-upload-buttons control-label">
            <ul className="btn-nav row" id="rcorners">
              <li>
                <span style={{ marginLeft: 2 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ic_photo.png`}
                    style={{ width: 25, height: 20 }}
                  />
                  <span
                    style={{ color: "#00B0CD", marginLeft: 10 }}
                  >
                    Add Picture
                  </span>
                  <input
                    type="file"
                    onChange={e=>{
                      e.preventDefault();
                      setFieldValue("file1", e.target.files[0]); // for upload
                      setFieldValue("file_obj1",URL.createObjectURL(e.target.files[0])); // for preview image
                    }}
                    name="image"
                    click-type="type1"
                    className="picupload"
                    multiple
                    accept="image/*"
                    id="files"
                    style={{ padding: "20px 0" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginTop: 15 }}>
        <div className="col-sm-12 col-sm-offset-2">
        {this.showPreviewImage2(values)}
          <div className="wrap-upload-buttons control-label">
            <ul className="btn-nav row" id="rcorners">
              <li>
                <span style={{ marginLeft: 2 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ic_photo.png`}
                    style={{ width: 25, height: 20 }}
                  />
                  <span
                    style={{ color: "#00B0CD", marginLeft: 10 }}
                  >
                    Add Picture
                  </span>
                  <input
                    type="file"
                    onChange={e=>{
                      e.preventDefault();
                      setFieldValue("file2", e.target.files[0]); // for upload
                      setFieldValue("file_obj2",URL.createObjectURL(e.target.files[0])); // for preview image
                    }}
                    name="image"
                    click-type="type1"
                    className="picupload"
                    multiple
                    accept="image/*"
                    id="files"
                    style={{ padding: "20px 0" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginTop: 15 }}>
        <div className="col-sm-12 col-sm-offset-2">
        {this.showPreviewImage3(values)}

          <div className="wrap-upload-buttons control-label">
            <ul className="btn-nav row" id="rcorners">
              <li>
                <span style={{ marginLeft: 2 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ic_photo.png`}
                    style={{ width: 25, height: 20 }}
                  />
                  <span
                    style={{ color: "#00B0CD", marginLeft: 10 }}
                  >
                    Add Picture
                  </span>
                  <input
                    type="file"
                    onChange={e=>{
                      e.preventDefault();
                      setFieldValue("file3", e.target.files[0]); // for upload
                      setFieldValue("file_obj3",URL.createObjectURL(e.target.files[0])); // for preview
                 
                    }}
                    name="image"
                    click-type="type1"
                    className="picupload"
                    multiple
                    accept="image/*"
                    id="files"
                    style={{ padding: "20px 0" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

    
    
   
       
      
    



      <div className="box-footer" style={{ marginTop: 50 }}>
        <button
          type="submit"
          className="btn btn-primary pull-right"
        >
          Save
        </button>
        <a
          onClick={() => {
            // this.props.clearProduct()
            this.props.history.goBack();
          }}
          type="Button"
          className="btn btn-default pull-right"
          style={{ marginRight: 10 }}
        >
          Cancel
        </a>
      </div>
    </form>
  
    )
    
  }
 
  render() {

    return this.props.location.query!=''? (
      <div className="content-wrapper">
        {/* Main content */}
        <section className="content" style={{ maxWidth: "80%" }}>
          <div className="box box-primary" style={{ marginTop: 70 }}>
            <div className="box-header with-border">
              <p className="box-title" style={{ fontSize: 30 }}>
              Update Product
              </p>
           <p>
          
           </p>
            </div>
            <div className="box-body" style={{ marginTop: 30 }}>
            {/* {this.showPreviewImage()}  */}
      
              <Formik
                enableReinitialize
                initialValues={{
                  item_name:this.state.Item_Name,
                  part_number:this.state.Part_Number,
                  price_list:this.state.Price_List,
                }}
                onSubmit={(values, { setSubmitting }) => {
                  let formData = new FormData()
                  formData.append("id", this.state.Id)
                  formData.append("part_number", values.part_number)
                  formData.append("item_name", values.item_name)
                  formData.append("price_list", values.price_list);
                  formData.append("Image_No1", this.state.Image_No1);
                  formData.append("Image_No2", this.state.Image_No2);
                  formData.append("Image_No3", this.state.Image_No3);

                  if (values.file1){ 
                    formData.append("image1",  values.file1) 
                  }
                  if (values.file2){ 
                    formData.append("image2",  values.file2) 
                  }
                  if (values.file3){ 
                    formData.append("image3",  values.file3) 
                  }
                  // alert(values.item_name)
                  setTimeout(() => {
                      this.props.updateProduct(this.state.Id,formData,this.props.history)
                  }, 2000);
                  
                  // this.props.updateProduct(formData, this.props.history)
                }} >
               {props=> this.showForm(props)} 
          
              
              </Formik>
       
                <br></br>
          
         
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>
    ):null;
  }
}


const mapStateToProps = ({ createProductReducer }) => ({
  createProductReducer
});
const mapDispatchToProps = {
  ...actions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(editProduct);

