import React, { Component } from 'react';
import * as actions from "../../actions/rentalCreate.action";
import { connect } from "react-redux";
import { Formik ,FieldArray,Field} from "formik";
import { imageUrl } from "../../constants";
import { Link } from "react-router-dom";
import _, { join, values } from "lodash";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import "./createRental.css";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from 'react-loader-spinner'

const MySwal = withReactContent(Swal)
var jwt = require("jsonwebtoken");
const moment = require('moment')

const orderid = require('order-id')('mysecret');
const id = orderid.generate();

class createRental extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ListEVCar:[],
      EVCarStoreValue:[],
      SelectCar:[]
    };
  }
  componentDidMount(){
    let id = this.props.match.params.id
    // this.getAllDataForSelect()
    this.props.getAllDataForSelect().then(result=>{
      this.ArrEVCar()
      // this.onHandleArrListEVCar()
    })
   
  }

  SelectCustomer = ()=>{
    let {isFetching,result} = this.props.rentalCreateReducer

    return (
      result!=null ? result.Customer.map((item,index)=>{
      return  <option  key={item.Customer_Name} value={item.Id}>{item.Customer_Name}</option>
      }):null
    );
    
  }
  SelectLocation= ()=>{
    let {isFetching,result} = this.props.rentalCreateReducer

    return (
      result!=null ? result.Location.map((item,index)=>{
      return  <option  key={item.Id} value={item.Id}>{item.Site_Name}</option>
      }):null
    );
    
  }
  SelectEVCar= ()=>{
    let {isFetching,result} = this.props.rentalCreateReducer

    return (
      this.state.ListEVCar!=null ? this.state.ListEVCar.map((item,index)=>{
      return  <option  key={item.Serial_Number} value={JSON.stringify(item)} >{item.Serial_Number}</option>
      }):null
    );
    // return (
    //   result!=null ? result.EVCar.map((item,index)=>{
    //   return  <option  key={item.Id} value={item.Id}>{item.Serial_Number}</option>
    //   }):null
    // );
    
  }
   ArrEVCar = () =>{
    let {isFetching,result} = this.props.rentalCreateReducer
 
    if(result!=null){
      // localStorage.setItem("EVCar",JSON.stringify(result.EVCar))
      this.setState({EVCarStoreValue:result.EVCar})
      let Temp = result.EVCar
     this.setState({ListEVCar:Temp})
    }
  }

  onHandleArrListEVCar = async (event) =>{

   
  // console.log("ok",event)
// alert(event.target.value)
   let ListCar  = this.state.ListEVCar

if(event.target.value !=null&& ListCar!=null){
 
     let PreLoad =  await JSON.parse(event.target.value)
 
      if(PreLoad){
        this.setState({SelectCar:[...this.state.SelectCar,PreLoad]})


        let arr = await ListCar.filter(function(item) {
          return item.Serial_Number !== PreLoad.Serial_Number
      })
      this.setState({ListEVCar:arr})

        // await ListCar.forEach(item=>{
        //   const index = item.Serial_Number.indexOf(PreLoad.Serial_Number);
        // if (index > -1) {
        //   ListCar.splice(index, 1);
        // }
        // })
      }else{
        alert("NOK")
      }
 

}
  }
onRecoveryArrListEVCar = async(value) =>{
  // alert(value.Serial_Number)
  let {isFetching,result} =this.props.rentalCreateReducer
//  let ListCar  = localStorage.getItem("EVCar")
 let ListCar = this.state.EVCarStoreValue
 let selectCar = this.state.SelectCar
if(value !=null&& result!=null){

  let arr = await selectCar.filter(function(item) {
    return item.Serial_Number !== value.Serial_Number
})


  this.setState({SelectCar:arr})

  //  let found=  await JSON.parse(ListCar).find(item=>{
  //   console.log(item.Serial_Number,value.Serial_Number)
  //    if(item.Serial_Number===value.Serial_Number)
  //    {
  //      return item
  //    }
  //   })
  let found=  await ListCar.find(item=>{
    console.log(item.Serial_Number,value.Serial_Number)
     if(item.Serial_Number===value.Serial_Number)
     {
       return item
     }
    })

     let Dummy =  this.state.ListEVCar
     Dummy.unshift(found)

         this.setState({ListEVCar:Dummy})
   
  console.log("CHECKSTATE",this.state.ListEVCar)
}

  }

 
  ValidateEndDate = (StartDate,EndDate) =>{
    if(EndDate){
      // let Check = moment(StartDate).format('DD-MM-YYYY')
      let FromNow =  moment(EndDate, "YYYYMMDD").fromNow();
      let FromNowStart =  moment(StartDate, "YYYYMMDD").fromNow();
    
       if(
         (
         FromNow.split(" ")[0]=='in' &&
         FromNow.split(" ")[1]>5 && 
         FromNow.split(" ")[2]==='days'
         )
         || 
        (  FromNow.split(" ")[0]=='in' &&
        ( FromNow.split(" ")[1]>0 || FromNow.split(" ")[1]==='a' )&& 
            ( FromNow.split(" ")[2]==='month'|| FromNow.split(" ")[2]==='months')
        )
        ||
        (  FromNow.split(" ")[0]=='in' &&
        ( FromNow.split(" ")[1]>0 || FromNow.split(" ")[1]==='a' )&& 
        (FromNow.split(" ")[2]==='year'||FromNow.split(" ")[2]==='years')
        )

         )
         {
      
        return true
       }else{
        return false
       }
    }
  }



  showForm =({values,handleChange,handleSubmit, setFieldValue})=>{
    // this.ValidateEndDate(values.Start_Date,values.End_Date)

    return (
      <form
      className="form-horizontal"
      onSubmit={handleSubmit}>
      <div className="form-group">
 
        <label className="col-sm-2 control-label" htmlFor="Contract_Number">
          Contract Number
        </label>
        <div className="col-sm-10">
          <div  className="input-group">
          <input
            name="Contract_Number"
            onChange={handleChange}
            value={values.Contract_Number}
            placeholder="Please fill required field"
            className="form-control"
            type="text"
            id="Contract_Number"
          />
          </div>
        </div>
      </div>


            
    <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="CustomerId">
          Customer Name
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <select
              name="CustomerId"
              onChange={handleChange}
              value={values.CustomerId}
              className="form-control"
              type="select"
              id="CustomerId"
            > 
              <option value=''  >Select Customer</option>
          
              {this.SelectCustomer()}
       
            </select>
      
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Location">
          Location
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <select
              name="Location"
              onChange={handleChange}
              value={values.Location}
              className="form-control"
              type="select"
              id="Location"
            > 
              <option value='' >Select Location</option>
    
              {this.SelectLocation()}
       
            </select>
      
          </div>
        </div>
      </div>


      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="Start_Date">
        Start Date
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="Start_Date"
              onChange={handleChange}
              value={values.Start_Date}
              className="form-control"
              type="date"
            />
      
          </div>
        </div>
      </div>
      <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="End_Date">
        End Date
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <input
              name="End_Date"
              onChange={handleChange}
              value={values.End_Date}
              className="form-control"
              type="date"
            />
          
          </div>
        </div>
      </div>
  
   
       
      
    
{/*        
               
              <div className="form-group" style={{ marginBottom: 10 }}>
                <label className="col-sm-2 control-label" htmlFor="RentalCarList">
               
                </label>
                <div className="col-sm-12">
                  <div className="input-group">
                  <FieldArray
             name="RentalCarList"
             render={arrayHelpers => (
               <div>
                 {values.RentalCarList && values.RentalCarList.length > 0 ? (
                   values.RentalCarList.map((RentalCarList, index) => (

                     <div key={index}  className="container">
                       <div className="row">
                       <div className="col-2">
                       <label className="col-sm-2 control-label" htmlFor="RentalCarList">
                      {`EV CAR No. ${index+1}`}
               </label>
                         </div>      
                      <div className="col-8">
                      <Field name={`RentalCarList[${index}].Serial_Number`} />
                       <button
                         type="button" className="btn btn-danger"
                         onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                       >
                         -
                       </button>
                       <button
                         type="button" className="btn btn-success"
                         onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                       >
                         +
                       </button>
                      </div>

                       </div>


                     </div>
                   ))
                 ) : (
                   <button type="button" onClick={() => arrayHelpers.push('')}>
                  
                     Add 
                   </button>
                 )}
             
               </div>
             )}
           />
                  </div>
               
                </div>
                <div className="col-sm-2">
                
                </div>
           
              </div>  */}
             

                {/* <div className="form-group" style={{ marginBottom: 10 }}>
        <label className="col-sm-2 control-label" htmlFor="RentalCarListD">
        RentalCarList
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <select
              name="RentalCarListD"
              onChange={(this.onHandleArrListEVCar)}
              value={values.RentalCarListD}
              className="form-control"
              type="select"
              id="RentalCarListD"
            > 
              <option value='' >Select EV Car</option>
    
              {this.SelectEVCar()}
       
            </select>
      
          </div>
           Total  {this.state.ListEVCar.length}
        </div>

      </div> */}


      <div className="box-footer" style={{ marginTop: 50 }}>
        <button
          type="submit"
          className="btn btn-primary pull-right"
        >
          Save
        </button>
        <a
          onClick={() => {
            // this.props.clearProduct()
            this.props.history.goBack();
          }}
          type="Button"
          className="btn btn-default pull-right"
          style={{ marginRight: 10 }}
        >
          Cancel
        </a>
      </div>
    </form>
  
    )
    
  }
  ComponentSelectEVCar = () =>{
    return    <div className="form-group" style={{ marginBottom: 10 }}>
    <label className="col-sm-2 control-label" htmlFor="RentalCarListD">
    RentalCarList
    </label>
    <div className="col-sm-10">
      <div className="input-group">
        <select
          name="RentalCarListD"
          onChange={(this.onHandleArrListEVCar)}
          value={values.RentalCarListD}
          className="form-control"
          type="select"
          id="RentalCarListD"
        > 
          <option value='' >Select EV Car</option>

          {this.SelectEVCar()}
   
        </select>
  
      </div>
       Total  {this.state.ListEVCar.length}
    </div>

  </div>

  }
  showSelectEVCarTable = () =>{
    return this.state.SelectCar.length>0? this.state.SelectCar.map((item)=>{
        return     <tr key={item.Serial_Number}>
        <td><Moment format="DD/MM/YYYY">{item.createdAt}</Moment></td>
        <td>
             
              {item.Id}         
          </td>
          <td>
          {item.Serial_Number}    
          </td>
          <td>
          {item.Description}    
          </td>
    
          <td><Moment format="DD/MM/YYYY">{item.Manufacturing_Date}</Moment></td>
         

   
     
          <td style={{ textAlign: "center", }}>
            <button   
              onClick={()=>{
                
                MySwal.fire({
                  title: 'Are you sure to delete?',
                  text: "You won't be able to revert this!",
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete it!',
                  cancelButtonText: 'No, cancel!',                                
                }).then(async(result) => {
                  if (result.value){
                    await this.onRecoveryArrListEVCar(item)
                    // this.props.deleteProduct(item.product_id)
                  }                 
                })   

              }}           
              type="button"
              className="btn btn-danger"
            >
              Delete
            </button>
          </td>
      </tr>
    }):null
  }
  render() {

 

    return (
      <div className="content-wrapper">
        {/* Main content */}
        <section className="content" style={{ maxWidth: "80%" }}>
          <div className="box box-primary" style={{ marginTop: 70 }}>
            <div className="box-header with-border">
              <p className="box-title" style={{ fontSize: 30 }}>
              Rental Contract Create
              </p>
           <p>
          
           </p>
            </div>
            <div className="box-body" style={{ marginTop: 30 }}>
              <Formik
                enableReinitialize
                initialValues={{
                
                  Contract_Number:'',
                  Location:'',
                  Start_Date:'',
                  End_Date:'',
                  CustomerId:'',
                  CreatedBy:localStorage.getItem('token'),
                  // DummySerialNo:'',
                  // RentalCarList:this.state.ListEVCar,
                  // RentalCarListD:[
                    
                  // ]
                //   RentalCarList:[
                //     {
                //     Leasing_Number:values.Leasing_Number,
                //     Serial_Number:1122,
                //     Start_Date:values.Start_Date,
                //     End_Date:values.End_Date
                //   }
                // ]

                  
                }}
                onSubmit={(values, { setSubmitting }) => {
                let checkValid =   this.ValidateEndDate(values.Start_Date,values.End_Date)
                // alert(JSON.stringify(this.state.SelectCar))
                values.RentalCarList = this.state.SelectCar
                // alert(values.CustomerId)
                // alert(JSON.stringify(values.RentalCarList ))

                setTimeout(() => {
                  if(checkValid){
                    this.props.addNewRentalContract(values, this.props.history)
                    setSubmitting(false);
                  }
                  else{
                    alert("Please select End Date at least 7 days")
                    values.End_Date=''
                  }
                  
                }, 2000);
            
                  // alert(values.Start_Date)
           
                  // this.props.clearProduct()
                }} >
               {props=> this.showForm(props)} 
          
              
              </Formik>
                {this.ComponentSelectEVCar()}
                <br></br>
              <table
                    id="stock_table"
                    className="table table-bordered table-striped table-hover mt-5"
                    style={{ height: 300, maxHeight: 300 }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "7%", textAlign: "center" }}>
                          CreatedAt
                        </th>
                        <th style={{ width: "10%" }}>Id</th>
                        <th style={{ width: "12%" }}>Serial No.</th>
                        <th style={{ width: "48%" }}>Model</th>
                        <th style={{ width: "9%" }}>Manufacturing Date</th>
                       
                        <th style={{ width: "14%", textAlign: "center" }}>
                          ACTION
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                
                   {this.state.SelectCar.length>0 && this.state.SelectCar!=null ? this.showSelectEVCarTable():null}
           
                    </tbody>
                  </table>
         
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>
    );
  }
}


const mapStateToProps = ({ rentalCreateReducer }) => ({
  rentalCreateReducer
});
const mapDispatchToProps = {
  ...actions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createRental);

