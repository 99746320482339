import {   HTTP_RENTAL_FETCHING_BY_ID,
    HTTP_RENTAL_SUCCESS_BY_ID,
    HTTP_RENTAL_FAILED_BY_ID, } from "../constants";

const initialState = {
    result: null,
    isFetching: false,
    isError: false
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case HTTP_RENTAL_FETCHING_BY_ID:
        return { ...state, result:null, isFetching:true, isError: false }
    case HTTP_RENTAL_SUCCESS_BY_ID:
        return { ...state, result:payload, isFetching:false, isError: false }
    case HTTP_RENTAL_FAILED_BY_ID:
        return { ...state, result:payload, isFetching:false, isError: true }
    default:
        return state
    }
}
