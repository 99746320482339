import axios from 'axios'
import join from 'url-join'
import {server, apiUrl, NOT_CONNECT_NETWORK, NETWORK_CONNECTION_MESSAGE} from '../constants'
const Config = require(`../Configs/${process.env.NODE_ENV}.json`) 
const isAbsoluteURLRegex = /^(?:\w+:)\/\//
function getWithExpiry(key) {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item.value
}
axios.interceptors.request.use(async (config) => {

    if (!isAbsoluteURLRegex.test(config.url)) {
        // const userToken = localStorage.getItem(server.TOKEN_KEY)
        const userToken = getWithExpiry(server.TOKEN_KEY)
        // alert(userToken)
        if (userToken) {
            config.headers = { 'x-access-token': userToken }
        }
        // alert(Config.axios.apiBaseURL)
  
        config.url = join(Config.axios.apiBaseURL, config.url) 
      
    }
    config.timeout = 1000000 // 10 Second 
    return config
})

axios.interceptors.response.use((response) => {
    return response
}, error => { 
    console.log(JSON.stringify(error, undefined, 2))
    if (axios.isCancel(error)) {
        return Promise.reject(error)
    } else if (!error.response) {
        return Promise.reject({ code: NOT_CONNECT_NETWORK, message: NETWORK_CONNECTION_MESSAGE })
    }
    return Promise.reject(error)
})

export const httpClient = axios
